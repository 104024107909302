import React from 'react';
import {
  get,
  capitalize,
  map,
  isNil,
  difference,
  findKey,
  each,
  uniq,
  sumBy,
  minBy,
  maxBy,
  toString,
  sortBy,
  toLower,
  find,
  isNaN,
  isEmpty
} from 'lodash';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import CustomFilter, {
  CustomSelectFilter
} from '../components/core/CustomFilter/CustomFilter';
import DisplayDate from '../components/core/DisplayDate/DisplayDate';
import {
  PROPERTY_TYPES,
  affixChartColors,
  DocumentFileTypes,
  sheetMappings,
  retailBaseClassesForExtraction,
  multifamilyBaseClassesForExtraction,
  USER_ROLES,
  validDateFormats,
  cellNumberFormat,
  USCurrencyFormat,
  USDateFormat,
} from '../constants';

import FormikTextField from '../components/core/FormikTextField/FormikTextField';
import FormikSelectField from '../components/core/FormikSelectField/FormikSelectField';
import config, { appConfig } from '../config';
import { TARGET_SHEET_NAME } from '../components/app/DocExtractionComponent/DataSpreadSheet';

export function delayedPromise(delay = 500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

export function forceUTCTimeZone(date) {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

export function loaderManager(
  p,
  onShow,
  onHide,
  timeout1 = 50,
  timeout2 = 400
) {
  let resolved = false;
  let visible = false;
  let timeoutOver = false;

  const hide = () => {
    if (visible && resolved && timeoutOver) {
      visible = false;
      onHide();
    }
  };

  return new Promise((resolve, reject) => {
    p.then(
      (value) => {
        resolved = true;
        hide();
        resolve(value);
      },
      (error) => {
        resolved = true;
        hide();
        reject(error);
      }
    );

    setTimeout(() => {
      if (!resolved) {
        visible = true;
        onShow();
        setTimeout(() => {
          timeoutOver = true;
          hide();
        }, timeout2);
      }
    }, timeout1);
  });
}

export const customSortFunc = (nestedSearchKey) => (a, b, order) => {
  const compareFieldA = get(a, nestedSearchKey, '');
  const compareFieldB = get(b, nestedSearchKey, '');
  if (order === 'asc') {
    return compareFieldA.localeCompare(compareFieldB);
  }
  return compareFieldB.localeCompare(compareFieldA);
};

export const getCustomFilterElement = (filterHandler, nestedSearchKey) => (
  <CustomFilter
    filterHandler={filterHandler}
    nestedSearchKey={nestedSearchKey}
  />
);

export const getCustomSelectFilterElement = (
  filterHandler,
  nestedSearchKey,
  options
) => (
  <CustomSelectFilter
    filterHandler={filterHandler}
    nestedSearchKey={nestedSearchKey}
    options={options}
  />
);

export const displayDateFormat = (ISOdateString) => (
  <DisplayDate date={new Date(ISOdateString)} />
);

export const checkValidNumber = (value) =>
  /^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/.test(value);

export const isValidDate = (value) => {
  const date = moment(value, validDateFormats, true);
  const dateValue = date.isValid() ? moment(new Date(value)).format("MM/DD/YYYY") : '';
  return dateValue && !checkValidNumber(dateValue) && !Number.isFinite(dateValue) ? (typeof date === 'object' || typeof date === 'string') && date.isValid() : false
}
export const displayNumberInUSFormat = (number) =>
  !isNil(number) && !isNaN(+number) ? (+number).toLocaleString('en-EN') : 0;
export const displayNumberInUSFormatWithOptions = (number, options) =>
  !isNil(number) && !isNaN(+number)
    ? (+number).toLocaleString('en-EN', options)
    : 0;
export const displayCurrencyInUSFormat = (currency) => (
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currency)
);
export const usDateFormat = (ISOdateString) =>
  moment(new Date(ISOdateString)).format('MM/DD/YYYY');

export const displayCurrencyFormat = (number) => {
  const roundValue = !isNil(number) ? number.toFixed(2) : 0;
  return !isNil(number)
    ? `$${roundValue
      .toString()
      .replace(',', '')
      .replace(/\B(?=(\d{3})+\b)/g, ',')
      .replace(/-(.*)/, '($1)')}`
    : 0;
};

export const displayNumberFormat = (number) => {
  const roundValue = !isNil(number) ? number.toFixed(2) : 0;
  return !isNil(number)
    ? roundValue
      .toString()
      .replace(',', '')
      .replace(/\B(?=(\d{3})+\b)/g, ',')
      .replace(/-(.*)/, '($1)')
    : 0;
};

export const convertStringToBoolean = (value) => {
  return typeof value === 'boolean' ? value : value && value.toLowerCase() === 'true';
}

export const DisplayAmountFormat = ({
  number,
  showCurency = true,
  appendString = ''
}) => {
  const styles =
    Math.sign(number) === -1
      ? { color: 'red', display: 'inline' }
      : { color: 'inherit', display: 'inline' };
  const roundValue =
    !isNil(number) && typeof number === 'number' ? number.toFixed(2) : 0;
  const cellValue = !isNil(number)
    ? `${showCurency ? '$' : ''}${roundValue
      .toString()
      .replace(',', '')
      .replace(/\B(?=(\d{3})+\b)/g, ',')
      .replace(/-(.*)/, '($1)')}${appendString}`
    : 0;
  return (
    <div title={cellValue} style={styles}>
      {cellValue}
    </div>
  );
};

export const isStringType = (str) => typeof str === 'string' && str;

export const getCurrencyFormat = (number) => {
  const roundValue = !isNil(number) && typeof number === 'number' ? number.toFixed(2) : 0;
  return !isNil(number)
    ? `${roundValue
      .toString()
      .replace(',', '')
      .replace(/\B(?=(\d{3})+\b)/g, ',')
      .replace(/-(.*)/, '($1)')}`
    : 0;
};
export const getSumbyGroup = (arr, key) => (
  sumBy(arr, (item) => (
    item[key] ? parseFloat((`${item[key]}`).includes('$') ? item[key].replace(/[$,]/g, '') : item[key]) : 0
  ))
);

export const formatAmountTitleTooltip = (
  number,
  showCurency = true,
  appendString = ''
) => {
  const roundValue = !isNil(number) ? number.toFixed(2) : 0;
  return !isNil(number)
    ? `${showCurency ? '$' : ''}${roundValue
      .toString()
      .replace(',', '')
      .replace(/\B(?=(\d{3})+\b)/g, ',')
      .replace(/-(.*)/, '($1)')}${appendString}`
    : 0;
};

export function capitalizeFirstLetter(str) {
  return (
    isStringType(str) && map(str.split(' '), (w) => capitalize(w)).join(' ')
  );
}

export function isBlankString(str) {
  return !str || str.length === 0 || !str.trim();
}

export function checkChildKeyHasValue(obj, childKey, value) {
  return Reflect.ownKeys(obj).some((key) => obj[key][childKey] === value);
}

export const getTemplateDropdownMap = (companyTemplates) => 
_.uniqBy(companyTemplates.map(({ uuid: value, templateName: label }) => ({ value, label })), 'label')

export function checkArrayIncludesValue(array, valueToSearch) {
  return (
    !!array &&
    array.some(
      (value) =>
        toString(value).trim().toLowerCase() ===
        toString(valueToSearch).trim().toLowerCase()
    )
  );
}

export function arrayContainsArray(superset, subset) {
  if (subset.length === 0) {
    return false;
  }
  return subset.every((value) => superset.indexOf(value) >= 0);
}

export function findMaximumLengthOfArrayInObj(objects) {
  let arraySize = 0;
  let foundKeyOfLargestValue = '';
  // eslint-disable-next-line no-restricted-syntax
  for (let item of Reflect.ownKeys(objects)) {
    if (objects[item].length > arraySize) {
      arraySize = objects[item].length;
      foundKeyOfLargestValue = item;
    }
  }
  return foundKeyOfLargestValue;
}

export function findValidKey(objectKeys, key) {
  let foundkey = objectKeys.find((k) => k.toLowerCase() === key.toLowerCase());
  return !isNil(foundkey) ? foundkey : '';
}

export const getRandomColor = () =>
  affixChartColors[Math.floor(Math.random() * affixChartColors.length)];

export const getAverage = (totalNumbers, counts) =>
  totalNumbers && Math.round(totalNumbers / counts);

export const validateValue = (value) => (!isNil(value) ? value : 0);

export const compareTwoArraysInLowerCase = (firstArray, secondArray) => {
  const firstArrayInLowerCase = firstArray.map((value) => value.toLowerCase());
  const seondArrayInLowerCase = secondArray.map((value) => value.toLowerCase());
  return difference(firstArrayInLowerCase, seondArrayInLowerCase);
};

export function getKeyByValue(obj, value) {
  return findKey(obj, (v) => v === value);
}

export function getPercentage(number, total) {
  return (number / total) * 100;
}

export function getTotalByKey(collection, keyName) {
  let total = 0;
  each(collection, (item) => {
    if (!isNil(item[keyName])) {
      total = total + Number(item[keyName]);
    }
  });
  return total;
}
const sortDesc = (value1, value2) => {
  if (value1 > value2) {
    return -1;
  } else if (value1 < value2) {
    return 1;
  }
  return 0;
};

const sortAsc = (value1, value2) => {
  if (value1 < value2) {
    return -1;
  } else if (value1 > value2) {
    return 1;
  }
  return 0;
};

export const doSorting = (value1, value2, order) => {
  const sort = order === 'desc' ? sortDesc : sortAsc;
  return sort(value1, value2);
};

export const isBracketExists = (str) => {
  const regex = /\(.*\)/;
  return regex.test(str);
};

export const getIsDocumentReExtractRequired = (document) => {
  if (
    document.taggingData &&
    document.taggingData.documentType === 'RENT_ROLL'
  ) {
    if (document.extractionJob && document.extractionJob.status) {
      const { createdAt } = document.extractionJob;
      const { reExtractBeforeDate } = config;
      const createdAtDate = moment(createdAt);
      const reExtractDate = moment(reExtractBeforeDate, 'MM/DD/YYYY');
      return createdAtDate.isBefore(reExtractDate);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export function isNumberBetweenRange(number, min, max) {
  return number >= min && number <= max;
}

export function validateChargeCode(chargeCode) {
  const updatedChargeCode = chargeCode.replaceAll('_', ' '); // removing underscore from the charge code
  return updatedChargeCode.indexOf('cc') > -1
    ? updatedChargeCode.substr(2)
    : updatedChargeCode;
}

export function constructProjectXlsxAndModelName(project, restrictedStrings) {
  const sanitizedModelName = project.name.replace(/[^a-zA-Z0-9 ]/g, '');
  let modelName = sanitizedModelName;
  if (
    restrictedStrings[project.assetType] &&
    sanitizedModelName.indexOf(restrictedStrings[project.assetType]) === 0
  ) {
    modelName = PROPERTY_TYPES.MULTIFAMILY.key
      ? sanitizedModelName.substring(2)
      : sanitizedModelName.substring(3);
  }
  return modelName.trim() ? modelName : Date.now().toString();
}

export function normalize(list, key) {
  return list.reduce((obj, item) => {
    obj[get(item, key)] = item;
    return obj;
  }, {});
}

export function removeNullEmptyValues(values) {
  return uniq(values).filter((value) => !isNil(value) && !!value.length);
}

export function uniqueByValue(arr, key) {
  return arr.reduce((a, d) => {
    if (!a.includes(d[key])) {
      a.push(d[key]);
    }
    return a;
  }, []);
}

export function compareTwoStrings(sourceText, targetText) {
  let isEqualText = false;
  if (!isNil(sourceText) && !isNil(targetText)) {
    if (isStringType(sourceText) && isStringType(targetText)) {
      isEqualText =
        sourceText.toLowerCase().replace(/\s/g, '') ===
        targetText.toLowerCase().replace(/\s/g, '');
    }
  }
  return isEqualText;
}

export function getJsonFileData(fileData, fileName) {
  try {
    return JSON.parse(fileData);
  } catch (e) {
    throw new Error(`Invalid Json File: ${fileName} - ${e}`);
  }
}

export function isStringFunction(funcString) {
  try {
    // eslint-disable-next-line no-unused-vars
    let evalFunc;
    // eslint-disable-next-line no-eval
    const result = eval(`evalFunc = ${funcString}`);
    if (result instanceof Function) {
      return;
    } else {
      throw new Error('Function string is not a function');
    }
  } catch (e) {
    throw new Error(`Error in function string: ${e}`);
  }
}

export function getTotalSum(object, key) {
  return !isNil(sumBy(object, key)) ? sumBy(object, key) : 0;
}

export function getMinValue(object, key) {
  const minValue = minBy(object, key);
  return !isNil(minValue) ? minValue[key] : 0;
}

export function getMaxValue(object, key) {
  const maxValue = maxBy(object, key);
  return !isNil(maxValue) ? maxValue[key] : 0;
}

export function convertDataListDropDown(dropdownValue) {
  const listDropDown = [];
  !!dropdownValue.length &&
    dropdownValue.forEach((value) => listDropDown.push({ item: value, value }));
  return listDropDown;
}

export function isOSBaseType(documentType) {
  return (
    documentType && DocumentFileTypes[documentType]?.baseType ===
    DocumentFileTypes.OPERATING_STATEMENT?.baseType
  );
}

export function isRRBaseType(documentType) {
  return (
    documentType && DocumentFileTypes[documentType].baseType ===
    DocumentFileTypes.RENT_ROLL.baseType
  );
}

export function isOSFullBaseType(documentType) {
  return (
    DocumentFileTypes[documentType].baseType ===
    DocumentFileTypes.OS_FULL.baseType
  );
}

export const isMFBaseType = (assetType, dynamicAssetTypes) => {
  const currentAssetTypeBaseClass = dynamicAssetTypes[assetType].baseClass;
  return multifamilyBaseClassesForExtraction.includes(currentAssetTypeBaseClass)
}

export const isRetailBaseType = (assetType, dynamicAssetTypes) => {
  const currentAssetTypeBaseClass = dynamicAssetTypes[assetType].baseClass;
  return retailBaseClassesForExtraction.includes(currentAssetTypeBaseClass)
}

export const isHotelBaseType = (assetType, dynamicAssetTypes) =>
  dynamicAssetTypes[assetType].baseClass === dynamicAssetTypes.HOTEL.baseClass;

export const json2CSVConverter = (items, headers) => {
  const replacer = (key, value) => (value === null ? '' : `${value}`);
  let csv = items.map((row) =>
    headers
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(',')
  );
  csv.unshift(headers.join(','));
  csv = csv.join('\r\n');
  return csv;
};

export const saveAsFile = (text, filename) => {
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, filename);
};

export function blobToFile(theBlob, fileName){       
  return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}

export const exportCSVFile = (listItems, headers, fileName) => {
  const csvText = json2CSVConverter(listItems, headers);
  saveAsFile(csvText, fileName);
};

export const isNotEmptyOrNaNValue = (object, key) =>
  object[key] !== '' && !isNaN(object[key]);

export const extractTextBetweenBracket = (text) => text.match(/\(([^)]+)\)/)[1];

export const fetchBaseTypeByAssetType = (assetType, dynamicAssetTypes) => {
  const baseClass = Reflect.ownKeys(dynamicAssetTypes)
    .map((assetClass) => dynamicAssetTypes[assetClass])
    .filter((f) => f.key === assetType);
  return baseClass.length ? baseClass[0].baseClass : baseClass;
};

export const isFreeBieApp = appConfig.isFreebieApp;

export const extractNumberFromString = (str) =>
  !!str && Number(str.replace(/\D+/g, ''));

export const sortPeriodDateByYear = (periodDates) => {
  let yearWithPeriodDiffrence = null;
  if (periodDates) {
    const sortPeriodByYear = sortBy(periodDates, (date) =>
      moment(date.periodFrom, 'MM/DD/YYYY').format('X')
    );
    yearWithPeriodDiffrence = sortPeriodByYear.reduce(
      (obj, date) =>
        Object.assign(obj, {
          [moment(date.periodFrom, 'MM/DD/YYYY').format('YYYY')]:
            moment(date.periodTo, 'MM/DD/YYYY').diff(
              moment(date.periodFrom, 'MM/DD/YYYY'),
              'months'
            ) + 1
        }),
      {}
    );
  }
  return yearWithPeriodDiffrence;
};

export const reportDateFormat = (date) =>
  date ? new Date(moment(new Date(date)).format('MM/DD/YYYY')) : null;

export function getKeyValuePair(obj) {
  const result = {};
  function fetchKeyValuePair(obj, currentKey) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      let value = obj[key];
      if (value !== undefined) {
        if (value && typeof value === 'object') {
          fetchKeyValuePair(value, key);
        } else {
          result[key] = value;
        }
      }
    }
  }
  fetchKeyValuePair(obj);
  return result;
}

export const getTimestampToFormatedTime = (timestamp) => {
  timestamp = +timestamp;
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = timestamp % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const getTimestampToFormatedMinuteString = (timestamp) => {
  timestamp = +timestamp;
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  return minutes.toString().padStart(2, '0');
};

export const getTimestampToFormatedSecondString = (timestamp) => {
  timestamp = +timestamp;
  const seconds = timestamp % 60;
  return seconds.toString().padStart(2, '0');
};

export const isValidNumber = (val) =>
  !!val && !isNaN(val) && Number.isFinite(val);
export const removeSpecialCharacter = (value) =>
  !!value && value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ');

export const getFileExtension = (filename) =>
  /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;

export const validateDocumentFile = (
  file,
  allowedFileSize = 10,
  validExtensions
) => {
  const cachedValidExtentions = validExtensions
    ? validExtensions.map(toLower)
    : undefined;
  const fileExtension = getFileExtension(file.name);
  const fileSize = file.size;
  if (allowedFileSize) {
    if (fileSize / (1024 * 1024) > allowedFileSize) {
      throw new Error(`Max File Size: ${allowedFileSize} MB`);
    }
  }
  if (
    cachedValidExtentions &&
    !cachedValidExtentions.includes(toLower(fileExtension))
  ) {
    console.log(cachedValidExtentions, fileExtension);
    throw new Error(`Allowed File Extensions:  ${cachedValidExtentions}`);
  }
};

const getComponentValue = (addressComponents, componentName, useLongName = true) => {
  const foundComponent =
    find(addressComponents, (component) => component.types.includes(componentName)) || {};

  const val = useLongName ? foundComponent.long_name : foundComponent.short_name;

  return val || '';
};

export function validateRequiredField(fieldName) {
  return function requiredField(value) {
    let error;
    if (!value) {
      error = `${fieldName} is required!`;
    }
    return error;
  };
}

export const getLocationDetails = (details) => {
  const lat = get(details, 'geometry.location.lat');
  const lng = get(details, 'geometry.location.lng');
  const googlePlaceId = details.place_id;
  const city = getComponentValue(
    details.address_components,
    'locality'
  );
  const state = getComponentValue(
    details.address_components,
    'administrative_area_level_1',
    false
  );

  const zip = getComponentValue(details.address_components, 'postal_code');
  const formattedAddress = details.formatted_address;
  const addressComponents = formattedAddress.split(",");
  const onlyAddressWithoutLocation = addressComponents.filter((c,i) => {
    return i < addressComponents.length - 3
  });


  return {
    lat,
    lng,
    googlePlaceId,
    city,
    state,
    zip,
    formattedAddress: onlyAddressWithoutLocation.join(",")
  };
};

function getNewClassName(newClassName) {
  if (!Array.isArray(newClassName)) return newClassName;

  return newClassName.filter((name) => name).join(' ');
}

export function appendClassName(baseClassName, newClassName) {
  if (!newClassName) return baseClassName || '';
  if (!baseClassName) return getNewClassName(newClassName) || '';
  return `${baseClassName} ${getNewClassName(newClassName)}`;
}

export function updateWorkBookSheetName(workbookData, isWbLoading = true) {
  if (workbookData && Reflect.ownKeys(workbookData).length > 0) {
    // eslint-disable-next-line no-prototype-builtins
    if (workbookData?.hasOwnProperty('sheets')) {
      const { sheets } = workbookData;
      Reflect.ownKeys(sheets).forEach((sheetName) => {
        const foundSheet = sheetMappings.find(
          (sheet) =>
            sheet[isWbLoading ? 'codeName' : 'displayName'] === sheetName
        );
        if (foundSheet) {
          const { codeName, displayName } = foundSheet;
          const codeSheetName = isWbLoading ? codeName : displayName;
          const displaySheetName = isWbLoading ? displayName : codeName;
          if (displaySheetName !== codeSheetName) {
            sheets[codeSheetName].name = displaySheetName;
            sheets[displaySheetName] = sheets[codeSheetName];
            // eslint-disable-next-line prefer-reflect
            delete sheets[codeSheetName];
          }
        }
      });
    }
  }
  return workbookData;
}

export const getFilteredArrayCount = (array, valueToSearch) =>
  array.filter((arraryValue) => arraryValue === valueToSearch).length;


export const showPaymentPopup = (company, checkField) => {
  const { isTrialOffering, isEnterpriseCompany } = company;
  const canDownload = company[checkField];
  if (!canDownload) {
    return !canDownload;
  }

  if (!isEnterpriseCompany && isTrialOffering) {
    return false;
  }

  return !canDownload;
};

export const disableAllRoutes = (company, userRole) => {
  if (userRole === USER_ROLES.SUPER_ADMIN.key) {
    return false;
  }
  const { isTrialOffering, isEnterpriseCompany, trialDays, createdAt, paymentDueDate, graceDays } = company;
  const trialDaysExpired = moment().isAfter(moment(createdAt).add(+trialDays, 'days'));
  if (!isEnterpriseCompany && isTrialOffering && trialDaysExpired) {
    return true;
  }

  const addedGraceDays = moment(paymentDueDate).add(+graceDays, 'days');
  const hideDownloadModelDropdown = moment().isAfter(addedGraceDays)

  if (!isEnterpriseCompany && !isTrialOffering && hideDownloadModelDropdown) {
    return true;
  }

  return false
};

export const getSortedJson = (object, key, category) => {
  const jsonObject = Object.values(object);
  jsonObject.sort((a, b) => {
    if ((!a[category] && !b[category]) || (a[category] && b[category])) {
      if (a[key].toUpperCase() < b[key].toUpperCase()) return -1;
      else if (a[key].toUpperCase() > b[key].toUpperCase()) return 1;
      return 0;
    } else {
      if (!a[category]) return -1;
      return 1;
    }
  })
  return jsonObject;
}

export const getCurrencyAverage = (
  totalNumbers, counts
) => {
  let totalValue = Math.round(totalNumbers / counts).toString();
  if (totalValue.substring(0, 1) === '-') {
    totalValue = `$(${(totalValue.replace(/-/g, ''))})`
  } else {
    totalValue = `$${totalValue}`
  }
  return totalValue
};

export const getApiIntergationFormField = (object, index) => {
  const dropDownValues = object.dropdownValues ? object.dropdownValues.split(',').filter((value) => value.trim() !== '') : [];
  const isDisabledField = convertStringToBoolean(object.disabled) || false;
  if (isEmpty(dropDownValues) && object.type === "dropdown") {
    return null;
  }
  return !isEmpty(dropDownValues)
    ? (<FormikSelectField
      label={object.label}
      name={`api_integration.${object.name}`}
      disabled={isDisabledField} 
      showRequiredAstric={!isDisabledField && convertStringToBoolean(object.required)}
    >
      <option value="">{object.placeholder}</option>
      {
        dropDownValues.map(value => (
          <option key={value} value={value}>{value}</option>
        ))
      }
    </FormikSelectField>
    )
    : <FormikTextField
      key={index}
      label={object.label}
      type={object.type}
      disabled={isDisabledField} 
      placeholder={object.placeholder}
      showRequiredAstric={!isDisabledField && convertStringToBoolean(object.required)}
      name={`api_integration.${object.name}`}
    />
}

function toColumnName(num) {
  let ret;
  let a;
  let b;
  for (ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(Number((num % b) / a) + 65) + ret;
  }
  return ret;
}

function removeSpecialChar(txt) {
  if (txt !== undefined) {
    return txt.replace(/[^a-zA-Z0-9]/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
  }
}

function getUniqueKeysFromData(rows) {
  return Object.keys(
    rows.reduce((result, obj) => Object.assign(result, obj), {}),
  );
}

export const getAiParserDataColumns = (rows) => {
  const columns = [];
  const uniqueKeys = getUniqueKeysFromData(rows);
  uniqueKeys.forEach((key) => {
      columns.push({ name: key, size: 180, headerName: removeSpecialChar(key) });
  });

  return columns.map((c, i) => ({
    ...c,
    displayName: toColumnName(i + 1),
  }));
}

export const isValidAmountNumber = (str) =>  /^[0-9,.]*$/.test(str);

export const getAiParserDataRows = (rows) => {
  const filterRows = rows.map((row) => {
    const rowObj = Reflect.ownKeys(row).reduce((acc, key) => {
      if (key !== "id") {
        let values = row[key];
        if (
          Array.isArray(values) &&
          values.some((value) => typeof value === "string")
        ) {
          if(typeof values === 'string' && isValidAmountNumber(values.replace(',', ''))) {
            values = Number(values);
          } else {
            values = values.join("\n");
          }
        }
        if (Array.isArray(values) && values.length === 0) {
          values = "";
        }    

        if (
          Array.isArray(values) &&
          values.some((value) => typeof value === "object")
        ) {
          let str = "";
          values.map((value) => {
          
            Reflect.ownKeys(value).forEach((key) => {
              const keyValue = JSON.stringify(value[key]) || '';
              str += `${key} - ${keyValue.replace(/\"/g, "")}` + ",\n";
              if (typeof value[key] === "object") {
                str += '\n'
              }
            })
          str += '\n'
          });
          values = str;
        }
        if(typeof values === 'string' && isValidAmountNumber(values)) {
          acc[key] = Number(values.replace(',', ''));
        } else {
          acc[key] = values;
        }
      }
      return acc;
    }, {});
    return rowObj;
  });
  return filterRows
}

export const isCustomMonthsExist = ( workbookData, isOSBaseType ) => {
  if (isOSBaseType) {
    const sheets = get(workbookData, 'sheets');
    const extractedData = get(sheets, TARGET_SHEET_NAME);
    if (extractedData && extractedData?.data.hasOwnProperty('dataTable')) {
      const columns = extractedData?.data.dataTable[0];
      return Object.values(columns).some(col => !!col?.value &&  col?.value.includes('/OADate'))
    }
   }
  return false;
}

export function getFpFloorPlan(fp) {
  for (let i = 0; i < fpConfigExtractor.length; i++) {
    const [regEx, extractor] = fpConfigExtractor[i];
    const floorPlan = extractor(regEx, fp);
    if (floorPlan) {
      if (floorPlan.baths) {
        const intBaths = parseInt(floorPlan.baths);
        if (intBaths > 9) {
          floorPlan.baths = (intBaths / 10).toString();
        }
      }
      return floorPlan;
    }
  }
  return null;
}


function floorPlanExtractorBedAndBath(regex, text) {
  const match = regex.exec(text);
  if (match) {
    console.log(text, regex, match);
    const baths = (match[2] !== undefined) ? parseFloat(match[2]).toString() : '1';
    const beds = match[1] === '0' ? 'studio' : match[1];

    return { BD: beds, BA: baths };
  }
  return null;
}

function floorPlanExtractorStudio(regex, text) {
  const match = regex.exec(text);
  if (match) {
    return { BD: 'studio', BA: ''};
  }
  return null;
}

function floorPlanExtractorConstants(beds, baths) {
  return function(regex, text) {
    const match = regex.exec(text);
    if (match) {
      return { BD: beds, BA: baths };
    }
    return null;
  };
}

const fpConfigExtractor = [
  // IMPORTANT!!!
  // ORDER MATTERS!!!
  // WHILE MAKING ANY CHANGE BELOW TRY USING THE test.tsx.dummy page to test
  [new RegExp(/g21(\d)x(\d\d?)[a-z]*/i), floorPlanExtractorBedAndBath],
  [new RegExp(/g21stu.*/i), floorPlanExtractorStudio],
  [new RegExp(/f50(\d)x(\d\d?)[a-z]*/i), floorPlanExtractorBedAndBath],
  [new RegExp(/f50stu.*/i), floorPlanExtractorStudio],
  [new RegExp(/f50den.*/i), floorPlanExtractorStudio],
  [new RegExp(/(\d)X(\d\.?\d?).*/i), floorPlanExtractorBedAndBath],
  [new RegExp(/(\d)(\d)G.*/i), floorPlanExtractorBedAndBath],
  [new RegExp(/(\d).*BD.*(\d).*B(th)/i), floorPlanExtractorBedAndBath],
  [new RegExp(/(\d)bed\/(\d)bath/), floorPlanExtractorBedAndBath],
  [new RegExp(/single/), floorPlanExtractorConstants('1', '1')],
  [new RegExp(/double/), floorPlanExtractorConstants('2', '1')],
  [new RegExp(/triple/), floorPlanExtractorConstants('3', '2')],
  [new RegExp(/.*(studio|stdio|EFF|stu|^su).*/i), floorPlanExtractorStudio],
  [new RegExp(/(\d)b(\d)b/i), floorPlanExtractorBedAndBath],
  [new RegExp(/.*(\d)(?:BR|D).?(\d)(B|D).*/i), floorPlanExtractorBedAndBath],
  [new RegExp(/(\d)BX?(\d\.?\d?)B.*/i), floorPlanExtractorBedAndBath],
  [new RegExp(/(\d)\/(\d\.\d\d).*/), floorPlanExtractorBedAndBath],
  [new RegExp(/(\d)\/(\d\.?\d?).*/), floorPlanExtractorBedAndBath],
  [new RegExp(/(\d)(\d)?[A-Z][A-Z]/i), floorPlanExtractorBedAndBath],
  [new RegExp(/^1U.*/), floorPlanExtractorConstants('1', '1')],
  [new RegExp(/[^0-9]*(\d)[.*-](\d)[^0-9]*/), floorPlanExtractorBedAndBath],
];


export function getQuarterDateFromString(date) {
  if (typeof date === 'string' && date.startsWith('Q1')) {
    const [, year] = date.split(' ');
    return `${year}-03-31`;
  } else if (typeof date === 'string' && date.startsWith('Q2')) {
    const [, year] = date.split(' ');
    return `${year}-06-31`;
  } else if (typeof date === 'string' && date.startsWith('Q3')) {
    const [, year] = date.split(' ');
    return `${year}-09-30`;
  } else if (typeof date === 'string' && date.startsWith('Q4')) {
    const [, year] = date.split(' ');
    return `${year}-12-31`;
  } else if (typeof date === 'number') { // expecting only year here
    return `${date}-12-31`;
  } else {
    return date;
  }
}

export function getCellFromatter(columnType) {
  switch(columnType) {
    case 'string':
      return ''
    case 'number': 
      return cellNumberFormat
    case 'currency': 
      return USCurrencyFormat
    case 'date': 
      return USDateFormat
    default: 
      return ''    
  }
}
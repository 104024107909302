import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import List from '../../core/List/List';
import {
  currentCompanySelector,
  companyTemplatesAsListSelector
} from '../../../store/selectors';
import CompanyTemplatePopups from '../CompanyTemplatePopups/CompanyTemplatePopups';
import UploadProgress from '../UploadProgress/UploadProgress';
import CompanyTemplateListItem from './CompanyTemplateListItem';
import SwitchButton from '../../core/SwitchButton/SwitchButton';
import { PAGINATION_DEFAULT } from '../../../constants';

function CompanyTemplateList({ templateService, companyTemplates, companyUUID }) {
  const [showUploadTemplateFormPopup, setShowUploadTemplateFormPopup] = useState(false);
  const [showUpdateTemplate, setShowUpdateTemplate] = useState(false);
  const [template, setTemplate] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDownloadCsv, setIsDownloadCsv] = useState(true);

  const hidePopups = useCallback(
    () => {
      setShowUploadTemplateFormPopup(false);
      setShowDeleteConfirm(false);
      setShowUpdateTemplate(false);
    },
    [],
  );

  const onDeleteTemplate = useCallback(async () => {
    hidePopups();
    try {
      await templateService.deleteTemplate(companyUUID, template);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [template, companyUUID, hidePopups]);

  const onUpdateTemplate = useCallback((template) => {
    setTemplate(template);
    setShowUpdateTemplate(true);
  }, []);

  const openDeleteTemplateModal = useCallback((template) => {
    setTemplate(template);
    setShowDeleteConfirm(true);
  }, []);

  const filterListItem = useCallback(
    (companyTemplate, searchText) => 
      companyTemplate.templateName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
    []
  );

  const onDownloadTemplate = useCallback((template) => {
    templateService.downloadTemplate(companyUUID, template, isDownloadCsv);
  }, [companyUUID, isDownloadCsv]);

  const onDownloadDictionary = useCallback((template) => {
    templateService.downloadDictionary(template, isDownloadCsv);
  }, [isDownloadCsv]);

  const onDownloadCategorySequence = useCallback((template) => {
    templateService.downloadCategorySequence(template, isDownloadCsv);
  }, [isDownloadCsv]);

  const onDownloadSummaryTotalConfig = useCallback((template) => {
    templateService.downloadSummaryTotalConfig(template, isDownloadCsv);
  }, [isDownloadCsv]);

  const switchCsvJsonButton = useMemo(() => (
    <SwitchButton
      isActive={isDownloadCsv}
      onChange={setIsDownloadCsv}
      offLabel="JSON"
      onLabel="CSV"
    />
  ), [isDownloadCsv]);

  const selectedTemplate = useMemo(() => {
    return companyTemplates.find(t => t.uuid === template?.uuid);
  }, [companyTemplates, template]);

  return (
    <>
      <List
        addButtonText="Add Template"
        title="Templates"
        onAdd={() => setShowUploadTemplateFormPopup(true)}
        listItems={companyTemplates}
        filterListItem={filterListItem}
        utilityButtons={[switchCsvJsonButton]}
        getKey={template => template.uuid}
        recordsPerPage={PAGINATION_DEFAULT.pageSize}
        ListItem={({ item: companyTemplate }) => (
          <CompanyTemplateListItem
            template={companyTemplate}
            isDownloadCsv={isDownloadCsv}
            companyUUID={companyUUID}
            onDeleteTemplate={openDeleteTemplateModal}
            onDownloadDictionary={onDownloadDictionary}
            onDownloadTemplate={onDownloadTemplate}
            onDownloadCategorySequence={onDownloadCategorySequence}
            onDownloadSummaryTotalConfig={onDownloadSummaryTotalConfig}
            onUpdateTemplate={onUpdateTemplate} />
        )}
      />
      <UploadProgress />
      <CompanyTemplatePopups
        onHide={hidePopups}
        onDeleteConfirm={onDeleteTemplate}
        showUploadTemplate={showUploadTemplateFormPopup}
        companyUUID={companyUUID}
        template={selectedTemplate}
        showDeleteConfirm={showDeleteConfirm}
        showUpdateTemplate={showUpdateTemplate}
      />
    </>
  );
}

const mapStateToProps = state => ({
  companyTemplates: companyTemplatesAsListSelector(state),
  companyUUID: currentCompanySelector(state)
});

export default connect(mapStateToProps)(withServices('templateService')(CompanyTemplateList));

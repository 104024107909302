import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormModal from '../FormModal/FormModal';
import TemplateModelForm from '../TemplateModelForm/TemplateModelForm';
import useModalForm from '../../../hooks/useModalForm';
import UpdateTemplateModelForm from '../UpdateTemplateModelForm/UpdateTemplateModelForm';

function TemplateModelPopups({
  model, onHide, showUploadModel, showDeleteConfirm, onDeleteConfirm, companyUUID, templateUUID,
  showUpdateModel, showModalInformation, onConfirmUpdateShowReportsForModel, showUpdateModelToShowReport,
  currentModelToShowReport
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  const [updateModelToShowReportPopupMessage, setUpdateModelToShowReportPopupMessage] =  useState('');
  function ModelInformationTable({ model }) {
    return (
      <>
        <table style={{ border: '1px solid #ddd', borderCollapse: 'collapse', width: '100%', marginTop: '10px', marginBottom: '20px' }}>
          <caption style={{ captionSide: 'top', textAlign: 'left', fontWeight: 'bold', marginBottom: '8px' }}>Model Information</caption>
          <tbody>
            <tr>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>Model Name</strong></td>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model?.modelName}</td>
            </tr>
            <tr>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>File Path</strong></td>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model?.modelFilePath}</td>
            </tr>
            <tr>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>Updated At</strong></td>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model?.updatedAt}</td>
            </tr>
          </tbody>
        </table>
        {model.hasReportTemplateFile && (
          <table style={{ border: '1px solid #ddd', borderCollapse: 'collapse', width: '100%' }}>
            <caption style={{ captionSide: 'top', textAlign: 'left', fontWeight: 'bold', marginBottom: '8px' }}>Report Information</caption>
            <tbody>
              <tr>
                <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>File Path</strong></td>
                <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model.reportFilePath}</td>
              </tr>
            </tbody>
          </table>)}
      </>
    );
  }

  useEffect(() => {
    if(model) {
      if(!model.showReportsForThisModel) {
        if(currentModelToShowReport) {
          setUpdateModelToShowReportPopupMessage(`Are you sure you want to enable the ${model.modelName} to show report and disable the earlier enabled ${currentModelToShowReport.modelName}?`);
        }
        else {
          setUpdateModelToShowReportPopupMessage(`Are you sure you want to enable the ${model.modelName} to show report?`);
        }
      } else {
        setUpdateModelToShowReportPopupMessage(`Are you sure you want to disable the ${model.modelName} to show report?. If none is active then model with reports which has been updated most recently will be used to show reports.`);
      }
    }
  }, [showUpdateModelToShowReport, model])


  return (
    <div>
      {showUploadModel && (
        <FormModal
          onHide={onHide}
          show={showUploadModel}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          title="Upload Model"
        >
          <TemplateModelForm
            initialValues={{
              ...model,
              reportTemplateName: '',
              reportTemplateFile: null,
            }}
            bindSubmission={bindSubmission}
            onSubmitSuccess={onHide}
            companyUUID={companyUUID}
            templateUUID={templateUUID}
          />
        </FormModal>
      )}
      {showUpdateModel && (
        <FormModal
          onHide={onHide}
          show={showUpdateModel}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          title="Update Model"
        >
          <UpdateTemplateModelForm
            initialValues={{
              ...model,
              reportTemplateName: ''
            }}
            bindSubmission={bindSubmission}
            onSubmitSuccess={onHide}
            companyUUID={companyUUID}
            templateUUID={templateUUID}
          />
        </FormModal>
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          show={showDeleteConfirm}
          title={`Delete Model ${model.modelName}?`}
          message={`Are you sure you want to delete the Model ${model.modelName}`}
          onConfirm={onDeleteConfirm}
          onCancel={onHide}
        />
      )}
      {showUpdateModelToShowReport && (
        <ConfirmationModal
          show={showUpdateModelToShowReport}
          title={`${model.modelName ? 'Deactivate' : 'Activate'}  ${model.modelName}?`}
          message={updateModelToShowReportPopupMessage}
          onConfirm={onConfirmUpdateShowReportsForModel}
          onCancel={onHide}
        />
      )}
      {showModalInformation && (
        <ConfirmationModal
          show={showModalInformation}
          title={model.hasReportTemplateFile ? 'Model and Report Information' : 'Model Information'}
          size={'lg'}
          message={(
            <ModelInformationTable model={model} />
          )}
          showConfirmButton={false}
          onCancel={onHide}
        />
      )}

    </div>
  );
}

export default TemplateModelPopups;

export function removeCurrencySymbol(value) {
    const isCurrency = /\$/g;
    return isCurrency.test(value) ? value.substr(1) : value;
}

export function removeSpecialCharacter(value) {
    const isNumber = /\(([^)]+)\)/g;
    if (isNumber.test(value)) {
        const extractedValue = value.substr(1).slice(0, -1);
        if (parseFloat(extractedValue).toString() !== 'NaN') {
            return `-${extractedValue}`;
        }
    } else {
        return removeCurrencySymbol(value);
    }
}

/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.css';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import { Container, Row, Col, OverlayTrigger, Tooltip, Button  } from 'react-bootstrap';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { IO } from '@grapecity/spread-excelio';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { allowDownloadableSummarySheet, dynamicAssetTypesAsObjSelector, readOnlyAccessSelector } from '../../../store/selectors';
import ColorPicker from '../ColorPicker/ColorPicker';
import {
  cellNumberFormat,
  DocumentFileTypes,
  affixComponentName,
  USCurrencyFormat
} from '../../../constants';
import styles from './SpreadSheet.module.scss';
import WorkbookManager from '../../../services/WorkbookManager';
import SheetIcon from '../Button/SheetIcon';
import DocExtractionTaggingInfo from '../../app/DocExtractionTaggingInfo/DocExtractionTaggingInfo';
import DocExtractionFindModule from '../../app/DocExtractionFindModule/DocExtractionFindModule';
import { noopFunc } from '../../../lib/utils/noop';
import { KeyboardShortcutsOpened } from '../../../constants/eventTrackerMessage';
import SwitchButton from '../SwitchButton/SwitchButton';
import ConfirmationModal from '../../app/ConfirmationModal/ConfirmationModal'
import IconButton from '../Button/IconButton';
import SpreadSheetPrintPdfPophover from '../SpreadSheetPrintPdfPophover/SpreadSheetPrintPdfPophover';

function SpreadSheet({
  className = '',
  company,
  currentActiveSheetName = null,
  data,
  onDataLoad,
  readonly,
  showToolbar,
  isProjectReport = false,
  canShareReport = false,
  onInitManager,
  sheetCount,
  assetType = '',
  docType = '',
  taggingInfo = {},
  onSaveData,
  onDownloadXLS,
  onDownloadPDF,
  hasAutoWidth = false,
  sheetHeight,
  onToggleSubHeader,
  onRefereceWindowOpen,
  onSlidePaneOpen,
  onToggleMonths,
  onShowReportLink,
  setCurrentActiveSheetName = null,
  onReportSave = noopFunc,
  setWorkbookToast = noopFunc,
  onCloseExtractionPreview = noopFunc,
  canDownloadDocument = false,
  canDownloadReportWorkbook,
  eventTrackerService,
  dynamicAssetTypes,
  isGlobalReport = false,
  isDocFallbackApproach=false,
  document,
  project,
  isTenantFiltered,
  setIsTenantFiltered,
  isFutureTenantEnabled,
  isActive,
  setIsActive,
  readOnlyAccess,
  isAllowDownloadableSummarySheet
}) {
  const spreadEl = useRef(null);
  const formulaEl = useRef(null);
  const statusEl = useRef(null);
  const cellRangeEl = useRef(null);
  const [manager, setManager] = useState(null);
  const [formatCopied, setFormatCopied] = useState(false);
  const [showFindModal, setShowFindModal] = useState(false);
  const [isWorkbookDownloading, setIsWorkbookDownloading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [sheetNames, setSheetNames] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const isRentRollWithMultiFamily = useMemo(
    () =>
      (assetType === dynamicAssetTypes.MULTIFAMILY.key ||
        assetType === dynamicAssetTypes.HEALTHCARE.key) &&
      docType === DocumentFileTypes.RENT_ROLL.key,
    [assetType, docType, dynamicAssetTypes]
  );

  const isDocumentOperatingStatement = useMemo(
    () => docType !== DocumentFileTypes.RENT_ROLL.key,
    [docType]
  );

  const isDocumentOSFullDocument = useMemo(
    () => docType === DocumentFileTypes.OS_FULL.key,
    [docType]
  );

  useEffect(() => {
    const _manager = new WorkbookManager();
    setManager(_manager);
    return () => {
      _manager.destroy();
      setManager(null);
    };
  }, []);

  useEffect(() => {
    if (manager) {
      manager.init(spreadEl, formulaEl, statusEl, cellRangeEl, sheetCount, {
        formatCopied: () => setFormatCopied(true),
        copiedFormatCleared: () => setFormatCopied(false),
        setActiveSheet: setCurrentActiveSheetName
          ? (sheetName) => setCurrentActiveSheetName(sheetName)
          : () => {},
        setShowFindModal: () => setShowFindModal(true),
        setWorkbookToast: (msgConfig) => setWorkbookToast(msgConfig),
        docType,
        assetType
      });
      if (onInitManager) {
        onInitManager(manager);
      }
    }
  }, [manager, onInitManager, sheetCount]);

  useEffect(() => {
    if (manager) {
      manager.setData(data, hasAutoWidth);
      if (onDataLoad) {
        onDataLoad({ sheetNames: manager.getSheetNames() });
      }
    }
  }, [data, hasAutoWidth, manager, onDataLoad]);

  useEffect(() => {
    if (manager && currentActiveSheetName) {
      manager.setActiveSheet(currentActiveSheetName);
    } else if (manager) {
      manager.setActiveSheetOnLoad();
    }
  }, [currentActiveSheetName, manager]);

  useEffect(() => {
    manager && manager.setReadonly(readonly);
  }, [manager, readonly]);

  function onSave() {
    if (onSaveData) {
      const data = manager.getWorkbookData();
      onSaveData(data);
    }
  }

  function onUndo() {
    manager.undo();
  }

  function onRedo() {
    manager.redo();
  }

  function onFreeze() {
    manager.freeze();
  }

  function onUnfreeze() {
    manager.unfreeze();
  }

  function onFormatChange(format) {
    manager.setFormat(format);
  }

  function setFilter() {
    manager.setFilter();
  }

  function onSortAscending() {
    manager.sort(undefined, true);
  }

  function onSortDescending() {
    manager.sort(undefined, false);
  }

  function setForeColor(color) {
    manager.setColor(undefined, true, color.hex);
  }

  function setBackColor(color) {
    manager.setColor(undefined, false, color.hex);
  }

  function downloadXLS() {
    if (onDownloadXLS) {
      setIsWorkbookDownloading(true);
      const data = manager.getWorkbookDataWithoutSheetProtection();
      const io = new IO();
      io.save(
        data,
        (blob) => {
          onDownloadXLS(blob);
          setIsWorkbookDownloading(false);
        },
        console.log
      );
    }
  }

  function copyFormat() {
    if (!formatCopied) {
      manager.copyFormat();
    } else {
      manager.clearCopyFormat();
    }
  }

  function hAlign(alignment) {
    manager.setHAlign(alignment);
  }

  function cellTextFormat(format) {
    manager.setTextFormat(format);
  }

  function onSlideAffixClik(selectedTab, componentName) {
    onSlidePaneOpen(selectedTab, componentName);
  }

  function onReverseRowSignClick() {
    manager.setReverseSignToRow();
  }

  function onSearchQuery(query) {
    manager.searchKeywordInSpreadView(query);
  }

  function onPrintPdf(sheetIndex) {
    setIsWorkbookDownloading(true);
    manager.onPrintPdf(sheetIndex)
    setIsWorkbookDownloading(false);
  }

  return (
    <>
      <div className={`${className} vertical-section`} style={sheetHeight}>
        {showToolbar && (
          <React.Fragment>
            <Container fluid className="p-0">
              <Row>
                <Col>
                  <div className={`${styles.spreadsheetsContainer}`}>
                    <div className={`${styles.spreadsheetToolbarWrapper}`}>
                      <div className={`${styles.spreadsheetToolbar}`}>
                        <SheetIcon
                          className={`${styles.undoIcon}`}
                          onClick={onUndo}
                          title="Undo"
                        />
                        <SheetIcon
                          className={`${styles.redoIcon}`}
                          onClick={onRedo}
                          title="Redo"
                        />
                        <div
                          className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                          aria-disabled="true"
                          role="separator line"
                        >
                          {' '}
                          &nbsp;{' '}
                        </div>
                        <SheetIcon
                          className={`${styles.lockIcon}`}
                          onClick={onFreeze}
                          title="Freeze Rows/Columns"
                        />
                        <SheetIcon
                          className={`${styles.unlockIcon}`}
                          onClick={onUnfreeze}
                          title="Unfreeze Rows/Columns"
                        />
                        <div
                          className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                          aria-disabled="true"
                          role="separator line"
                        >
                          {' '}
                          &nbsp;{' '}
                        </div>
                        <SheetIcon
                          className={`${styles.ascendingIcon}`}
                          onClick={onSortAscending}
                          title="Sort Ascending"
                        />
                        <SheetIcon
                          className={`${styles.descendingIcon}`}
                          onClick={onSortDescending}
                          title="Sort Descending"
                        />
                        <div
                          className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                          aria-disabled="true"
                          role="separator line"
                        >
                          {' '}
                          &nbsp;{' '}
                        </div>
                        <div
                          className={`position-relative d-inline-block ${styles.docsToolbarButton}`}
                        >
                          <DropdownButton
                            title="123"
                            className={`dropdown-btn-xs ${styles.spreadsheetToolbarDropdownButton}`}
                            variant="light"
                            size="sm"
                          >
                            <Dropdown.Item onClick={() => onFormatChange('')}>
                              General
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onFormatChange(0)}>
                              Integer
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onFormatChange(cellNumberFormat)}
                            >
                              Number
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onFormatChange(USCurrencyFormat)}
                            >
                              Currency
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onFormatChange(USCurrencyFormat)}
                            >
                              Accounting{' '}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onFormatChange('mm/dd/yyyy;@')}
                            >
                              Short Date
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                onFormatChange('dddd, mmmm dd, yyyy')
                              }
                            >
                              Long Date
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onFormatChange('h:mm:ss AM/PM')}
                            >
                              Time
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onFormatChange('0%')}>
                              Percentages
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onFormatChange('# ?/?')}
                            >
                              Fraction
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onFormatChange('0.00E+00')}
                            >
                              Scientific
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onFormatChange('@')}>
                              Text
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                        <ColorPicker
                          onSetColor={(color) => setBackColor(color)}
                        >
                          <SheetIcon
                            className={`${styles.fillCellColorIcon}`}
                            title="Fill Cell Color"
                          />
                        </ColorPicker>
                        <div
                          className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                          aria-disabled="true"
                          role="separator line"
                        >
                          {' '}
                          &nbsp;{' '}
                        </div>
                        <SheetIcon
                          className={`${styles.boldIcon}`}
                          onClick={() => cellTextFormat('bold')}
                          title="Bold"
                        />
                        <SheetIcon
                          className={`${styles.italicIcon}`}
                          onClick={() => cellTextFormat('italic')}
                          title="Italic"
                        />
                        <SheetIcon
                          className={`${styles.underlineIcon}`}
                          onClick={() => cellTextFormat('underline')}
                          title="Underline"
                        />
                        <ColorPicker
                          onSetColor={(color) => setForeColor(color)}
                        >
                          <SheetIcon
                            className={`${styles.textColorIcon}`}
                            title="Text Color"
                          />
                        </ColorPicker>
                        <div
                          className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                          aria-disabled="true"
                          role="separator line"
                        >
                          {' '}
                          &nbsp;{' '}
                        </div>
                        <SheetIcon
                          className={
                            formatCopied
                              ? `${styles.paintIcon} text-warning`
                              : `${styles.paintIcon} text-auto`
                          }
                          onClick={copyFormat}
                          title="Paint Format"
                        />
                        <SheetIcon
                          className={`${styles.autofilterIcon}`}
                          onClick={setFilter}
                          title="Apply Row Filter"
                        />
                        <div
                          className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                          aria-disabled="true"
                          role="separator line"
                        >
                          {' '}
                          &nbsp;{' '}
                        </div>
                        <SheetIcon
                          className={`${styles.alignLeftIcon}`}
                          onClick={() => hAlign('left')}
                          title="Align Left"
                        />
                        <SheetIcon
                          className={`${styles.alignCenterIcon}`}
                          onClick={() => hAlign('center')}
                          title="Align Center"
                        />
                        <SheetIcon
                          className={`${styles.alignRightIcon}`}
                          onClick={() => hAlign('right')}
                          title="Align Right"
                        />
                        <div
                          className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                          aria-disabled="true"
                          role="separator line"
                        >
                          {' '}
                          &nbsp;{' '}
                        </div>
                        {isDocumentOperatingStatement && !isProjectReport && !isDocFallbackApproach && (
                          <>
                            <SheetIcon
                              className={`${styles.reverseSignIcon}`}
                              id="reverseRowSign"
                              onClick={onReverseRowSignClick}
                              title="Reverse Row Sign"
                            />
                            <div
                              className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                              aria-disabled="true"
                              role="separator line"
                            >
                              {' '}
                              &nbsp;{' '}
                            </div>
                            {
                              !isDocumentOSFullDocument && (
                                <>
                                  <SheetIcon
                                    className={`${styles.calendarMonthsIcon}`}
                                    id="toggleMonths"
                                    onClick={() => onToggleMonths()}
                                    title="Show/Hide Months"
                                  />
                                  <div
                                    className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                                    aria-disabled="true"
                                    role="separator line"
                                  >
                                    {' '}
                                    &nbsp;{' '}
                                  </div>
                                </>
                              )
                            }
                          </>
                        )}
                        {!isProjectReport && (
                          <SheetIcon
                            className={styles.searchIcon}
                            title="Find"
                            onClick={() => setShowFindModal(!showFindModal)}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={`spreadsheet-side-toolbar ${styles.docsToolbar} ${styles.spreadsheetSideToolbar}`}
                    >
                      {/* {
                        isRentRollWithMultiFamily && (
                          <SheetIcon id="configCC" className={`${styles.configChageCodeIcon}`} size="lg" onClick={() => onSlideAffixClik('chargeCodeConfig')} title="Rent Roll Configuration" />
                        )
                      } */}
                      {/* {
                        isDocumentOperatingStatement && (
                          <SheetIcon id="osSummary" className={`${styles.configChageCodeIcon}`} size="lg" onClick={() => onSlideAffixClik(affixComponentName.OPERATING_STATEMENT)} title="Document Summary Sheet" />
                        )
                      } */}
                      {onSaveData && (
                        <>{isFutureTenantEnabled && !readOnlyAccess &&
                          <OverlayTrigger show={false} placement="bottom" overlay={
                            <Tooltip>
                              {`Click to remove future tenants after ${moment(taggingInfo?.asOnDate).format('MM/DD/YYYY')}.`}
                            </Tooltip>}>
                            <SwitchButton
                              isActive={isActive}
                              onChange={() => {
                                isActive && setIsActive(!isActive)
                                !isActive && setOpenConfirmModal(!isActive)
                              }}
                              offLabel="Filter future tenants"
                              offLabelColor="text-muted"
                            />
                          </OverlayTrigger>
                          }
                          <DocExtractionTaggingInfo
                            docType={docType}
                            taggingInfo={taggingInfo}
                            project={project}
                            document={document}
                            readOnlyAccess={readOnlyAccess}
                          />
                        </>
                      )}
                      {
                        isProjectReport && canShareReport && (
                          <span className="text-danger small flex-grow-1 text-right mr-4">
                            Please save the report to share the latest version
                          </span>
                        )
                      }
                      <div
                        className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                        aria-disabled="true"
                        role="separator line"
                      >
                        {' '}
                        &nbsp;{' '}
                      </div>
                      {
                        isProjectReport && (
                          <>
                            {
                              canDownloadReportWorkbook && (
                                <SheetIcon
                                  disabled={isWorkbookDownloading}
                                  className={styles.downloadFileIcon}
                                  id="DownloadXLSXFile"
                                  size="lg"
                                  onClick={downloadXLS}
                                  title="Download Report"
                                />
                              )
                            }

                            {
                              !isGlobalReport && (
                                <SheetIcon
                                  className={styles.saveFileIcon}
                                  size="lg"
                                  id="SaveXLSXFile"
                                  onClick={onReportSave}
                                  title="Save Report"
                                />
                              )
                            }
                             
                            {
                              canShareReport && (
                                <SheetIcon
                                  className={styles.shareIcon}
                                  id="ProjectReportLink"
                                  size="lg"
                                  onClick={onShowReportLink}
                                  title="Share Report Link"
                                />
                              )
                            }
                          </>
                        )
                      }

                      {!isProjectReport && (
                        <>
                          <SheetIcon
                            disabled={isWorkbookDownloading}
                            className={isAllowDownloadableSummarySheet ? styles.excelIcon : styles.downloadFileIcon}
                            id="DownloadXLSXFile"
                            size="lg"
                            onClick={downloadXLS}
                            title="Download Workbook"
                          />
                          {
                            isAllowDownloadableSummarySheet && isDocumentOperatingStatement && (
                              <SpreadSheetPrintPdfPophover
                                isWorkbookDownloading={isWorkbookDownloading}
                                onPrintPdf={onPrintPdf}
                              />
                            )
                          }
                        </>
                      )}
                      {!isProjectReport && !readOnlyAccess && !isDocFallbackApproach && (
                        <>
                          <SheetIcon
                            className={`${styles.saveFileIcon}`}
                            size="lg"
                            id="SaveXLSXFile"
                            onClick={onSave}
                            hidden={!onSaveData}
                            title="Save Workbook"
                          />
                          <SheetIcon
                            className={`${styles.referenceWindowIcon}`}
                            id="refWindow"
                            size="lg"
                            onClick={onRefereceWindowOpen}
                            title="Open Reference Window"
                          />
                          <SheetIcon
                            className={`${styles.keyboardShortcutsIcon}`}
                            id="keyboardShortcuts"
                            size="lg"
                            onClick={() => {
                              onSlideAffixClik(
                                affixComponentName.SHORTCUT_KEYS,
                                affixComponentName.SHORTCUT_KEYS
                              );
                              eventTrackerService.track(
                                KeyboardShortcutsOpened
                              );
                            }}
                            title="Keyboard Shortcuts"
                          />
                        </>
                      )}
                      {
                        isDocFallbackApproach && (
                          <>
                            <div
                              className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`}
                              aria-disabled="true"
                              role="separator line"
                            >
                              {' '}
                              &nbsp;{' '}
                            </div>
                            <IconButton 
                              variant='danger'
                              size="md"
                              onClick={onCloseExtractionPreview}
                              className={'px-2 py-1 text-white border-none'} 
                              icon={faTimes} title='Close Extraction Preview'/>
                          </>
                       
                        )
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className={`pr-2 ${styles.formulaBarInputContainer}`}>
                <Col xs="auto" className="pr-0">
                  <span
                    id="SSCellBox"
                    className={`position-relative d-inline-block ${styles.formulaBarLabel}`}
                    ref={cellRangeEl}
                  >
                    A1
                  </span>
                </Col>
                <Col className={`${styles.formulaBarInput}`}>
                  <input
                    id="SSFormulaBox"
                    ref={formulaEl}
                    className={`position-relative d-inline-block m-0 ${styles.formulaBar} ${styles.cellInput}`}
                    style={{ flexGrow: 0 }}
                  />
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        )}
        <div className="vertical-section position-relative">
          <div ref={spreadEl} className="fill-parent" />
        </div>
        <div ref={statusEl} style={{ flexGrow: 0 }} />
      </div>
      {showFindModal && (
        <div className={styles.searchBox}>
          <DocExtractionFindModule
            onSearchQuery={onSearchQuery}
            onClose={() => setShowFindModal(false)}
          />
        </div>
      )}

      {openConfirmModal && (
        <ConfirmationModal
          show={openConfirmModal}
          title="Filter Future Tenants"
          message="This action will filter out the future tenants. If you save the document after applying this filter, future tenants will be permanently deleted from this document."
          onConfirm={() => {
            setOpenConfirmModal(false);
            setIsActive(!isActive);
          }}
          onCancel={() => {
            setOpenConfirmModal(false)
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state),
  isAllowDownloadableSummarySheet: allowDownloadableSummarySheet(state),
});

export default connect(mapStateToProps)(withServices('eventTrackerService')(SpreadSheet));

import React, { useRef, useState } from 'react';
import SheetIcon from '../Button/SheetIcon';
import styles from '../SpreadSheet/SpreadSheet.module.scss';
import { Dropdown, Overlay, Popover } from 'react-bootstrap';
import { sheetIndex } from '../../../constants';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

function SpreadSheetPrintPdfPophover({ isWorkbookDownloading, onPrintPdf }) {  
  return (
    <Dropdown
      className={`position-relative d-inline-block`}>
      <Dropdown.Toggle
        as={CustomToggle}
        id="printPdf"
      >
        <SheetIcon
          disabled={isWorkbookDownloading}
          className={styles.pdfIcon}
          id="printPdfButton"
          size="lg"
          onClick={null}
          title="Print Sheet"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        <Dropdown.Item
          disabled={isWorkbookDownloading}
          onClick={() => onPrintPdf(sheetIndex.summarySheetIndex)}
        >
          Summary Sheet
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SpreadSheetPrintPdfPophover
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable max-depth */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable max-params */
import GC from '@grapecity/spread-sheets';
import "@grapecity/spread-sheets-print"
import { isNil } from 'lodash';
import { cellNumberFormat, sheetContextMenuData, KEY_CODES, USDateFormat, sheetNames } from '../constants';
import { GCConfig } from '../config';

const selectionTypes = {
  LAST: 0,
  FIRST: 1,
  ALL: 2
};

const hAlignment = {
  left: GC.Spread.Sheets.HorizontalAlign.left,
  right: GC.Spread.Sheets.HorizontalAlign.right,
  center: GC.Spread.Sheets.HorizontalAlign.center
};

const customCommand = [
  { name: 'gc.spread.deleteRows', cmd: 'rowcolDeletion' },
  { name: 'gc.spread.insertRows', cmd: 'rowcolInsertion' },
  { name: 'gc.spread.insertColumns', cmd: 'rowcolInsertion' },
  { name: 'gc.spread.deleteColumns', cmd: 'rowcolDeletion' }
];

class WorkbookManager {
  constructor() {
    if (GCConfig.licenseKey) {
      GC.Spread.Sheets.LicenseKey = GCConfig.licenseKey;
    }

    this.onRowColDeletion = () => {};
    this.onRowColInsertion = () => {};
    this.setReverseSignToRowForOS = () => {};
    this.setChargeCodeCalculations = () => {};
    this.showHideMonthColumns = () => {};
  }

  init(workbookRef, formulaBoxRef, statusBarRef, cellRangeRef, sheetCount, options) {
    this.options = options;
    this.cellRange = cellRangeRef;
    this.workbookRef = workbookRef;
    // This needs to be created before workbook, or else workbook eats up its space
    this.statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(statusBarRef.current);

    // TODO: Widget creation should happen in constructor, other initialisations here
    this.workbook = new GC.Spread.Sheets.Workbook(workbookRef.current, {
      sheetCount: sheetCount || 1,
      grayAreaBackColor: '#FFFFFF',
      allowExtendPasteRange: true,
      newTabVisible: false,
      tabStripRatio: 0.4,
      tabEditable: false
    });
    this.formulaBox = new GC.Spread.Sheets.FormulaTextBox.FormulaTextBox(formulaBoxRef.current, {});
    this.formulaBoxValue = formulaBoxRef;
    this.formulaBox.workbook(this.workbook);
    this.statusBar.bind(this.workbook);
    this.sheetCount = sheetCount;
    this.bindWorkbookEvents();
  }

  bindWorkbookEvents() {
    this.workbook.bind(GC.Spread.Sheets.Events.SelectionChanged, () => {
      this.pasteFormat();
    });

    this.workbook.bind(GC.Spread.Sheets.Events.SelectionChanging, () => {
      const sheet = this.getSheet();
      const selection = sheet.getSelections().pop();
      if (selection) {
        const selectedRange = this.getSelectedRangeString(sheet, selection);
        if (this.cellRange && this.cellRange.current) {
          this.cellRange.current.innerHTML = selectedRange;
        }
      }
    });

    this.workbook.bind(GC.Spread.Sheets.Events.SheetTabClick, () => {
      this.setCurrentSheetContextMenu(true);
    });

    this.workbook.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, (_, args) => {
      const { newSheet } = args;
      this.options.setActiveSheet(newSheet.name());
    });

    this.setShortcutKeys();
    this.detectKeyPress();
    this.registerSetHAlignCommand();
    this.registerTextFormatCommand();
    this.registerFreezeCommand();
    this.registerSetFormatCommand();
    this.registerFilterCommand();
    this.registerSortCommand();
    this.registerSetColorCommand();
    this.registerFillSameDataToSelectedColumn();
    this.setSheetTabContextMenu();
    this.setCurrentSheetContextMenu();
  }

  get isActiveSheetEditable() {
    return this.workbook.sheets[this.workbook.getActiveSheetIndex()].options.isProtected;
  }

  setActiveSheetOnLoad() {
    const activeSheet = this.getSheet(0);
    this.options.setActiveSheet(activeSheet.name());
  }

  setCurrentSheetContextMenu(isWorkbookLoaded = false) {
    if (isWorkbookLoaded) {
      setTimeout(() => this.workbook.options.allowContextMenu = !this.isActiveSheetEditable, 0);
    } else {
      this.workbook.options.allowContextMenu = this.isActiveSheetEditable;
    }
  }

  setSheetColumnCount(sheetIndex, count) {
    const sheet = this.getSheet(sheetIndex);
    sheet.setColumnCount(count);
  }

  getSelections(sheetIndex, type = selectionTypes.ALL) {
    const selections = this.getSheet(sheetIndex).getSelections();
    if (selections.length > 0) {
      return type === selectionTypes.FIRST ? selections[0] :
        type === selectionTypes.LAST ? selections.pop() :
          selections;
    }

    return type === selectionTypes.ALL ? [] : null;
  }

  getRange(sheetIndex, selection) {
    const {
      row, col, rowCount, colCount
    } = selection;
    return this.getSheet(sheetIndex).getRange(row, col, rowCount, colCount);
  }

  * selectedCells() {
    for (const selection of this.getSelections()) {
      const {
        row, rowCount, col, colCount
      } = this.getRange(undefined, selection);
      for (let r = row; r < row + rowCount; r++) {
        for (let c = col; c < col + colCount; c++) {
          yield ({ row: r, col: c });
        }
      }
    }
  }

  registerSetHAlignCommand() {
    const runCommand = (options) => {
      const { activeSheetIndex, alignment } = options;
      this.suspend();
      for (const selection of this.getSelections(activeSheetIndex)) {
        const range = this.getRange(undefined, selection);
        range.hAlign(hAlignment[alignment]);
      }
      this.resume();
    };
    this.registerCustomCommand('setHAlignCommand', runCommand);
  }

  setHAlign(alignment) {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetEditable && this.workbook.commandManager().execute({
      cmd: 'setHAlignCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, alignment
    });
  }

  updateCellColor(sheet, row, colums, color) {
    this.suspend();
    for(let col=0; col<colums; col++) {
      sheet.getCell(row, col).foreColor(color);
    }
    this.resume();
  }

  registerTextFormatCommand() {
    const runCommand = (options) => {
      const { activeSheetIndex, formatType } = options;
      const ranges = this.getSelections(activeSheetIndex);
      for (const range of ranges) {
        this.updateTextFormat(range, formatType);
      }
    };
    this.registerCustomCommand('setTextFormat', runCommand);
  }

  updateTextFormat(range, formatType) {
    const {
      row, col, rowCount, colCount
    } = range;
    this.suspend();
    const style = new GC.Spread.Sheets.Style();
    for (let r = row; r < row + rowCount; r++) {
      for (let c = col; c < col + colCount; c++) {
        if (this.getSheet().getStyle(r, c) === null) {
          style.font = `${formatType} 11pt Calibri`;
          this.getSheet().setStyle(r, c, style);
          this.setUnderlineText(r, c, formatType);
        } else if (formatType !== 'underline') {
          const { font } = this.getSheet().getStyle(r, c);
          if (font === undefined) {
            this.getSheet().getCell(r, c).font(`${formatType} 11pt Calibri`);
          } else if (font.includes(formatType)) {
            this.getSheet().getCell(r, c).font(font.replace(formatType, ''));
          } else {
            this.getSheet().getCell(r, c).font(`${formatType} ${font}`);
          }
        } else {
          this.setUnderlineText(r, c, formatType);
        }
      }
    }
    this.resume();
  }

  setUnderlineText(row, col, formatType) {
    if (formatType === 'underline') {
      const { textDecoration } = this.getSheet().getStyle(row, col);
      const underlineType = textDecoration === undefined || textDecoration === 0 ? GC.Spread.Sheets.TextDecorationType.underline : GC.Spread.Sheets.TextDecorationType.none;
      this.getSheet().getCell(row, col).textDecoration(underlineType);
    }
  }

  setTextFormat(formatType) {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetEditable && this.workbook.commandManager().execute({
      cmd: 'setTextFormat', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, formatType
    });
  }

  pasteFormat() {
    if (this.copiedFormat) {
      this.suspend();
      for (const { row, col } of this.selectedCells()) {
        this.setCellStyle(undefined, row, col, this.copiedFormat.clone());
      }
      this.resume();
      this.clearCopyFormat();
    }
  }

  copyFormat() {
    if (!this.isActiveSheetEditable) {
      const { row, col } = this.getSelections(undefined, selectionTypes.FIRST);
      this.copiedFormat = this.getSheet().getStyle(row, col) || new GC.Spread.Sheets.Style();
      if (this.options.formatCopied) {
        this.copiedFormat && this.options.formatCopied();
      }
    }
  }

  clearCopyFormat() {
    this.copiedFormat = null;
    if (this.options.copiedFormatCleared) {
      this.options.copiedFormatCleared();
    }
  }

  getWorkbookData() {
    return this.workbook.toJSON({
      includeBindingSource: true,
      ignoreStyle: false,
      ignoreFormula: false,
      rowHeadersAsFrozenColumns: false,
      columnHeadersAsFrozenRows: false
    });
  }

  setWorkbookData(data) {
    this.workbook.fromJSON(data);
  }

  isInitialized() {
    return !!this.workbook;
  }

  destroy() {
    this.formulaBox.destroy();
    this.statusBar.dispose();
    this.workbook.destroy();
  }

  suspend() {
    this.workbook.suspendPaint();
    this.workbook.suspendCalcService(false);
    this.workbook.suspendEvent();
  }

  resume() {
    this.workbook.resumeCalcService(true);
    this.workbook.resumeEvent();
    this.workbook.resumePaint();
  }

  getWorkbookDataWithoutSheetProtection() {
    const workbookData = this.getWorkbookData();
    const { sheets } = workbookData;
    Reflect.ownKeys(sheets).forEach(item => sheets[item].isProtected = false);
    return workbookData;
  }

  setData(jsonData, hasAutoWidth) {
    this.workbook.fromJSON(jsonData);
    const { sheets } = this.workbook;
    this.suspend();
    !hasAutoWidth && sheets.forEach((_, i) => this.setColumnWidths(i, 200));
    this.setActiveSheetOnLoad();
    this.resume();
  }

  setSheetData(sheetIndex, data, colCount = 50, rowCount = 50, columnWidth = 180) {
    this.suspend();
    this.workbook.sheets[sheetIndex].setDataSource(data);
    this.setColumnWidths(sheetIndex, columnWidth);
    this.addAdditionalRowsColumns(sheetIndex, colCount, rowCount);
    this.resume();
  }

  setSheetFormulas(sheetIndex, formulas) {
    const sheet = this.getSheet(sheetIndex);
    formulas.forEach((formulaRow, row) => {
      Reflect.ownKeys(formulaRow).forEach((col) => {
        sheet.setFormula(row, +col, formulaRow[col]);
      });
    });
  }

  addAdditionalRowsColumns(sheetIndex, colCount = 50, rowCount = 50) {
    const sheet = this.workbook.sheets[sheetIndex];
    const rc = sheet.getRowCount();
    const cc = sheet.getColumnCount();
    sheet.addColumns(cc, colCount);
    sheet.addRows(rc, rowCount);
  }

  setColumnWidths(sheetIndex, width = 200) {
    this.suspend();
    const sheet = this.workbook.sheets[sheetIndex];
    const colCount = sheet.getColumnCount();
    for (let i = 0; i < colCount; i++) {
      sheet.setColumnWidth(i, width);
    }
    this.resume();
  }

  setSheetReadOnly(sheetIndex, readonly) {
    const sheet = this.workbook.sheets[sheetIndex];
    sheet.options.isProtected = readonly;
    sheet.options.protectionOptions.allowResizeRows = true;
    sheet.options.protectionOptions.allowResizeColumns = true;
    sheet.options.protectionOptions.allowFilter = true;
  }

  setSheetTabContextMenu() {
    const contextMenuData = this.workbook.contextMenu.menuData;
    contextMenuData.forEach((e) => {
      e.disable = !!sheetContextMenuData.includes(e.name);
    });
  }

  onToggleMonths() {
    this.showHideMonthColumns();
  }

  setReverseSignToRow() {
    this.setReverseSignToRowForOS();
  }

  getSelectedRangeString(sheet, range) {
    let selectionInfo = '';
    const {
      rowCount, colCount, row, col
    } = range;

    if (rowCount === 1 && colCount === 1) {
      selectionInfo = this.getCellPositionString(row + 1, col + 1);
    } else if (rowCount < 0 && colCount > 0) {
      selectionInfo = `${colCount}C`;
    } else if (colCount < 0 && rowCount > 0) {
      selectionInfo = `${rowCount}R`;
    } else if (rowCount < 0 && colCount < 0) {
      selectionInfo = `${sheet.getRowCount()}R x ${sheet.getColumnCount()}C`;
    } else {
      selectionInfo = `${rowCount}R x ${colCount}C`;
    }
    return selectionInfo;
  }

  getCellPositionString(row, column) {
    if (row < 1 || column < 1) {
      return '';
    }
    if (this.workbook.options.referenceStyle === GC.Spread.Sheets.ReferenceStyle.a1) {
      return `${this.toAlphabetColumnName(column)}${row}`;
    }
    return `R${row}C${column}`;
  }

  setShortcutKeys() {
    const { workbook } = this;
    const commands = GC.Spread.Sheets.Commands;
    // Replace existing command with custom command of context menu to detele row
    customCommand.forEach((e) => {
      const menuIndex = this.workbook.contextMenu.menuData.map(item => item.name).indexOf(e.name);
      this.workbook.contextMenu.menuData[menuIndex].command = e.cmd;
    });

    workbook.commandManager().register('rowSelection', () => {
      const sheet = this.getSheet();
      sheet.setSelection(sheet.getActiveRowIndex(), 0, 1, sheet.getColumnCount());
    });

    workbook.commandManager().register('colSelection', () => {
      const sheet = this.getSheet();
      sheet.setSelection(0, sheet.getActiveColumnIndex(), sheet.getRowCount(), 1);
    });

    workbook.commandManager().register('rowcolDeletion', {
      canUndo: true,
      execute: (spread, options, isUndo) => {
        const sheet = this.getSheet();
        const range = options.ranges === undefined ? options.selections[0] : options.ranges[0];
        const {
          row, rowCount, col, colCount
        } = range;
        if (isUndo) {
          commands.undoTransaction(spread, options);
          this.onRowColDeletion(isUndo, sheet, row, col, rowCount, colCount);
          return true;
        }
        if (row < 1) {
          commands.startTransaction(spread, options);
          sheet.deleteColumns(col, colCount);
          this.bindSheetHeader(sheet);
          this.onRowColDeletion(isUndo, sheet, row, col, rowCount, colCount);
          commands.endTransaction(spread, options);
        } else if (col < 1) {
          commands.startTransaction(spread, options);
          this.onRowColDeletion(isUndo, sheet, row, col, rowCount, colCount);
          this.deleteVisibleRows(sheet, row, rowCount)
          commands.endTransaction(spread, options);
        }
        return true;
      }
    });

    workbook.commandManager().register('rowcolInsertion', {
      canUndo: true,
      execute: (context, options, isUndo) => {
        const sheet = this.getSheet();
        const range = options.ranges === undefined ? options.selections[0] : options.ranges[0];
        const {
          row, rowCount, col, colCount
        } = range;
        if (isUndo) {
          commands.undoTransaction(context, options);
          return true;
        }
        if (row < 1) {
          commands.startTransaction(context, options);
          sheet.addColumns(col, colCount);
          this.bindSheetHeader(sheet);
          this.onRowColInsertion(sheet, row, col, rowCount, colCount);
          commands.endTransaction(context, options);
        } else if (col < 1) {
          commands.startTransaction(context, options);
          sheet.addRows(row, rowCount);
          this.onRowColInsertion(sheet, row, col, rowCount, colCount);
          commands.endTransaction(context, options);
        }
        return true;
      }
    });

    workbook.commandManager().setShortcutKey('colSelection', GC.Spread.Commands.Key.space, true, false, false, false);
    workbook.commandManager().setShortcutKey('rowSelection', GC.Spread.Commands.Key.enter, true, false, false, false);
  }

  deleteVisibleRows(sheet, startRow, rowCount) {
    this.suspend();
    const isFiltered = sheet.rowFilter()
    if (isFiltered && sheet.name() === sheetNames.targetSheetName) {
      const rowToDelete = []
      for (let row = startRow; row < startRow + rowCount; row++) {
        const visibleRow = sheet.getRowVisible(row);
        const serialNumber = sheet.getCell(row, 0).value()
        if (visibleRow) {
          rowToDelete.push(serialNumber);
        }
      }
      this.deleteFilterdRow(sheet, rowToDelete)
    } else {
      sheet.deleteRows(startRow, rowCount);
    }
    this.resume();
  }

  deleteFilterdRow(sheet, rowsToDelete) {
    this.suspend();
    rowsToDelete.forEach((serialNumber) => {
      const { foundRowIndex } = this.searchWithColumnValue(1, 0, serialNumber);
      sheet.deleteRows(foundRowIndex, 1)
    })
    this.resume();
  }

  detectKeyPress() {
    const {
      MINUS_KEY, MINUS_NUM_KEY, PLUS_KEY, PLUS_NUM_KEY, I_KEY, B_KEY, U_KEY, D_KEY, F4_KEY
    } = KEY_CODES;
    document.addEventListener('keyup', (e) => {
      const { keyCode } = e;
      const sheet = this.getSheet();
      if (sheet) {
        const selections = sheet.getSelections();
        if (selections.length > 0) {
          const {
            row, rowCount, col, colCount
          } = selections[0];
          const activeSheetIndex = this.getActiveSheetIndex();
          if (!this.isActiveSheetEditable) {
            if (e.ctrlKey && e.altKey && (keyCode === MINUS_KEY || keyCode === MINUS_NUM_KEY)) { // Row/Column Deletion
              this.workbook.commandManager().execute({
                cmd: 'rowcolDeletion',
                sheetName: this.workbook.getSheet(activeSheetIndex).name(),
                ranges: [new GC.Spread.Sheets.Range(row, col, rowCount, colCount)]
              });
            } else if (e.ctrlKey && e.altKey && (keyCode === PLUS_KEY || keyCode === PLUS_NUM_KEY)) { // Row/Column Insertion
              this.workbook.commandManager().execute({
                cmd: 'rowcolInsertion',
                sheetName: this.workbook.getSheet(activeSheetIndex).name(),
                ranges: [new GC.Spread.Sheets.Range(row, col, rowCount, colCount)]
              });
            } else if (e.ctrlKey && keyCode === I_KEY) { // Ctrl + I for italic text
              this.setTextFormat('italic');
            } else if (e.ctrlKey && keyCode === B_KEY) { // Ctrl + B for bold text
              this.setTextFormat('bold');
            } else if (e.ctrlKey && keyCode === U_KEY) { // Ctrl + U for underline text
              this.setTextFormat('underline');
            } else if (keyCode === F4_KEY) {
              this.absoluteFormulaReference(sheet, row, col);
            } else if (e.ctrlKey && e.altKey && keyCode === D_KEY) { // Ctrl + Alt + D to Fill first cell value to selected column
              const { rowCount, row, col } = selections[0];
              if (rowCount > 1) {
                const previousCellValue = this.getColumnValues(activeSheetIndex, row, rowCount, col);
                const previousCellStyle = this.getColumnStyles(activeSheetIndex, row, rowCount, col);
                this.workbook.commandManager().execute({
                  cmd: 'fillSameDataToColumnCommand', activeSheetIndex, selections: selections[0], isUndo: false, previousCellValue, previousCellStyle
                });
              }
            }
          }
          if (e.ctrlKey && keyCode == 70) {
            this.options.setShowFindModal();
          }
        }
      }
    });
  }

  absoluteFormulaReference(sheet, row, col) {
    const { hasFormula } = sheet.getFormulaInformation(row, col, GC.Spread.Sheets.CalcEngine.RangeReferenceRelative);
    const cursorPosition = this.formulaBox.caret();
    const formulaExpression = this.formulaBoxValue.current.value;
    const cellRefRegex = /[a-zA-Z]{1,2}(\d{1,3})/g;

    if (hasFormula && formulaExpression.charAt(0) === '=') {
      const absoluteCellRef = [...formulaExpression.matchAll(cellRefRegex)];
      const cellAddress = absoluteCellRef.filter((_, i) => absoluteCellRef[i].index <= cursorPosition).pop();

      if (cellRefRegex.test(cellAddress[0])) {
        const newCellRef = this.addDollarToString(cellAddress[0]);
        const formula = this.replaceMatchedString(formulaExpression, cellAddress.index, cellAddress[0].length, newCellRef);
        sheet.setFormula(row, col, formula);
        sheet.clearSelection();
      }
    }
  }

  addDollarToString(str) {
    const hasString = /[a-zA-Z]/g;
    let absoluteRef = '$';
    for (let i = 0; i < str.length; i++) {
      hasString.test(str[i]) ? absoluteRef += `${str[i]}$` : absoluteRef += str[i];
    }
    return absoluteRef;
  }

  replaceMatchedString(formulaExpression, cellAddress, stringLength, newCellRef) {
    const replaceFormulaExp = formulaExpression.split('');
    replaceFormulaExp.splice(cellAddress, stringLength);
    replaceFormulaExp.splice(cellAddress, 0, newCellRef);
    return replaceFormulaExp.join('');
  }

  getColumnIndexByName(sheetData, columnName) {
    const { columns } = sheetData;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].headerName === columnName) {
        return i;
      }
    }
  }

  getColumnCount(sheetData) {
    return Object.keys(sheetData.colHeaderData.dataTable[0]).length;
  }

  // TODO: This function is about (18/0.3) ~ 60 times faster than the next one.
  // See if we can make such improvement at any other place we are using SJS search
  // functionality.
  customSearchWithColumnValueInLowerCase(sheetIndex, columnIndex, cellValue, options = {}) {
    const sheet = this.getSheet(sheetIndex);
    const rowCount = sheet.getRowCount();
    for (let row = options.startRow || 0; row < rowCount; row++) {
      const val = sheet.getCell(row, columnIndex).value();
      if (val !== null) {
        if (val.toLowerCase() === cellValue.toLowerCase()) {
          return { foundRowIndex: row };
        }
      }
    }
    return { foundRowIndex: -1 };
  }

  customSearchWithColumnValue(sheetIndex, columnIndex, cellValue, options = {}) {
    const sheet = this.getSheet(sheetIndex);
    const rowCount = sheet.getRowCount();
    for (let row = options.startRow || 0; row < rowCount; row++) {
      const val = sheet.getCell(row, columnIndex).value();
      if (val === cellValue) {
        return { foundRowIndex: row };
      }
    }
    return { foundRowIndex: -1 };
  }

  customSearchWithColumnValues(sheetIndex, columnIndex, cellValue, options = {}) {
    const foundRows = [];
    const sheet = this.getSheet(sheetIndex);
    const rowCount = sheet.getRowCount();
    for (let row = options.startRow || 0; row < rowCount; row++) {
      const val = sheet.getCell(row, columnIndex).value();
      if (val === cellValue) {
        foundRows.push(row);
      }
    }
    return foundRows;
  }

  searchWithColumnValue(sheetIndex, columnIndex, cellValue) {
    const searchCondition = new GC.Spread.Sheets.Search.SearchCondition();
    searchCondition.searchString = cellValue;
    searchCondition.startSheetIndex = sheetIndex;
    searchCondition.endSheetIndex = sheetIndex;
    searchCondition.columnStart = columnIndex;
    searchCondition.columnEnd = columnIndex;
    searchCondition.searchOrder = GC.Spread.Sheets.Search.SearchOrder.nOrder;
    searchCondition.searchTarget = GC.Spread.Sheets.Search.SearchFoundFlags.cellText;
    return this.workbook.search(searchCondition);
  }

  customSearchColumnWithValue(sheetIndex, rowIndex, cellValue, options = {}) {
    const sheet = this.getSheet(sheetIndex);
    const cellCount = sheet.getColumnCount();
    for (let col = options.startCol || 0; col < cellCount; col++) {
      const val = sheet.getCell(rowIndex, col).value();
      if (val === cellValue) {
        return { foundColumnIndex: col };
      }
    }
    return { foundColumnIndex: -1 };
  }

  customSearchRowWithValue(
    sheetIndex, columnIndex, cellValue, options = {},
  ) {
    const sheet = this.getSheet(sheetIndex);
    const rowCount = sheet.getRowCount();
    for (let row = options.startRow || 0; row < rowCount; row++) {
      const val = sheet.getCell(row, columnIndex).value();
      if (val === cellValue) {
        return { foundRowIndex: row };
      }
    }
    return { foundRowIndex: -1 };
  }


  customSearchColumnWithIgnoreCaseValue(sheetIndex, rowIndex, cellValue, options = {}) {
    const sheet = this.getSheet(sheetIndex);
    const cellCount = sheet.getColumnCount();
    if (cellValue !== null) {
      for (let col = options.startCol || 0; col < cellCount; col++) {
        const val = sheet.getCell(rowIndex, col).text();
        const updatedCellValue = !isNil(val) ? val.toLowerCase() : val;
        if (updatedCellValue === cellValue.toLowerCase()) {
          return { foundColumnIndex: col };
        }
      }
    }

    return { foundColumnIndex: -1 };
  }

  getAlphabetRowIndexByValue(sheetIndex, rowCount, sourceColumnIndex, targetColumnIndex, cellValue) {
    this.suspend();
    const sheet = this.getSheet(sheetIndex);
    const foundRowIndex = [];
    for (let row = 1; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, sourceColumnIndex).value();
      if (columnValue === cellValue) {
        foundRowIndex.push(`${this.toAlphabetColumnName(targetColumnIndex + 1)}${row + 1}`);
      }
    }
    this.resume();
    return foundRowIndex;
  }

  getMatchedCellValues(sheetIndex, rowCount, sourceColumnIndex, targetColumnIndex, cellValue, isExactDataTypeMatch = true) {
    this.suspend();
    const sheet = this.getSheet(sheetIndex);
    const foundRowIndex = [];
    for (let row = 1; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, sourceColumnIndex).value();
      const validatedCellValue = isExactDataTypeMatch ? columnValue === cellValue : columnValue == cellValue;
      if (validatedCellValue) {
        foundRowIndex.push(sheet.getCell(row, targetColumnIndex).value());
      }
    }
    this.resume();
    return foundRowIndex;
  }

  searchColumnWithValue(sheetIndex, RowIndex, cellValue) {
    const searchCondition = new GC.Spread.Sheets.Search.SearchCondition();
    searchCondition.searchString = cellValue;
    searchCondition.startSheetIndex = sheetIndex;
    searchCondition.endSheetIndex = sheetIndex;
    searchCondition.rowStart = RowIndex;
    searchCondition.rowEnd = RowIndex;
    searchCondition.searchOrder = GC.Spread.Sheets.Search.SearchOrder.nOrder;
    searchCondition.searchTarget = GC.Spread.Sheets.Search.SearchFoundFlags.cellText;
    return this.workbook.search(searchCondition);
  }

  searchKeywordInSpreadView(query) {
    this.suspend();
    const sheet = this.getSheet();
    const searchCondition = this.getSearchCondition(query);
    let searchResult = null;
    const selection = sheet.getSelections();
    if (selection.length > 1) {
      searchCondition.searchFlags |= GC.Spread.Sheets.Search.SearchFlags.blockRange;
    } else if (selection.length == 1) {
      const { row, col, rowCount, colCount } = selection[0];
      const spanInfo = this.getSpanInfo(sheet, row, col);
      if (rowCount != spanInfo.rowSpan && colCount != spanInfo.colSpan) {
        searchCondition.searchFlags |= GC.Spread.Sheets.Search.SearchFlags.blockRange;
      }
    }
    searchResult = this.getResultSearchinSheetEnd(searchCondition);
    if (searchResult == null || searchResult.searchFoundFlag == GC.Spread.Sheets.Search.SearchFoundFlags.none) {
      searchResult = this.getResultSearchinSheetBefore(searchCondition);
    }

    if (searchResult != null && searchResult.searchFoundFlag != GC.Spread.Sheets.Search.SearchFoundFlags.none) {
      this.setActiveSheetByIndex(searchResult.foundSheetIndex);
      const sheet = this.getSheet(searchResult.foundSheetIndex);
      sheet.setActiveCell(searchResult.foundRowIndex, searchResult.foundColumnIndex);
      if ((searchCondition.searchFlags & GC.Spread.Sheets.Search.SearchFlags.blockRange) == 0) {
        sheet.setActiveCell(searchResult.foundRowIndex, searchResult.foundColumnIndex, 1, 1);
      }
      //scrolling
      if (searchResult.foundRowIndex < sheet.getViewportTopRow(1)
        || searchResult.foundRowIndex > sheet.getViewportBottomRow(1)
        || searchResult.foundColumnIndex < sheet.getViewportLeftColumn(1)
        || searchResult.foundColumnIndex > sheet.getViewportRightColumn(1)
      ) {
        sheet.showCell(searchResult.foundRowIndex,
          searchResult.foundColumnIndex,
          GC.Spread.Sheets.VerticalPosition.center,
          GC.Spread.Sheets.HorizontalPosition.center);
      }
    } else {
      this.options.setWorkbookToast({ message: 'Sorry, we cannot find the data you are searching for.', type: 'danger' });
    }
    this.resume();
  }

  getSpanInfo(sheet, row, col) {
    const span = sheet.getSpans(new GC.Spread.Sheets.Range(row, col, 1, 1));
    if (span.length > 0) {
      const { rowCount, colCount } = span[0];
      return { rowSpan: rowCount, colSpan: colCount };
    } else {
      return { rowSpan: 1, colSpan: 1 };
    }
  }

  getResultSearchinSheetEnd(searchCondition) {
    const sheet = this.getSheet();
    searchCondition.startSheetIndex = this.getActiveSheetIndex();
    searchCondition.endSheetIndex = this.getActiveSheetIndex();

    if (searchCondition.searchOrder == GC.Spread.Sheets.Search.SearchOrder.zOrder) {
      searchCondition.findBeginRow = sheet.getActiveRowIndex();
      searchCondition.findBeginColumn = sheet.getActiveColumnIndex() + 1;
    } else if (searchCondition.searchOrder == GC.Spread.Sheets.Search.SearchOrder.nOrder) {
      searchCondition.findBeginRow = sheet.getActiveRowIndex() + 1;
      searchCondition.findBeginColumn = sheet.getActiveColumnIndex();
    }

    if ((searchCondition.searchFlags & GC.Spread.Sheets.Search.SearchFlags.blockRange) > 0) {
      const { row, col, rowCount, colCount } = sheet.getSelections()[0];
      searchCondition.rowStart = row;
      searchCondition.columnStart = col;
      searchCondition.rowEnd = row + rowCount - 1;
      searchCondition.columnEnd = col + colCount - 1;
    }
    return this.workbook.search(searchCondition);
  }

  getResultSearchinSheetBefore(searchCondition) {
    const sheet = this.getSheet();
    searchCondition.startSheetIndex = this.getActiveSheetIndex();
    searchCondition.endSheetIndex = this.getActiveSheetIndex();
    if ((searchCondition.searchFlags & GC.Spread.Sheets.Search.SearchFlags.blockRange) > 0) {
      const { row, col, rowCount, colCount } = sheet.getSelections()[0];
      searchCondition.rowStart = row;
      searchCondition.columnStart = col;
      searchCondition.findBeginRow = row;
      searchCondition.findBeginColumn = col;
      searchCondition.rowEnd = row + rowCount - 1;
      searchCondition.columnEnd = col + colCount - 1;
    } else {
      searchCondition.rowStart = -1;
      searchCondition.columnStart = -1;
      searchCondition.findBeginRow = -1;
      searchCondition.findBeginColumn = -1;
      searchCondition.rowEnd = sheet.getActiveRowIndex();
      searchCondition.columnEnd = sheet.getActiveColumnIndex();
    }
    return this.workbook.search(searchCondition);
  }

  getSearchCondition(query) {
    const searchCondition = new GC.Spread.Sheets.Search.SearchCondition();
    const { keyword } = query;
    searchCondition.searchString = keyword;
    searchCondition.startSheetIndex = this.getActiveSheetIndex();
    searchCondition.endSheetIndex = this.getActiveSheetIndex();
    searchCondition.searchOrder = GC.Spread.Sheets.Search.SearchOrder.zOrder;
    searchCondition.searchTarget = GC.Spread.Sheets.Search.SearchFoundFlags.cellText;
    searchCondition.searchFlags |= GC.Spread.Sheets.Search.SearchFlags.ignoreCase;
    return searchCondition;
  }

  getColumnValues(sheetIndex, row, rowCount, columnIndex) {
    const cellValues = [];
    for (let i = row; i < rowCount + row; i++) {
      cellValues.push(this.getCellValue(sheetIndex, i, columnIndex));
    }
    return cellValues;
  }

  getColumnStyles(sheetIndex, row, rowCount, columnIndex) {
    const cellStyles = [];
    for (let i = row; i < rowCount + row; i++) {
      cellStyles.push(this.getCellStyle(sheetIndex, i, columnIndex));
    }
    return cellStyles;
  }

  updateRowBackColorForColumnValue(sheetIndex, columnIndex, cellValue, backColor, col = 0, colCount = -1) {
    const searchResult = this.searchWithColumnValue(sheetIndex, columnIndex, cellValue);
    if (searchResult.foundString !== null) {
      const { foundRowIndex } = searchResult;
      this.setCellRangeAttr(sheetIndex, foundRowIndex, 0, 1, colCount, 'backColor', backColor);
    }
  }

  setCellValue(sheetIndex, row, col, value) {
    this.getSheet(sheetIndex).setValue(row, col, value);
  }

  setCellText(sheetIndex, row, col, value) {
    this.getSheet(sheetIndex).setText(row, col, value);
  }

  getCellValue(sheetIndex, row, col) {
    return this.getSheet(sheetIndex).getCell(row, col).value();
  }

  getSheetRowCount(sheetIndex) {
    return this.getSheet(sheetIndex).getRowCount();
  }

  getSheetColumnCount(sheetIndex) {
    return this.getSheet(sheetIndex).getColumnCount();
  }


  setReadonly(readonly) {
    this.workbook.sheets.forEach((_, i) => this.setSheetReadOnly(i, readonly));
  }

  setActiveSheet(activeSheetName) {
    this.workbook.setActiveSheet(activeSheetName);
  }

  setActiveSheetByIndex(sheetIndex) {
    this.workbook.setActiveSheetIndex(sheetIndex);
  }

  getSheetNames() {
    return this.workbook.sheets.map(sheet => sheet.name());
  }

  getSheets() {
    return this.workbook.sheets;
  }

  getSheetCount() {
    return this.workbook.sheets.length;
  }

  getActiveSheetIndex() {
    return this.workbook.getActiveSheetIndex();
  }

  getSheet(sheetIndex) {
    if (sheetIndex !== undefined) {
      return this.workbook.sheets[sheetIndex];
    }
    return this.workbook.getActiveSheet();
  }

  bindSheetColumns(sheetIndex, columns, defaultColumnSize) {
    const sheet = this.getSheet(sheetIndex);
    const sheetColumns = columns.map(column => {
      return  { resizable: true, size: defaultColumnSize, ...column }
    })
    sheet.bindColumns(sheetColumns);
  }

  bindSheetHeader(sheet) {
    const count = sheet.getColumnCount();
    this.suspend();
    for (let i = 0; i < count; i++) {
      sheet.setText(0, i - 1, this.toAlphabetColumnName(i), GC.Spread.Sheets.SheetArea.colHeader);
    }
    this.resume();
  }

  setSheetHeaderNames(sheetIndex, columns) {
    this.addRows(sheetIndex, 0, 1);
    this.setRowData(sheetIndex, 0, columns.map(c => c.headerName));
  }

  autoFitColumns(sheetIndex) {
    this.suspend();
    const sheet = this.getSheet(sheetIndex);
    const colCount = sheet.getColumnCount();
    for (let i = 0; i < colCount; i++) {
      // ignore collapsed columns
      const cell = sheet.getCell(-1, i);
      const originalWidth = cell.width();
      if (originalWidth > 2) {
        sheet.autoFitColumn(i);
        cell.width(cell.width() + 20);
        cell.resizable(true);
      } else {
        cell.resizable(false);
      }
    }

    this.resume();
  }

  setSheetName(sheetIndex, name) {
    this.getSheet(sheetIndex).name(name);
  }

  addRows(sheetIndex, position, count) {
    this.getSheet(sheetIndex).addRows(position, count);
  }

  setRowData(sheetIndex, row, data) {
    this.getSheet(sheetIndex).setArray(row, 0, [data]);
  }

  addStyle(sheetIndex, styleName, {
    backColor, foreColor, borderBottom, borderLeft, borderRight, hAlign = false, vAlign = false, font
  }) {
    const style = new GC.Spread.Sheets.Style();
    style.name = styleName;
    style.backColor = backColor;
    style.foreColor = foreColor;

    if (borderBottom) {
      style.borderBottom = new GC.Spread.Sheets.LineBorder(borderBottom.color, GC.Spread.Sheets.LineStyle[borderBottom.lineStyle]);
    }

    if (borderLeft) {
      style.borderLeft = new GC.Spread.Sheets.LineBorder(borderLeft.color, GC.Spread.Sheets.LineStyle[borderLeft.lineStyle]);
    }

    if (borderRight) {
      style.borderRight = new GC.Spread.Sheets.LineBorder(borderRight.color, GC.Spread.Sheets.LineStyle[borderRight.lineStyle]);
    }

    if (hAlign) {
      style.hAlign = GC.Spread.Sheets.HorizontalAlign.right;
    }

    if (vAlign) {
      style.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    }

    if (font) {
      style.font = font;
    }

    this.getSheet(sheetIndex).addNamedStyle(style);
  }

  setCellStyle(sheetIndex, row, col, style) {
    this.getSheet(sheetIndex).setStyle(row, col, style);
  }

  getCellStyle(sheetIndex, row, col) {
    return this.getSheet(sheetIndex).getStyle(row, col);
  }

  setCellRangeAttr(sheetIndex, row, col, rowCount, colCount, attr, value) {
    this.getSheet(sheetIndex).getRange(row, col, rowCount, colCount)[attr](value);
  }

  setCellType(sheetIndex, row, col, cellType) {
    this.getSheet(sheetIndex).getCell(row, col).cellType(cellType);
  }

  freezeRow(sheetIndex, row) {
    this.getSheet(sheetIndex).frozenRowCount(row);
  }

  freezeColumn(sheetIndex, col) {
    this.getSheet(sheetIndex).frozenColumnCount(col);
  }

  resetRowFreeze(sheetIndex) {
    this.getSheet(sheetIndex).frozenRowCount(1);
  }

  resetColFreeze(sheetIndex) {
    this.getSheet(sheetIndex).frozenColumnCount(1);
  }

  undo() {
    !this.isActiveSheetEditable && this.workbook.undoManager().undo();
  }

  redo() {
    !this.isActiveSheetEditable && this.workbook.undoManager().redo();
  }

  registerFreezeCommand() {
    const runCommand = (options) => {
      const { activeSheetIndex } = options;
      const sheet = this.getSheet(activeSheetIndex);
      const {
        col, colCount, row, rowCount
      } = sheet.getSelections()[0];
      if (rowCount === 1 && colCount === 1) {
        this.freezeRow(activeSheetIndex, row);
        this.freezeColumn(activeSheetIndex, col);
      }
      if (col === -1) {
        this.freezeRow(activeSheetIndex, row);
      }
      if (row === -1) {
        this.freezeColumn(activeSheetIndex, col);
      }
    };

    const undoCommand = (options) => {
      const { activeSheetIndex } = options;
      this.resetColFreeze(activeSheetIndex);
      this.resetRowFreeze(activeSheetIndex);
    };

    this.registerCustomCommand('freezeCommand', runCommand, undoCommand);
  }

  freeze(sheetIndex) {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetEditable && this.workbook.commandManager().execute({ cmd: 'freezeCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex });
  }

  unfreeze(sheetIndex) {
    !this.isActiveSheetEditable && this.resetColFreeze(sheetIndex);
    !this.isActiveSheetEditable && this.resetRowFreeze(sheetIndex);
  }

  registerSetFormatCommand() {
    const rangeSelections = []
    const runCommand = (options) => {
      const { activeSheetIndex, format, rowCount } = options;
      const sheet = this.getSheet(activeSheetIndex);
      const selections = sheet.getSelections();
      const alignment = (format === cellNumberFormat || format === 0 || format === USDateFormat) ? hAlignment.right : hAlignment.left;
      options.isUndo = false;
      options.selections = selections[0];
      this.suspend();
      selections.forEach((selection) => {
        rangeSelections.push(selection);
        const selectionRowCount = rowCount || selection.rowCount;
        const selectionRow = selection.row === -1 ? 1 : selection.row;
        const range = sheet.getRange(selectionRow, selection.col, selectionRowCount, selection.colCount);
        range.formatter(format).hAlign(alignment);
      });
      this.resume(); 
    };

    const undoCommand = (options) => {
      setTimeout(() => {
        options.isUndo = true;
        options.selections = rangeSelections.length ? rangeSelections[rangeSelections.unshift() - 1] : rangeSelections;
        rangeSelections.pop();
      }, 0)
     
    };

    this.registerCustomCommand('setFormatCommand', runCommand, undoCommand);
  }

  setFormat(format, rowCount = null) {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetEditable && this.workbook.commandManager().execute({
      cmd: 'setFormatCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, format, rowCount
    });
  }


  setCellFormat(sheetIndex, row, col, format) {
    this.suspend();
    const sheet = this.getSheet(sheetIndex);
    sheet.getCell(row, col).formatter(format);
    this.resume();
  }

  setCellFormula(sheet, row, col, formulaExpression) {
    this.suspend();
    sheet.setFormula(row, col, formulaExpression);
    this.resume();
  }

  registerFilterCommand() {
    const runCommand = (options) => {
      const { activeSheetIndex } = options;
      const sheet = this.getSheet(activeSheetIndex);
      if (sheet.rowFilter()) {
        sheet.rowFilter(null);
      } else {
        const selections = this.getSheet().getSelections();
        if (selections.length > 0) {
          const selection = selections[0];
          sheet.rowFilter(
            new GC.Spread.Sheets.Filter.HideRowFilter(
              new GC.Spread.Sheets.Range(0, selection.col, sheet.getRowCount(), selection.colCount),
            ),
          );
        }
      }
    };
    this.registerCustomCommand('setFilterCommand', runCommand);
  }

  setFilter() {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetEditable &&
    this.workbook.commandManager().execute({ cmd: 'setFilterCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex });
  }

  registerSortCommand() {
    const runCommand = (options) => {
      const { ascending, activeSheetIndex } = options;
      const sheet = this.getSheet(activeSheetIndex);
      const selections = sheet.getSelections();
      if (selections.length > 0) {
        const { col } = selections[0];
        sheet.sortRange(1, 0, sheet.getRowCount() - 1, sheet.getColumnCount(), true, [{
          index: col,
          ascending
        }]);
      }
    };
    this.registerCustomCommand('sortCommand', runCommand);
  }

  sort(sheetIndex, ascending) {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetEditable && this.workbook.commandManager().execute({
      cmd: 'sortCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, ascending
    });
  }

  registerSetColorCommand() {
    const runCommand = (options) => {
      const {
        selections, color, foreColor, activeSheetIndex
      } = options;
      const sheet = this.getSheet(activeSheetIndex);
      const isFiltered = sheet.rowFilter() == null ? null : Object.keys(sheet.rowFilter().LX);
      this.suspend();
      for (const {
        row, col, rowCount, colCount
      } of selections) {
        if (isFiltered !== null && isFiltered.length > 0) {
          for (let i = row; i < row + rowCount; i++) {
            if (i > -1 && sheet.getRowVisible(i)) {
              const cellRange = sheet.getRange(i, col, 1, colCount, GC.Spread.Sheets.SheetArea.viewport);
              foreColor ? cellRange.foreColor(color) : cellRange.backColor(color);
            }
          }
        } else {
          const cellRange = sheet.getRange(row, col, rowCount, colCount);
          foreColor ? cellRange.foreColor(color) : cellRange.backColor(color);
        }
      }
      this.resume();
    };
    this.registerCustomCommand('setColorCommand', runCommand);
  }

  setColor(sheetIndex, foreColor, color) {
    const activeSheetIndex = this.getActiveSheetIndex();
    const selections = this.getSheet(activeSheetIndex).getSelections();
    !this.isActiveSheetEditable && this.workbook.commandManager().execute({
      cmd: 'setColorCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, selections, foreColor, color
    });
  }

  registerFillSameDataToSelectedColumn() {
    const runCommand = (options) => {
      const {
        activeSheetIndex, isUndo, previousCellValue, previousCellStyle, selections: { row, rowCount, col }
      } = options;
      const cellValue = this.getCellValue(activeSheetIndex, row, col);
      const cellStyle = this.getCellStyle(activeSheetIndex, row, col);
      this.suspend();
      for (let i = 0; i < rowCount; i++) {
        if (isUndo) {
          this.setCellValue(activeSheetIndex, row + i, col, previousCellValue[i]);
          this.setCellStyle(activeSheetIndex, row + i, col, previousCellStyle[i]);
        } else {
          this.setCellValue(activeSheetIndex, row + i, col, cellValue);
          this.setCellStyle(activeSheetIndex, row + i, col, cellStyle);
        }
      }
      this.resume();
    };

    const undoCommand = (options) => {
      options.isUndo = true;
      runCommand(options);
    };

    this.registerCustomCommand('fillSameDataToColumnCommand', runCommand, undoCommand);
  }

  registerCustomCommand(commandName, runCommand, undoCommand = undefined) {
    const commands = GC.Spread.Sheets.Commands;
    this.workbook.commandManager().register(commandName, {
      canUndo: true,
      canRedo: true,
      execute: (context, options, isUndo) => {
        if (isUndo) {
          if (undoCommand !== undefined) {
            undoCommand(options);
          }
          commands.undoTransaction(context, options);
          return true;
        }
        commands.startTransaction(context, options);
        runCommand(options);
        commands.endTransaction(context, options);

        return true;
      }
    }, null, false, false, false, false);
  }

  toAlphabetColumnName(num) {
    let str = '';
    for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
      str = String.fromCharCode(Number((num % b) / a) + 65) + str;
    }
    return str;
  }

  fetchValueFromColumns(sheetIndex, columnNames, outputKeys, showRowIndex = true) {
    this.suspend();
    const result = [];
    const rowCount = this.workbook.getSheet(sheetIndex).getRowCount();
    for (let column = 0; column < columnNames.length; column++) {
      const columnIndex = this.customSearchColumnWithValue(sheetIndex, 0, columnNames[column]).foundColumnIndex;
      if (columnIndex !== -1) {
        for (let row = 1; row < rowCount; row++) {
          const columnValues = this.getCellValue(sheetIndex, row, columnIndex);
          if (!isNil(columnValues)) {
            const rowIndex = showRowIndex ? { [`${outputKeys[column]}_ROW_INDEX`]: row } : {};
            result[row] = { ...result[row], ...{ [outputKeys[column]]: columnValues, ...rowIndex } };
          }
        }
      }
    }

    this.resume();
    return result.filter(output => output);
  }

  setColumnsFont(sheet, row, columns = [], font) {
    this.suspend();
    columns.forEach(column => {
      sheet.getCell(row, column).font(font);
    })
    this.resume();
  }

  resizeCell(sheet, cellInfo) {
    this.suspend()
    sheet.getRange(cellInfo.row, cellInfo.col, cellInfo.rowCount, cellInfo.colCount).wordWrap(true);
    for (let row = cellInfo.row; row < cellInfo.rowCount; row++) {
      sheet.autoFitRow(row);
    }
    this.resume();
  }

  onPrintPdf(sheetIndex = 2) {
    this.suspend();  
    const sheet = this.getSheet(sheetIndex) 
    const printInfo = sheet.printInfo();
    printInfo.showBorder(false);
    printInfo.showGridLine(false); 
    printInfo.fitPagesTall(1);
    printInfo.fitPagesWide(2);
    printInfo.pageOrder(GC.Spread.Sheets.Print.PrintPageOrder.center);
    printInfo.paperSize(new GC.Spread.Sheets.Print.PaperSize(GC.Spread.Sheets.Print.PaperKind.a4));
    printInfo.showRowHeader(GC.Spread.Sheets.Print.PrintVisibilityType.hide);
    printInfo.showColumnHeader(GC.Spread.Sheets.Print.PrintVisibilityType.hide);
    printInfo.orientation(GC.Spread.Sheets.Print.PrintPageOrientation.landscape);
    this.workbook.print(sheetIndex)
    this.resume();
  }
}

export default WorkbookManager;

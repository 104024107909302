import React, { useCallback, useState, useMemo } from 'react';


import { connect } from 'react-redux';
import { withServices } from 'reaf';
import List from '../../core/List/List';
import CompanyTemplatePopups from '../CompanyTemplatePopups/CompanyTemplatePopups';
import UploadProgress from '../UploadProgress/UploadProgress';
import CompanyTemplateListItem from './CompanyTemplateListItem';
import SwitchButton from '../../core/SwitchButton/SwitchButton';
import { PAGINATION_DEFAULT } from '../../../constants';
import { getGlobalTemplateMappingsAsListSelector } from '../../../store/selectors';

const GlobalTemplateMappings = ({
  globalTemplateMappings,
  templateService,
}) => {
  const [showUploadTemplateFormPopup, setShowUploadTemplateFormPopup] = useState(false);
  const [showUpdateTemplate, setShowUpdateTemplate] = useState(false);
  const [template, setTemplate] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDownloadCsv, setIsDownloadCsv] = useState(true);

  const hidePopups = useCallback(
    () => {
      setShowUploadTemplateFormPopup(false);
      setShowDeleteConfirm(false);
      setShowUpdateTemplate(false);
    },
    [],
  );

  const onUpdateTemplate = useCallback((template) => {
    setTemplate(template);
    setShowUpdateTemplate(true);
  }, []);

  const filterListItem = useCallback(
    (companyTemplate, searchText) =>
      companyTemplate.templateName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
    []
  );

  const onDownloadTemplate = useCallback((template) => {
    templateService.downloadGlobalTemplate(template, isDownloadCsv);
  }, [isDownloadCsv]);

  const onDownloadDictionary = useCallback((template) => {
    templateService.downloadDictionary(template, isDownloadCsv);
  }, [isDownloadCsv]);

  const onDownloadCategorySequence = useCallback((template) => {
    templateService.downloadCategorySequence(template, isDownloadCsv);
  }, [isDownloadCsv]);

  const switchCsvJsonButton = useMemo(() => (
    <SwitchButton
      isActive={isDownloadCsv}
      onChange={setIsDownloadCsv}
      offLabel="JSON"
      onLabel="CSV"
    />
  ), [isDownloadCsv]);

  const selectedTemplate = useMemo(() => {
    return globalTemplateMappings.find(t => t.uuid === template?.uuid);
  }, [globalTemplateMappings, template]);

  return (
    <>
      <List
        addButtonText="Add Template"
        title="Templates"
        onAdd={() => setShowUploadTemplateFormPopup(true)}
        listItems={globalTemplateMappings}
        filterListItem={filterListItem}
        utilityButtons={[switchCsvJsonButton]}
        getKey={template => template.uuid}
        recordsPerPage={PAGINATION_DEFAULT.pageSize}
        ListItem={({ item: companyTemplate }) => (
          <CompanyTemplateListItem
            template={companyTemplate}
            isDownloadCsv={isDownloadCsv}
            isGlobal
            onDeleteTemplate={() => {}}
            onDownloadDictionary={onDownloadDictionary}
            onDownloadTemplate={onDownloadTemplate}
            onDownloadCategorySequence={onDownloadCategorySequence}
            onUpdateTemplate={onUpdateTemplate} />
        )}
      />
      <UploadProgress />
      <CompanyTemplatePopups
        onHide={hidePopups}
        onDeleteConfirm={() => {

        }}
        showUploadTemplate={showUploadTemplateFormPopup}
        isGlobal
        template={selectedTemplate}
        showDeleteConfirm={showDeleteConfirm}
        showUpdateTemplate={showUpdateTemplate}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  globalTemplateMappings: getGlobalTemplateMappingsAsListSelector(state)
});

export default connect(mapStateToProps)(withServices(
  'templateService'
)(GlobalTemplateMappings));

import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { pick } from 'lodash';
import List from '../../core/List/List';
import {
  currentCompanySelector,
  currentTemplateSelector,
  templateModelAsListSelector,
  companyTemplatesList,
  dynamicAssetTypesAsObjSelector
} from '../../../store/selectors';
import TemplateModelListItem from './TemplateModelListItem';
import UploadProgress from '../UploadProgress/UploadProgress';
import TemplateModelPopups from '../TemplateModelPopups/TemplateModelPopups';

function TemplateModelList({
  templateModels, companyUUID, templateUUID,
  companyTemplates, templateModelService,
  dynamicAssetTypes
}) {
  const [showUploadModelFormPopup, setShowUploadModelFormPopup] = useState(false);
  const [showModalInformationPopup, setShowModalInformationPopup] = useState(false);
  const [showDeleteModalPopUp, setDeleteModalPopUp] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [currentModelToShowReport, setCurrentModelToShowReport] = useState(null);
  const [showUpdateModelToShowReport, setShowUpdateModelToShowReport] = useState(false);

  let { assetTypes } = companyTemplates[templateUUID];
  assetTypes = assetTypes ? pick(dynamicAssetTypes, assetTypes) : dynamicAssetTypes;

  const filterListItem = (templateModel, searchText) => (
    templateModel.modelName.toLowerCase()
  ).indexOf(searchText.toLowerCase()) !== -1;

  const hidePopups = useCallback(
    () => {
      setShowUploadModelFormPopup(false);
      setDeleteModalPopUp(false);
      setShowUpdateModel(false);
      setShowModalInformationPopup(false);
      setShowUpdateModelToShowReport(false);
    },
    [],
  );


  const onDeleteTemplateModel = useCallback(async () => {
    hidePopups();
    try {
      await templateModelService.deleteTemplateModel(companyUUID, templateUUID, currentModel.uuid);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [currentModel, companyUUID, templateUUID, hidePopups]);

  const onDownloadModel = useCallback(async (model) => {
    try {
      await templateModelService.downloadTemplateModel(companyUUID, templateUUID, model);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [companyUUID, templateUUID]);

  const onToggleConsolidatedModel = useCallback(async (model, canDownloadConsolidatedModel) => {
    await templateModelService.updateConsoleteModelAccess(
      model,
      companyUUID, 
      templateUUID,
      model.uuid,
      model.assetType,
      canDownloadConsolidatedModel
    )
  }, [companyUUID, templateUUID]);

  const updateShowReportsForModel = useCallback(async (model) => {
    setCurrentModel(model);
    if(!model.showReportsForThisModel) {
      const currentModelToShowReport = templateModels.find(templateModel => templateModel.showReportsForThisModel);
      setCurrentModelToShowReport(currentModelToShowReport);
    }
    setShowUpdateModelToShowReport(true);
  }, [templateModels]);

  const onConfirmUpdateShowReportsForModel = useCallback(async() => {
    hidePopups();
    try {
      await templateModelService.updateShowReportsForModel(companyUUID, templateUUID, currentModel)
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [companyUUID, templateUUID, currentModel])

  const openDeleteTemplateModelModal = useCallback(
    async(model) => {
      await templateModelService.loadTemplateModelsList(companyUUID, templateUUID);
      setCurrentModel(model);
      setDeleteModalPopUp(true);
    }, []
  );

  const openEditTemplateModelModal = useCallback(
    (model) => {
      setCurrentModel(model);
      setShowUploadModelFormPopup(true);
    }, []
  );

  const handleModelInformation = useCallback(
    (model) => {
      setCurrentModel(model);
      setShowModalInformationPopup(true);
    }, []
  );

  const onAdd = useCallback(() => {
    setCurrentModel({ modelName: '', assetType: Reflect.ownKeys(assetTypes)[0], file: null });
    setShowUploadModelFormPopup(true);
  }, []);

  const handleUpdateModel = useCallback(async(model) => {
    setCurrentModel(model);
    setShowUpdateModel(true);
  }, []);

  return (
    <>
      <List
        onAdd={onAdd}
        listItems={templateModels}
        title="Template Models"
        addButtonText="Add Models"
        filterListItem={filterListItem}
        isGlobal={companyTemplates[templateUUID].isGlobal}
        getKey={model => model.uuid}
        ListItem={({ item: model }) => (
          <TemplateModelListItem
            model={model}
            template={companyTemplates[templateUUID]}
            onDownloadModel={onDownloadModel}
            onEditModel={handleUpdateModel}
            onModelInformation={handleModelInformation}
            templateUUID={templateUUID}
            companyUUID={companyUUID}
            onDeleteTemplateModel={openDeleteTemplateModelModal}
            onToggleConsolidatedModel={onToggleConsolidatedModel}
            updateShowReportsForModel={updateShowReportsForModel}
            onEditTemplateModel={openEditTemplateModelModal} />
        )}
      />
      <UploadProgress />
      <TemplateModelPopups
        onHide={hidePopups}
        model={currentModel}
        onDeleteConfirm={onDeleteTemplateModel}
        showDeleteConfirm={showDeleteModalPopUp}
        showUploadModel={showUploadModelFormPopup}
        showUpdateModel={showUpdateModel}
        showModalInformation={showModalInformationPopup}
        companyUUID={companyUUID}
        templateUUID={templateUUID}
        onConfirmUpdateShowReportsForModel={onConfirmUpdateShowReportsForModel}
        showUpdateModelToShowReport={showUpdateModelToShowReport}
        currentModelToShowReport={currentModelToShowReport}
      />
    </>
  );
}

const mapStateToProps = state => ({
  templateModels: templateModelAsListSelector(state),
  companyUUID: currentCompanySelector(state),
  templateUUID: currentTemplateSelector(state),
  companyTemplates: companyTemplatesList(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state),
});

export default connect(mapStateToProps)(withServices('templateModelService')(TemplateModelList));

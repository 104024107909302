import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import List from '../../core/List/List';
import {
  currentTemplateSelector,
  getGlobalTemplateMappingsAsListSelector,
  templateModelAsListSelector,
  dynamicAssetTypesAsObjSelector
} from '../../../store/selectors';
import TemplateModelListItem from './TemplateModelListItem';
import UploadProgress from '../UploadProgress/UploadProgress';
import TemplateModelPopups from '../TemplateModelPopups/TemplateModelPopups';

function GlobalModels({
  templateModels,
  templateUUID,
  companyTemplates,
  dynamicAssetTypes,
  templateModelService
}) {
  const [showUploadModelFormPopup, setShowUploadModelFormPopup] = useState(false);
  const [showDeleteModalPopUp, setDeleteModalPopUp] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [showModalInformationPopup, setShowModalInformationPopup] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const assetTypes = companyTemplates.find(template => template.uuid == templateUUID).assetTypes;
  const filterListItem = (templateModel, searchText) => (
    templateModel.modelName.toLowerCase()
  ).indexOf(searchText.toLowerCase()) !== -1;

  const onAdd = useCallback(() => {
    setCurrentModel({ modelName: '', assetType: assetTypes ? Reflect.ownKeys(assetTypes)[0]: null, file: null });
    setShowUploadModelFormPopup(true);
  }, []);

  const hidePopups = useCallback(
    () => {
      setShowUploadModelFormPopup(false);
      setDeleteModalPopUp(false);
      setShowUpdateModel(false);
      setShowModalInformationPopup(false)
    },
    [],
  );

  const onDeleteTemplateModel = useCallback(async () => {
    hidePopups();
    try {
      await templateModelService.deleteTemplateModel(null, templateUUID, currentModel.uuid);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [currentModel, templateUUID, hidePopups]);

  const openDeleteTemplateModelModal = useCallback(
    async(model) => {
      setCurrentModel(model);
      setDeleteModalPopUp(true);
    }, []
  );

  const openEditTemplateModelModal = useCallback(
    (model) => {
      setCurrentModel(model);
      setShowUploadModelFormPopup(true);
    }, []
  );

  const handleUpdateModel = useCallback(async(model) => {
    setCurrentModel(model);
    setShowUpdateModel(true);
  }, []);

  const handleModelInformation = useCallback(
    (model) => {
      setCurrentModel(model);
      setShowModalInformationPopup(true);
    }, []
  );

  const onDownloadModel = useCallback(async (model) => {
    try {
      await templateModelService.downloadTemplateModel(null, templateUUID, model);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [templateUUID]);

  const updateGlobalModelConsolidatedModelAccess = useCallback(async (model, isActiveToggle) => {
    await templateModelService.updateGlobalModelConsolidatedModelAccess(templateUUID, model, isActiveToggle);
  }, [templateUUID]);

  const updateShowReportsForModel = useCallback(async (model) => {
    await templateModelService.updateShowReportsForModel(null, templateUUID, model)
  }, [templateUUID]);

  return (
    <>
      <List
        onAdd={onAdd}
        listItems={templateModels}
        title="Template Models"
        addButtonText="Add Models"
        filterListItem={filterListItem}
        getKey={model => model.uuid}
        ListItem={({ item: model }) => (
          <TemplateModelListItem
            model={model}
            template={companyTemplates.find((template)=> template.uuid === templateUUID)}
            onEditModel={handleUpdateModel}
            onModelInformation={handleModelInformation}
            onDeleteTemplateModel={openDeleteTemplateModelModal}
            onEditTemplateModel={openEditTemplateModelModal}
            onDownloadModel={onDownloadModel}
            updateShowReportsForModel={updateShowReportsForModel}
            templateUUID={templateUUID}
            updateGlobalModelConsolidatedModelAccess={updateGlobalModelConsolidatedModelAccess}
          />
        )}
      />
      <UploadProgress />
      <TemplateModelPopups
        onHide={hidePopups}
        model={currentModel}
        onDeleteConfirm={onDeleteTemplateModel}
        showDeleteConfirm={showDeleteModalPopUp}
        showUploadModel={showUploadModelFormPopup}
        showUpdateModel={showUpdateModel}
        showModalInformation={showModalInformationPopup}
        companyUUID={null}
        templateUUID={templateUUID}
      />
    </>
  );
}

const mapStateToProps = state => ({
  templateModels: templateModelAsListSelector(state),
  templateUUID: currentTemplateSelector(state),
  companyTemplates: getGlobalTemplateMappingsAsListSelector(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state),
});

export default connect(mapStateToProps)(withServices('templateModelService')(GlobalModels));

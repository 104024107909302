import React, { useState, useCallback } from 'react';
import { withServices } from 'reaf';
import {
  Row, Col, ListGroup, Button, Dropdown
} from 'react-bootstrap';
import {
  faInfo as infoIcon, faTrash, faPencilAlt, faCheck, faTimesCircle, faEllipsisV
} from '@fortawesome/free-solid-svg-icons';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import IconButton from '../../core/Button/IconButton';
import SwitchButton from '../../core/SwitchButton/SwitchButton';
import ReportTemplatesModal from '../ReportTemplatesModal/ReportTemplatesModal';

function ToggleButton({ isActive, title, onChange }) {
  const [isActiveToggle, setIsActiveToggle] = React.useState(isActive)

  const onToggle = React.useCallback(() => {
    setIsActiveToggle(!isActiveToggle);
    onChange(!isActiveToggle)
  }, [isActiveToggle, onChange])

  return (
    <div className="d-flex flex-column">
      <label className="text-sm">{title}</label>
      <div>
        <SwitchButton
          isActive={isActiveToggle}
          onChange={onToggle}
        />
      </div>
    </div>
  );
} 

function TemplateModelListItem({
  model, template, onDeleteTemplateModel, onEditTemplateModel, 
  onDownloadModel, companyUUID, templateUUID, onEditModel, onModelInformation,
  onToggleConsolidatedModel, templateModelService, updateShowReportsForModel
}) {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const onDeleteReportTemplate = useCallback( async (model, reportTemplate) => {
    try {
      await templateModelService.deleteReportTemplate(companyUUID, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... It's intentional to show the error to the admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [companyUUID, templateUUID])


  const onDownloadReportTemplate = useCallback(async (model, reportTemplate) => {
    try {
      await templateModelService.downloadReportTemplate(companyUUID, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [companyUUID, templateUUID]);

  const assetType = model.assetType &&
      (<AssetTypeBadge key={model.assetType} AssetType={model.assetType} />);

  return (
    <ListGroup.Item>
      <Row className="align-items-center w-100">
        <Col xs={2} className="d-flex align-items-center">
          {model.modelName}
          {assetType}
        </Col>

        <Col xs={3} className="d-flex align-items-center">
          <ToggleButton 
            className="text-sm"
            isActive={model.canDownloadConsolidatedModel} 
            title="Allow Download consolidated model"
            onChange={(isActive) => onToggleConsolidatedModel(model, isActive)}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center">
          {!template.isGlobal && (
            <>
              {model.modelFilePath && (
                <Button
                  className="btn btn-default btn-xs"
                  variant="secondary"
                  onClick={() => onDownloadModel(model)}
                >
                  Download Model
                </Button>
              )}
              {model.hasReportTemplateFile && (
                <Button
                  className="btn btn-default btn-xs ml-2"
                  variant="primary"
                  onClick={toggleModal}
                >
                  Manage Reports
                </Button>
              )}
            </>
          )}
        </Col>

        <Col xs={3} className="d-flex align-items-center">
          <label className="mb-0 mr-2">Show Report for this model</label>
          <IconButton
            className={model.showReportsForThisModel ? 'btn-outline-success' : 'btn-outline-danger'}
            onClick={() => updateShowReportsForModel(model)}
            icon={model.showReportsForThisModel ? faCheck : faTimesCircle}
            title={model.showReportsForThisModel ? 'Show Report' : 'Hide Report'}
          />
        </Col>

        <Col xs={1} className="d-flex align-items-center justify-content-center">
          {!template.isGlobal && (
            <Dropdown drop="left">
              <Dropdown.Toggle
                as={IconButton}
                bsPrefix="custom-toggle"
                variant="outline-secondary"
                className="smallIconButton actionButton"
                icon={faEllipsisV}
              />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onDeleteTemplateModel(model)}>
                  Delete
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onEditModel(model)}>
                  Update
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onModelInformation(model)}>
                  Info
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Col>
      </Row>
      <ReportTemplatesModal
        show={showModal}
        onHide={toggleModal}
        model={model}
        onDownloadReportTemplate={onDownloadReportTemplate}
        onDeleteReportTemplate={onDeleteReportTemplate}
      />
    </ListGroup.Item>
  );
}

export default (withServices('templateModelService')(TemplateModelListItem));

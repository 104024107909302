/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-reflect */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/* eslint-disable eqeqeq */
/* eslint-disable max-params */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-depth */
import GC from '@grapecity/spread-sheets';
import {
  compact, difference,
  divide, filter, find,
  findIndex, groupBy, get,
  invertBy, isArray, isEmpty, isNil,
  isNumber, mean,
  last, map,
  max, mergeWith, min, multiply, pick, round, some, sortBy,
  sum, sumBy,
  uniq, uniqBy, isUndefined, isFinite, values, trim, toLower,
  startCase,
  toUpper
} from 'lodash';
import * as moment from 'moment';
import messages from '../../locales/en-US';
import {
  ALLOWED_RETAIL_CC_CATEGORY, cellNumberFormat, CLIK_OS_CATEGORY_MAPPING, DocumentFileTypes,
  excludedRentRollMFColValue, OS_FIXED_COLUMNS, PROPERTY_TYPES, RED_BACKGROUND_BASE64_IMG, RENT_ROLL_MF_COLUMN_NAME,
  RETAIL_CHARGE_CODE_FORMULA, sheetIndex, rrSheetIndex, sheetNames, summaryConstant, summarySheetHeaders,
  columnDataTypeFormatters, tenantTypeColumnCalc, USDateFormat, USCurrencyFormat, validDateFormats,
  spreadColorConfig, spreadCellFormatter, summarySheetHeadSequence, TEMPLATE_TAGS, affordableRnotationOptions, 
  unitMixNonRevenueUnits, leaseTypeList, unitStatusSummaryHeader, floorPlanWithUnitDetailsHeader, 
  floorPlanWithRentDetailsHeader, recentLeaseAverageRentkey, sheetHeaderObj, floorPlanWithRentDetailsGroupedCol, 
  floorPlanWithUnitDetailsGroupedCol, chargeCodeSummaryFooterConfig, unitStatusSummaryFooterConfig, 
  floorPlanWithUnitDetailsFooterConfig, floorPlanWithRentDetailsFooterConfig, rrSummaryColorConfig, 
  summarySheetTableTitles, summarySheetFooterOperations, occupiedRentAvg, ExtractionJobStatus, PROTECTED_OS_COLUMNS, 
  aiParserSheet, aiParserIgnoredColumnsForFormatter, aiParserNumberColumns
} from '../constants';
import {
  setChargeCodeConfig, setFloorPlan, setFloorPlanSummary, setLeaseConfig,
  setNetRentalIncome, setRetailLeaseTypeConfig, setRetailOccupancy,
  setRetailTenantTypeConfig, setRowReverseMapping, setUnitStatus, setUseMonthlyData, setUnitMixSummary
} from '../store/currentDocument';
import {
  chargeCodeConfigSelector, currentDocumentFloorPlanSelector, unitMixSummaryConfigSelector,
  currentDocumentUnitStatusSelector, currentProjectTemplateMappingsSelector, currentTemplateTagSelector,
  excludedCategoriesSelector, occupancyMappingSelector, occupancySelector, projectDictionaryExistsSelector,
  retailLeaseTypeConfigSelector, retailLeaseTypeDropDownSelector, staticChargeCodeMappingSelector,
  staticChargeCodeSelector, tenantNameUnitStatusSelector, workbookDataSelector, currentDocumentTaggingInfoSelector,
  mfRentRollConfigSelector, currentProjectSelector, floorPlanSummarySelector, numberOfOSMonthsToHideSelector, currentDocumentSelector, dynamicAssetTypesListSelector, dynamicAssetTypesAsObjSelector,
  cashflowPeriodIdentifierSelector
} from '../store/selectors';
import {
  arrayContainsArray, capitalizeFirstLetter, checkArrayIncludesValue, compareTwoStrings,
  convertDataListDropDown, findValidKey, getKeyByValue, getPercentage, getTotalByKey, isBracketExists,
  isNotEmptyOrNaNValue, isNumberBetweenRange, isOSBaseType, isStringType, checkValidNumber,
  isValidNumber, getAverage, getFilteredArrayCount, getSumbyGroup, validateChargeCode, isValidDate,
  getAiParserDataRows, getAiParserDataColumns, isValidAmountNumber,
  isOSFullBaseType,
  getQuarterDateFromString,
  getCellFromatter,
} from '../utils/utils';
import { handleWorkbookEvents } from './DocExtractionWorkbookManagerEvents';

const { RENT_ROLL } = DocumentFileTypes;
const { targetSheetName, sourceSheetName, workingSheetName, summarySheetName } = sheetNames;
const {
  sourceSheetIndex, 
  targetSheetIndex, 
  workingSheetIndex, 
  summarySheetIndex, 
  customSummarySheetIndex, 
  summarySheetIndexWithCustomSummary
} = sheetIndex;
const { invalidCellColor } = spreadColorConfig;
const { headerBgColor, textColor, tableHeaderBgColor, propertyInfoBgColor, black, footerBgColor, borderColor } = rrSummaryColorConfig;
const { chargeCodeSummayTitle, unitStatusSummaryTitle, floorPlanWithRentDetailsTitle } = summarySheetTableTitles;

const rowColors = {
  'Income': '#daeeff',
  'Expense': '#fff1f1',
  'Capital Expense': '#feffb6',
  'matchedRow': '#dddddd',
  'NOI': '#d9f8fd',
  'Noi': '#d9f8fd'
};

const statusMap = {
  "Occupied": "Occupied",
  "Vacant": "Vacant",
};

const tentativeColumnName = 'Tentative Status';
const columnMap = {
  "Status": tentativeColumnName,
  "MarketRent": "Market Rent",
  "MonthlyRent": "Monthly Rent",
  "LeaseEndDate": "End Date",
};

const cutoffPercentage = 30;

const errorMessageMap = {
  MONTHLY_RENT_0: {
    message: 'Monthly rent is 0 for occupied status',
    color: '#E5CB9F'
  },
  MONTHLY_RENT_NON_0: {
    message: 'Monthly rent is not 0 for vacant status',
    color: '#99C4C8'
  },
  CUTTOFF_ERROR: {
    message: `Deviation for monthly rent and market rent by ${cutoffPercentage}%`,
    color: '#EEE4AB',
  },
  ASOFDATE_AFTER_ENDDATE: {
    message: 'As of date is after lease end date',
    color: '#E2DEA9'
  }
};

const rentDiffPercentage = (marketRent, monthlyRent) => Math.abs(monthlyRent - marketRent);

class DocExtractionWorkbookManager {
  constructor(manager, isAllowedCustomSummarySheet) {
    this.workbookManager = manager;
    this.isAllowedCustomSummarySheet = isAllowedCustomSummarySheet
    this.addStyles();
    this.registerCopyCommand();
    this.registerPasteCommand();
    this.registerUpdateCellBgColorCommand();
    this.registerDragFillCommand();
    this.registerReverseRowSignCommand();
    this.workbookManager.onRowColDeletion = this.onRowColDeletion.bind(this);
    this.workbookManager.onRowColInsertion = this.onRowColInsertion.bind(this);
    this.workbookManager.setReverseSignToRowForOS = this.setReverseSignToRowForOS.bind(this);
    this.options = {
      projectTemplateHeadCategories: {},
      projectHeadCategorySequence: null,
      projectSummarySheetTotalConfig: null,
      template: {},
      docExtractionStatus: ExtractionJobStatus.SUCCESSFUL.key,
      readOnlyAccess: false
    };
    this.columnHeadersList = [];
    this.allColumnList = null;
    this.fixedOSColumns = [];
    this.initailChargeCodes = {};
    this.copiedSourceColumnRange = null;
    this.copiedSourceSelectedRange = null;
    this.copiedCategoryValues = null;
    this.copiedSubCategoryValue = null;
    this.copiedHeaderColumnValues = [];
    this.rowReverseMapping = {};
    handleWorkbookEvents(this);
  }

  isWorkbookHasOSTypeDoc() {
    return isOSBaseType(this.workbookManager.options.docType);
  }

  isWorkbookHasOSFullTypeDoc() {
    return isOSFullBaseType(this.workbookManager.options.docType);
  }

  isWorkbookHasRentRollTypeDoc() {
    return this.workbookManager.options.docType === RENT_ROLL.key;
  }

  getWorkingSheetIndex() {
    return this.isWorkbookHasOSTypeDoc() ? workingSheetIndex : rrSheetIndex.workingSheetIndex;
  }

  validateDocumentAssetType(baseClass) {
    const dynamicAssetTypes = dynamicAssetTypesAsObjSelector(this.options.store.getState())
    const allAssetTypes = {
      ...PROPERTY_TYPES,
      ...dynamicAssetTypes
    }
    return allAssetTypes[this.workbookManager.options.assetType].baseClass === baseClass &&
      !allAssetTypes[this.workbookManager.options.assetType].isRentRollRestricted;
  }

  isMultifamilyOrHealthcareDocument() {
    const dynamicAssetTypes = dynamicAssetTypesAsObjSelector(this.options.store.getState())
    const allAssetTypes = {
      ...PROPERTY_TYPES,
      ...dynamicAssetTypes
    }

    const baseClasses = [PROPERTY_TYPES.MULTIFAMILY.baseClass, PROPERTY_TYPES.HEALTHCARE.baseClass];

    return baseClasses.includes(allAssetTypes[this.workbookManager.options.assetType].baseClass) &&
      !allAssetTypes[this.workbookManager.options.assetType].isRentRollRestricted;
  }

  fixedOperatingStatementColumns() {
    OS_FIXED_COLUMNS.forEach((item) => {
      const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, item);
      this.fixedOSColumns.push({ col: item, colIndex: foundColumnIndex });
    });
  }

  get operatingStatementSummarySheetIndex() {
    return this.isAllowedCustomSummarySheet ? summarySheetIndexWithCustomSummary : summarySheetIndex;
  }

  registerDragFillCommand() {
    const runCommand = (options) => {
      const { undoState } = options;
      const {
        row,
        rowCount,
        selectedCol: col,
        previousCategoryValues,
        previousHeadValues,
        categoryCol,
        isCopyCategory,
        isCopySubCategory,
        subCategoryCol,
        copyCategoryValue,
        copySubCategoryValue,
        previousSubCategoryValues
      } = undoState;
      const { targetSheetColCount, sourceSheetColCount } = options.colCount;
      const { headIndex } = options;
      if (headIndex !== col) {
        return;
      }

      this.workbookManager.suspend();
      if (options.isUndo) {
        Array(rowCount).fill(0).forEach((_, index) => {
          const rowIndex = row + index;
          this.onTargetHeadValueChange(rowIndex, previousHeadValues[index], sourceSheetColCount, targetSheetColCount);
          this.workbookManager.setCellValue(targetSheetIndex, rowIndex, categoryCol, previousCategoryValues[index]);
          // eslint-disable-next-line max-len
          this.workbookManager.setCellValue(targetSheetIndex, rowIndex, subCategoryCol, previousSubCategoryValues[index]);
        });
      } else {
        Array(rowCount).fill(0).forEach((_, index) => {
          const rowIndex = row + index;
          const colValue = this.workbookManager.getCellValue(targetSheetIndex, rowIndex, col);
          this.onTargetHeadValueChange(rowIndex, colValue, sourceSheetColCount, targetSheetColCount);
          if (isCopyCategory) {
            this.workbookManager.setCellValue(targetSheetIndex, rowIndex, categoryCol, copyCategoryValue);
          }
          if (isCopySubCategory) {
            this.workbookManager.setCellValue(targetSheetIndex, rowIndex, subCategoryCol, copySubCategoryValue);
          }
        });
      }
      this.workbookManager.resume();
    };

    const undoCommand = (options) => {
      options.isUndo = true;
      runCommand(options);
      setTimeout(() => this.workbookManager.undo(), 0);
    };

    this.workbookManager.registerCustomCommand('onDragFill', runCommand, undoCommand);
  }

  registerCopyCommand() {
    const runCommand = (options) => {
      const { activeSheetIndex } = options;
      if (activeSheetIndex === sourceSheetIndex) {
        const copiedRange = this.workbookManager.workbook.getActiveSheet().getSelections()[0];
        if (copiedRange.row < 0) {
          this.copiedSourceColumnRange = copiedRange;
          this.copiedSourceSelectedRange = null;
        } else {
          this.copiedSourceSelectedRange = copiedRange;
          this.copiedSourceColumnRange = null;
        }
      } else {
        this.copiedSourceColumnRange = null;
        this.copiedSourceSelectedRange = null;
      }
    };
    this.workbookManager.registerCustomCommand('onCopy', runCommand);
  }

  registerPasteCommand() {
    const runCommand = (options) => {
      const { activeSheet, headerNames } = options;
      if (this.workbookManager.getActiveSheetIndex() === activeSheet && this.copiedSourceColumnRange) {
        const pastedRange = this.workbookManager.getSelections(activeSheet)[0];
        if (pastedRange.row < 0) {
          const targetColumnRange = pastedRange;
          const sourceColumnRange = this.copiedSourceColumnRange;
          this.copySourceToTarget(activeSheet, headerNames, sourceColumnRange, targetColumnRange);
          this.workbookManager.bindSheetHeader(this.workbookManager.getSheet(activeSheet));
        }
      } else if (this.workbookManager.getActiveSheetIndex() === activeSheet && !this.copiedSourceColumnRange) {
        const pastedRange = this.workbookManager.getSelections(activeSheet)[0];
        if (pastedRange.row < 0) {
          const sheet = this.workbookManager.getSheet(activeSheet);
          const workbookData = this.workbookManager.getWorkbookData();
          const { columns } = workbookData.sheets[targetSheetName];
          setTimeout(() => this.copyFromSameSheet(sheet, columns, pastedRange), 0)
        }
      }
      this.copiedSourceColumnRange = null;
    };

    const undoCommand = (options) => {
      options.isUndo = true;
      this.validateAndSetColumnValue(options.args);
    }

    this.workbookManager.registerCustomCommand('onPaste', runCommand, undoCommand);
  }

  validateAndSetColumnValue(options) {
    this.workbookManager.suspend();
    const rowCount = this.getTargetSheetRowCount();
    const workbookData = this.workbookManager.getWorkbookData();
    const { columns } = workbookData.sheets[targetSheetName];
    const { sheet, cellRange } = options;
    const { row, col, colCount } = cellRange;
    const foundColumnDataType = [];
    if (sheet && row < 0) {
      for (let i = 0; i < rowCount; i++) {
        for (let j = 0; j < colCount; j++) {
          const columnValue = sheet.getCell(i, col + j).value();
          if (i === 0) {
            const foundColumnConfig = columns.length ? columns.filter(col => col.headerName === columnValue) : [];
            foundColumnDataType.push(...foundColumnConfig);
          } else if (!!foundColumnDataType.length && foundColumnDataType[j]?.hasOwnProperty('dataType')) {
            const { dataType } = foundColumnDataType[j];
            let isValidDataType = this.validateColumnDataType(dataType, columnValue);
            if (!isValidDataType) {
              sheet.getCell(i, col + j).value(null);
            }
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  onRangePaste(activeSheet, options) {
    if (this.workbookManager.getActiveSheetIndex() === activeSheet && this.copiedSourceSelectedRange) {
      const pastedRange = this.workbookManager.getSelections(activeSheet)[0];
      const targetColumn = pastedRange.col;
      const targetRow = pastedRange.row;
      this.copyRangeSourceToTarget(activeSheet, this.copiedSourceSelectedRange, targetRow, targetColumn);
    } else if (this.workbookManager.getActiveSheetIndex() === activeSheet && !this.copiedSourceSelectedRange) {
      this.onRangePasteToTargetSheet(activeSheet, options);
    }
    this.copiedSourceSelectedRange = null;
  }

  onRangePasteToTargetSheet(activeSheetIndex, options) {
    if (this.workbookManager.getActiveSheetIndex() === activeSheetIndex) {
      this.workbookManager.suspend();
      const sheet = this.workbookManager.getSheet(activeSheetIndex);
      const workbookData = this.workbookManager.getWorkbookData();
      const { columns } = workbookData.sheets[targetSheetName];
      const { cellRange } = options;
      this.copyFromSameSheet(sheet, columns, { ...cellRange })
      this.workbookManager.resume();
    }
  }

  copyFromSameSheet(sheet, columns, options) {
    this.workbookManager.suspend();
    const { row, col, rowCount, colCount } = options;
    const cellValidationStates = [];
    const startRow = row < 0 ? 1 : 0;
    if (row < 0) {
      this.validateHeaderIndentity(sheet, options);
    }
    for (let i = startRow; i < rowCount; i++) {
      for (let j = 0; j < colCount; j++) {
        const rowIndex = row < 0 ? i : row + i;
        const cellValue = sheet.getCell(rowIndex, col + j).value();
        const cellState = this.onCellValueChanged({ sheet, columns, row: rowIndex, col: col + j });
        !isNil(cellValue) && cellValidationStates.push(cellState);
      }
    }
    if (cellValidationStates.includes(false)) { // checking if any dataType validation failed in collection
      this.options.setWorkbookToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
    }
    this.workbookManager.resume();
  }

  protectExtractedSheetHeader(args) {
    this.workbookManager.suspend();
    const {
      row, col, sheet, sheetName
    } = args;
    if (sheetName === targetSheetName) {
      if (row === 0) {
        const cellValue = sheet.getCell(row, col).value();
        if (this.isWorkbookHasOSTypeDoc()) {
          if (cellValue && PROTECTED_OS_COLUMNS.includes(cellValue)) {
            args.cancel = true;
          }
        } else if (this.isWorkbookHasRentRollTypeDoc) {
          const protectedHeaders = this.allColumnList.filter(column => column && column.type === 'STATIC').map(col => col.headerName) || [];
          if (cellValue && protectedHeaders.includes(cellValue)) { 
            args.cancel = true;
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  protectOSDropdowns(args) {
    this.workbookManager.suspend();
    const {
      row, col, sheet, sheetName
    } = args;
    if (sheetName === targetSheetName) {
      if (this.isWorkbookHasOSTypeDoc()) {
        const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
        const categoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
        const subCategoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
        
        if (col === headIndex || col === categoryIndex || col === subCategoryIndex) {
          const style = sheet.getStyle(row, col);
          if (style && style.hasOwnProperty('dropDowns')) {
            args.cancel = true;
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  protectRRDropdowns(args) {
    this.workbookManager.suspend();
    const {
      row, col, sheet, sheetName
    } = args;
    if (sheetName === targetSheetName) {
      if (this.isWorkbookHasRentRollTypeDoc()) {
        const statusColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Status').foundColumnIndex;
        const affordableColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Affordable').foundColumnIndex;
        const renovationColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Renovation').foundColumnIndex;
        const leaseTypeColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Lease Type').foundColumnIndex;
        const tenantTypeColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Tenant Type').foundColumnIndex;

        if (
            col === statusColIndex || 
            col === affordableColIndex || 
            col === renovationColIndex || 
            col === leaseTypeColIndex ||
            col === tenantTypeColIndex
           ) {
          const style = sheet.getStyle(row, col);
          if (style && style.hasOwnProperty('dropDowns')) {
            args.cancel = true;
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  validateHeaderIndentity(sheet, options) {
    this.workbookManager.suspend();
    const headerConfig = this.copiedHeaderColumnValues;
    if (headerConfig.length) {
      const { col, colCount } = options;
      for (let i = 0; i < colCount; i++) {
        const columnIndex = col + i;
        const defaultCellStyle = this.workbookManager.getCellStyle(targetSheetIndex, 0, 1);
        const cellStyle = headerConfig[i] ? headerConfig[i].columnStyle : defaultCellStyle;
        sheet.setStyle(0, columnIndex, cellStyle);
      }
    }
    this.workbookManager.resume();
  }

  validateColumnDataType(dataType, value) {
    let isValidDataType = true;
    const date = moment(value, validDateFormats, true);
    const cellValue = (dataType === 'date' && date.isValid()) ? moment(new Date(value)).format("MM/DD/YYYY") : this.validateCurrencyColumn(value, dataType);
    if (!!dataType && (!isNil(cellValue) && (!!cellValue || checkValidNumber(cellValue)))) {
      if (dataType === 'number' || dataType === 'currency') {
        isValidDataType = checkValidNumber(cellValue);
      } else if (dataType === 'string') {
        isValidDataType = true; // Allowing any thpe value in string data type 
      } else if (dataType === 'date') {
        if (!checkValidNumber(cellValue) && !Number.isFinite(cellValue)) {
          isValidDataType = (typeof date === 'object' || typeof date === 'string') && date.isValid();
        } else {
          isValidDataType = false;
        }
      }
    }
    return isValidDataType;
  }

  setExistingCellFormat(sheet, row, col, dataType, cellValue = null) {
    this.workbookManager.suspend();
    if (dataType === 'currency') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : USCurrencyFormat;
      sheet.setFormatter(row, col, validFormat)
    } else if (dataType === 'date') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : 0;
      sheet.setFormatter(row, col, validFormat)
    } else if (dataType === 'number') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : 0;
      sheet.setFormatter(row, col, validFormat)
    } else if (dataType === 'string') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : '';
      sheet.setFormatter(row, col, validFormat)
    }
    this.workbookManager.resume();
  }

  copySourceToTarget(activeSheet, headerNames, sourceColumnRange, targetColumnRange) {
    const { col: sourceColumn, rowCount: sourceRowCount, colCount: sourceColumnCount } = sourceColumnRange;
    const { col: targetColumn } = targetColumnRange;
    const sheet = this.workbookManager.getSheet(activeSheet);
    const workbookData = this.workbookManager.getWorkbookData();
    const { columns } = workbookData.sheets[targetSheetName];
    this.workbookManager.suspend();
    let lastIndex = 0;
    let columnDataTypes = [];
    let columnDataTypeRecord = [];
    let foundColumnDataType = [];
    for (let i = 0; i < sourceRowCount; i++) {
      for (let j = 0; j < sourceColumnCount; j++) {
        i && this.workbookManager.setCellValue(activeSheet, i, targetColumn + j, '');
        if (!!headerNames[j] && i === 0) {
          const { columnValue } = headerNames[j];
          const foundColumnConfig = columns.length ? columns.filter(col => col.headerName === columnValue) : [];
          foundColumnDataType.push(...foundColumnConfig);
          if (!!foundColumnDataType.length && foundColumnDataType[j]?.hasOwnProperty('dataType')) {
            columnDataTypes.push({ column: targetColumn + j, dataType: foundColumnDataType[j].dataType })
          }
          this.workbookManager.setCellStyle(activeSheet, i, targetColumn + j, headerNames[j].columnStyle);
        } else {
          const cellStyle = new GC.Spread.Sheets.Style();
          const defaultCellStyle = sheet.getStyle(i, 1);
          if (defaultCellStyle) {
            cellStyle.backColor = defaultCellStyle.backColor || undefined
            cellStyle.borderBottom = defaultCellStyle.borderBottom || undefined;
            cellStyle.borderLeft = defaultCellStyle.borderLeft || undefined;
            cellStyle.borderRight = defaultCellStyle.borderRight || undefined;
            cellStyle.borderTop = defaultCellStyle.borderTop || undefined;
          }
          this.workbookManager.setCellStyle(activeSheet, i, targetColumn + j, cellStyle);
        }
        const sourceId = this.workbookManager.getCellValue(sourceSheetIndex, i, 0);
        const { foundRowIndex } = this.workbookManager.customSearchWithColumnValue(targetSheetIndex, 0, sourceId, { startRow: lastIndex });
        if (foundRowIndex !== -1 && i > 0) {
          lastIndex = foundRowIndex;
          const columnValue = this.workbookManager.getCellValue(sourceSheetIndex, i, sourceColumn + j);
          if (!!columnDataTypes.length && columnDataTypes[j].dataType === 'date') {
            this.validateAndSetDateToCell(sheet, foundRowIndex, targetColumn + j, columnValue)
          } else {
            const colDataType = columnDataTypes.length ? columnDataTypes[j].dataType : 'string';
            this.setValue(activeSheet, foundRowIndex, targetColumn + j, columnValue, colDataType);
            !!columnDataTypes.length && this.setExistingCellFormat(sheet, foundRowIndex, targetColumn + j, columnDataTypes[j].dataType);
          }

          if (!!columnDataTypes.length && columnValue) {
            let isValidDataType = this.validateColumnDataType(columnDataTypes[j].dataType, columnValue);
            if (!isValidDataType) {
              const { dataType } = columnDataTypes[j];
              columnDataTypeRecord.push({ row: foundRowIndex, sourceId, isValidDataType, col: targetColumn + j, dataType });
            }
          }
        }
      }
    }
    this.workbookManager.resume();

    if (!!columnDataTypes.length) {
      setTimeout(() => {
        sheet.clearSelection();
        this.workbookManager.suspend();
        if (!!columnDataTypeRecord.length) {
          columnDataTypeRecord.forEach(({ row, col }) => {
            this.highlightCellValue(sheet, row, col, invalidCellColor)
          });
          this.options.setWorkbookToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
        }
        columnDataTypes.forEach(({ column }) => {
          this.onHighlightAmountCell(column);
        });
        this.workbookManager.resume();
      }, 0);
    }
  }

  highlightCellValue(sheet, row, col, foreColor, isAlignRight = false) {
    this.workbookManager.suspend();
    const cellStyle = sheet.getStyle(row, col);
    if (cellStyle) {
      const style = new GC.Spread.Sheets.Style();
      style.foreColor = foreColor || undefined;
      style.backColor = cellStyle.backColor || undefined;
      style.borderBottom = cellStyle.borderBottom || undefined;
      style.borderLeft = cellStyle.borderLeft || undefined;
      style.borderRight = cellStyle.borderRight || undefined;
      style.borderTop = cellStyle.borderTop || undefined;
      style.cellButtons = cellStyle.cellButtons || undefined;
      style.dropDowns = cellStyle.dropDowns || undefined;
      style.parentName = cellStyle.parentName || undefined;
      style.name = cellStyle.name || undefined;
  
      if (isAlignRight) {
        style.hAlign = GC.Spread.Sheets.HorizontalAlign.right;
      }
      sheet.setStyle(row, col, style);
      sheet.getCell(row, col).tag(foreColor ? 'invalid' : 'valid'); // attaching the tag to the cell.
    }
    this.workbookManager.resume();
  }

  copyRangeSourceToTarget(activeSheetIndex, sourceRange, targetRow, targetColumn) {
    this.workbookManager.suspend();
    const {
      row, col, rowCount, colCount
    } = sourceRange;
    const sheet = this.workbookManager.getSheet(activeSheetIndex);
    const workbookData = this.workbookManager.getWorkbookData();
    const { columns } = workbookData.sheets[targetSheetName];
    const cellValidationStates = [];

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < colCount; j++) {
        const sourceValue = this.workbookManager.getCellValue(sourceSheetIndex, row + i, col + j);
        this.setValue(activeSheetIndex, targetRow + i, targetColumn + j, sourceValue);
        const cellState = this.onCellValueChanged({ sheet, columns, row: targetRow + i, col: targetColumn + j });
        cellValidationStates.push(cellState)
      }
    }
    if (cellValidationStates.includes(false)) { // checking if any dataType validation failed in collection
      this.options.setWorkbookToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
    }
    this.workbookManager.resume();
  }

  onCellValueChanged({ sheet, columns, row, col }) {
    this.workbookManager.suspend();
    const cellValue = sheet.getCell(row, col).value();
    const headerName = sheet.getCell(0, col).value();
    let isValidValue = true;
    if (headerName) {
      const foundColumnConfig = columns.length ? columns.filter(col => col.headerName === headerName) : [];
      if (!!foundColumnConfig.length && foundColumnConfig[0]?.hasOwnProperty('dataType')) {
        const { dataType } = foundColumnConfig[0];
        const validCellValue = typeof cellValue === 'object' ? moment(new Date(cellValue), 'MM/DD/YYYY') : cellValue;
        isValidValue = dataType === 'string' ? true : this.validateColumnDataType(dataType, validCellValue);
        if (isValidValue) {
          this.highlightCellValue(sheet, row, col, undefined);
        } else {
          this.highlightCellValue(sheet, row, col, invalidCellColor);
        }
        this.setExistingCellFormat(sheet, row, col, dataType, cellValue);
      }
    }
    this.workbookManager.resume();
    return isValidValue;
  }

  validateTargetSheet() {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(targetSheetIndex);
    const rowCount = sheet.getRowCount();
    const workbookData = this.workbookManager.getWorkbookData();
    const { columns } = workbookData.sheets[targetSheetName];
    const invalidCoulumns = [];
    if (columns.length) {
      const columnsWithValidDataType = columns.filter(column => column?.hasOwnProperty('dataType') && column.dataType !== 'string'); // ignoring string data type columns
      for (let col = 0; col < columnsWithValidDataType.length; col++) {
        const column = columnsWithValidDataType[col];
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, column.headerName);
        if (foundColumnIndex !== -1) {
          const isInvalid = this.searchInvalidColumnValues(sheet, foundColumnIndex, rowCount, 'invalid');
          if (isInvalid) {
            invalidCoulumns.push(column.headerName);
          }
        }
      }
    }

    if (invalidCoulumns.length) {
      this.workbookManager.setActiveSheet(targetSheetName);
      const sentance = invalidCoulumns.length === 1 ? `column has` : `columns have`;
      this.options.setWorkbookToast({
        type: 'invalid-on-save-datatype',
        message: `${invalidCoulumns.join(', ')} ${sentance} ${messages.toastMessage.INVALID_DATA_TYPE_WITH_CELL_REF}`,
        autohide: false,
        wrapperClass: 'spread-validation-error',
        headerMessage: 'Data Validation Error'
      });
    }

    this.workbookManager.resume();
    return !!invalidCoulumns.length
  }

  searchInvalidColumnValues(sheet, colIndex, rowCount, cellTag) {
    let isInvalid = false;
    const invalidRows = []
    for (let row = 1; row < rowCount; row++) {
      const tag = sheet.getTag(row, colIndex);
      const cellValue =  sheet.getCell(row, colIndex).value();
      const hightlightedColor = sheet.getCell(row, colIndex).foreColor();
      if (tag === cellTag && hightlightedColor === invalidCellColor && cellValue !== null) {
        isInvalid = true;
        invalidRows.push(isInvalid);
      } else {
        isInvalid = false;
        invalidRows.push(isInvalid);
      }
    }
    return invalidRows.includes(true); // checking columns has any invalid value
  }

  setValue(sheetIndex, row, col, value, colDataType = 'string') {
    if (typeof value === 'number') {
      this.workbookManager.setCellValue(sheetIndex, row, col, value);
    } else {
      const cellValue = this.validateCurrencyColumn(value, colDataType);
      if (parseFloat(cellValue).toString() === 'NaN') {
        this.workbookManager.setCellValue(sheetIndex, row, col, cellValue);
      } else {
        this.workbookManager.setCellText(sheetIndex, row, col, cellValue.split(',').join(''));
      }
    }
  }

  isColNotEmpty(sheetIndex, colIndex) {
    for (let i = 0; i < this.workbookManager.getSheet(sheetIndex).getRowCount(); i++) {
      if (this.workbookManager.getSheet(sheetIndex).getValue(i, colIndex) != null) {
        return true;
      }
    }
    return false;
  }

  validateCurrencyColumn(value, colDataType = '') {
    const isNumber = /\(([^)]+)\)/g;
    const validNumberRegex = /[^-.,a-zA-Z0-9]/g;
    if (isNumber.test(value)) {
      let extractedValue = value.substr(1).slice(0, -1);
      if (colDataType === 'currency') {
        extractedValue = extractedValue.replace(validNumberRegex, '');
      }
      if (parseFloat(extractedValue).toString() !== 'NaN') {
        return `-${extractedValue}`;
      }
    } else {
      let cellValue = value;
      if (isNaN(value) && colDataType === 'currency') {
        const removedSpecialCharValue = cellValue.replace(validNumberRegex, '');
        if (isNaN(Number(removedSpecialCharValue)) && removedSpecialCharValue.toString().length === 1) {
          cellValue = '';
        } else {
          cellValue = removedSpecialCharValue;
        }
      }
      return cellValue;
    }
  }

  removeCurrencySymbol(value) {
    const isCurrency = /\$/g;
    return isCurrency.test(value) ? value.substr(1) : value;
  }

  updateOptions(options) {
    this.options = {
      ...this.options,
      ...options
    };
  }

  addStyles() {
    this.workbookManager.addStyle(targetSheetIndex, 'dataHeader', {
      backColor: 'rgb(43, 142, 255)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'cashflowColumns', {
      backColor: '#eee',
      foreColor: '#000',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'staticHeader', {
      backColor: 'rgb(23, 102, 155)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'chargeCodeHeader', {
      backColor: '#4e4d49',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'rentStepDate', {
      backColor: '#446',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      },
      borderLeft: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'rentStepDateData', {
      backColor: '#aae',
      foreColor: '#000',
      borderLeft: {
        color: 'black',
        lineStyle: 'medium'
      },
      borderBottom: {
        color: 'grey',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'rentStepMonthly', {
      backColor: '#557',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'rentStepMonthlyData', {
      backColor: '#ccf',
      foreColor: '#000',
      borderLeft: {
        color: 'grey',
        lineStyle: 'thin'
      },
      borderBottom: {
        color: 'grey',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'rentStepPsf', {
      backColor: '#557',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      },
      borderRight: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'rentStepPsfData', {
      backColor: '#ccf',
      foreColor: '#000',
      borderBottom: {
        color: 'grey',
        lineStyle: 'thin'
      },
      borderLeft: {
        color: 'grey',
        lineStyle: 'thin'
      },
      borderRight: {
        color: 'black',
        lineStyle: 'medium'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'staticChargeCodeHeader', {
      backColor: '#3e3d39',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.workbookManager.addStyle(targetSheetIndex, 'boldCellStyle', {
      font: 'bold 11pt Calibri'
    });

    if (this.isWorkbookHasOSTypeDoc()) {
      this.summmarySheetStyle(this.operatingStatementSummarySheetIndex)
      if (this.isAllowedCustomSummarySheet) {
        this.summmarySheetStyle(customSummarySheetIndex)
      }
      this.summaryStyle = {
        SUMMARY_TOTAL: 'summaryTotalStyle',
        SUMMARY_HEADER: 'summaryHeaderStyle',
        SUMMARY_STATIC_HEADER: 'staticSummaryHeader'
      };
    }

    this.headerStyleMap = {
      STATIC: 'staticHeader',
      CHARGE_CODE: 'chargeCodeHeader',
      STATIC_CHARGE_CODE: 'staticChargeCodeHeader',
      DATA: 'dataHeader',
      RENT_STEP_DATE: 'rentStepDate',
      RENT_STEP_DATE_DATA: 'rentStepDateData',
      RENT_STEP_MONTHLY: 'rentStepMonthly',
      RENT_STEP_MONTHLY_DATA: 'rentStepMonthlyData',
      RENT_STEP_PSF: 'rentStepPsf',
      RENT_STEP_PSF_DATA: 'rentStepPsfData',
      BOLD_CELL_STYLE: 'boldCellStyle',
      CASHFLOW_DROPDOWNS: 'cashflowColumns'
    };
  }

  summmarySheetStyle(sheetIndex) {
    this.workbookManager.suspend();
    this.workbookManager.addStyle(sheetIndex, 'summaryTotalStyle', {
      hAlign: true,
      vAlign: true
    });

    this.workbookManager.addStyle(sheetIndex, 'summaryHeaderStyle', {
      hAlign: true,
      vAlign: true,
      font: 'bold 11pt Calibri'
    });

    this.workbookManager.addStyle(sheetIndex, 'staticSummaryHeader', {
      backColor: 'rgb(23, 102, 155)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });
    this.workbookManager.resume();
  }

  setSheetData(sheetIndex, data, readonly) {
    const columnCount = data.columns.length;
    this.workbookManager.setSheetData(sheetIndex, data.rows);
    this.workbookManager.bindSheetColumns(sheetIndex, data.columns, 180);
    this.workbookManager.setSheetColumnCount(sheetIndex, columnCount + 18);
    this.workbookManager.setSheetReadOnly(sheetIndex, readonly);
  }

  setTargetColumnStyles(columns) {
    columns.forEach((column, col) => {
      const style = this.headerStyleMap[`${column.type}_DATA`];
      if (style) {
        const rowCount = this.workbookManager.getSheet(targetSheetIndex).getRowCount();
        for (let row = 1; row < rowCount; row++) {
          this.workbookManager.setCellStyle(targetSheetIndex, row, col, style);
        }
      }
    });
  }

  setTargetSheetHeaderNames(columns) {
    this.workbookManager.addRows(targetSheetIndex, 0, 1);
    this.workbookManager.setRowData(targetSheetIndex, 0, columns.map(c => c.headerName));
    columns.forEach((column, i) => {
      this.workbookManager.setCellStyle(targetSheetIndex, 0, i, this.headerStyleMap[column.type]);
    });
  }

  setDropDownList(sheetIndex, row, col, dropDownOptions) {
    this.workbookManager.suspend();
    const dropDownListCellType = new GC.Spread.Sheets.Style();
    dropDownListCellType.cellButtons = [{
      imageType: GC.Spread.Sheets.ButtonImageType.dropdown,
      command: "openList",
      useButtonStyle: false
    }];
    dropDownListCellType.dropDowns = [{
      type: GC.Spread.Sheets.DropDownType.list,
      option: {
        items: convertDataListDropDown(dropDownOptions)
      }
    }
    ];
    this.workbookManager.getSheet(sheetIndex).setStyle(row, col, dropDownListCellType);
    this.workbookManager.resume();
  }


  setMonthPicker(sheetIndex, row, col) {
    this.workbookManager.suspend();
    const dateTimePicker = new GC.Spread.Sheets.Style();
    dateTimePicker.cellButtons = [{
      imageType: GC.Spread.Sheets.ButtonImageType.dropdown,
      command: 'openMonthPicker',
      useButtonStyle: false,
    }];
    dateTimePicker.dropDowns = [{
      type: GC.Spread.Sheets.DropDownType.monthPicker,
      option: {
        startYear: 2000,
        stopYear: 2039,
      },
    }];
    dateTimePicker.formatter = 'mmm yyyy';
    this.workbookManager.getSheet(sheetIndex).setStyle(row, col, dateTimePicker);
    this.workbookManager.resume();
  }

  setDropDownColumns({ rows, columns }) {
    const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head');
    const headType = Array(1).fill('').concat(Reflect.ownKeys(this.options.projectTemplateHeadCategories).sort());
    columns.forEach((column, c) => {
      if (column.options) {
        rows.forEach((row, r) => {
          if (Reflect.ownKeys(row).length > 0 && r > 0) {
            const dropDownOptions = foundColumnIndex !== -1 ? headType : column.options;
            this.setDropDownList(targetSheetIndex, r, c, dropDownOptions);
          }
        });
      }
    });
  }

  highlightMappedRows(source, target) {
    let sourceIndex = 0;
    let targetIndex = 1;

    while (targetIndex < target.rows.length && sourceIndex <= source.rows.length - 1) {
      if (target.rows[targetIndex].id === source.rows[sourceIndex].id) {
        let color = '#eee';

        if (target.rows[targetIndex].head) {
          color = rowColors[target.rows[targetIndex].head];
          this.workbookManager.setCellRangeAttr(targetSheetIndex, targetIndex, 0, 1, target.columns.length, 'backColor', color);
        }

        this.workbookManager.setCellRangeAttr(sourceSheetIndex, sourceIndex, 0, 1, source.columns.length, 'backColor', color);
        targetIndex++;
      }
      sourceIndex++;
    }
  }

  populateTentativeStatusOnFirstRender() {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      const targetSheetColumnCount = this.workbookManager.getSheet(targetSheetIndex).getColumnCount();
      const columnIndex = targetSheetColumnCount - 3;
      const statusIndex = this.getColIndex("Status");

      const { targetSheetData } = this.getSheetData();
      const { data: { dataTable } } = targetSheetData;
      this.workbookManager.setCellValue(targetSheetIndex, 0, columnIndex, tentativeColumnName);
      const sheet = this.workbookManager.getSheet(targetSheetIndex);
      sheet.setColumnWidth(columnIndex, 0);
      sheet.setColumnResizable(columnIndex, false, GC.Spread.Sheets.SheetArea.colHeader);
      for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
        const rowIndex = i;
        const status = this.workbookManager.getCellValue(targetSheetIndex, rowIndex, statusIndex);
        this.workbookManager.setCellValue(targetSheetIndex, rowIndex, columnIndex, status);
      }
    }
  }

  setData(data, onlySource = false) {
    if (this.workbookManager && this.workbookManager.isInitialized()) {
      this.workbookManager.suspend();
      this.setSheetData(sourceSheetIndex, data.source, true);
      this.workbookManager.setSheetName(sourceSheetIndex, sourceSheetName);
      this.workbookManager.resetColFreeze(0);
      if (!onlySource) {
        this.allColumnList = data.target.columns;
        this.setSheetData(targetSheetIndex, data.target, false);
        this.setTargetSheetHeaderNames(data.target.columns);
        this.setTargetColumnStyles(data.target.columns);
        this.workbookManager.autoFitColumns(targetSheetIndex);
        this.workbookManager.setSheetName(targetSheetIndex, targetSheetName);

        if (this.isWorkbookHasOSTypeDoc()) {
          const updatedWorkingSheetIndex = this.isAllowedCustomSummarySheet ? 4 : workingSheetIndex;
          this.workbookManager.setSheetName(this.operatingStatementSummarySheetIndex, summarySheetName);   
          if (this.isAllowedCustomSummarySheet) {
            this.workbookManager.setSheetName(customSummarySheetIndex, `${this.options.template?.templateName}-summary`);   
          }  
          this.setSheetData(updatedWorkingSheetIndex, {
            columns: [],
            rows: [[]]
          }, false);
          this.workbookManager.setSheetName(updatedWorkingSheetIndex, workingSheetName);     
          const months = map(this.allColumnList.filter(month => !isNil(month) && moment(month.headerName, "MMMM YYYY")._isValid && month), 'headerName');
          if (months.length < 3 && Reflect.ownKeys(data.target).length) {
            !!data.target.columns.length && data.target.columns.pop(); // removing total column if there are less than 3 months
          }
        } else {
          this.workbookManager.setSheetName(rrSheetIndex.workingSheetIndex, workingSheetName);
          this.workbookManager.setSheetName(rrSheetIndex.summarySheetIndex, summarySheetName);
          setTimeout(() => this.setRRSummarySheetData(true), 0);
        }
        this.setDropDownColumns(data.target);
        this.bindCategoryDropdown(data.target);
        this.highlightLineItems(data.target);
        this.setSummarySheetData(this.operatingStatementSummarySheetIndex);
        if (this.isWorkbookHasOSTypeDoc() && this.isAllowedCustomSummarySheet) {
          this.setSummarySheetData(customSummarySheetIndex);
        }
        this.onFreezeRowColumn(targetSheetIndex);
        this.setCellFormatInUSNumber();
        this.setShowHideMonthColumnsOnLoad();
        this.onDataBind();
        this.setChargeCodeAfterColumnMerge(false, 'onDatabind');
        this.setCellBorder(data);
        // this.highlightVacancyLossAmountCell();
        this.handleColumnsFormat();
        this.setOutlineToGroupedColumns();
        this.setMonthTotalAmount(); // Always call this method at last to highlight the diffrence value between amount & total
        this.populateTentativeStatusOnFirstRender();
        //Allowing context menu for non-protected sheet
        this.workbookManager.workbook.options.allowContextMenu = true;
        this.highlightMappedRows(data.source, data.target);
        this.hideWorkingSheet();

        if (this.isWorkbookHasOSFullTypeDoc()) { // setting cashflow columns
          const { cashflowDropdowns } = this.options;
          this.workbookManager.addRows(targetSheetIndex, 1, 3);
          this.setAdditionalCashFlowColumns(data.target.columns, cashflowDropdowns);
          this.workbookManager.freezeRow(targetSheetIndex, 4); // Freezing row header
          this.workbookManager.getSheet(summarySheetIndex).visible(false); // Hiding summary sheet for cashflow
          this.getCFSummary();
        } 
      } else {
        const sheetCount = this.workbookManager.workbook.sheets.length;
        for (let i = 1; i < sheetCount; i++) {
          this.workbookManager.workbook.removeSheet(1);
        }
      }
      setTimeout(() => this.setSheetReadOnly(), 0);
      this.workbookManager.resume();
    }
  }

  setAdditionalCashFlowColumns(columns, columnDropdown) {
    this.workbookManager.suspend();
    columns.forEach((column, colIndex) => {
      if (column?.isCashFlowColumn) {
        this.workbookManager.setCellValue(targetSheetIndex, 1, colIndex, startCase(column.cashFlowType));
        this.workbookManager.setCellValue(targetSheetIndex, 2, colIndex, startCase(column.period)); 
        this.bindPeriodColumns(3, colIndex, startCase(column.period), column.periodEndDate);
        this.setDropDownList(
          targetSheetIndex,
          1,
          colIndex,
          columnDropdown.cfPeriodType,
        );
        this.setDropDownList(
          targetSheetIndex,
          2,
          colIndex,
          columnDropdown.cfPeriod,
        );
        this.setCashFlowPriodDropdownStyle(colIndex);
        this.workbookManager.getSheet(targetSheetIndex).setColumnWidth(colIndex, 180);
      }
    });
    this.workbookManager.resume();
  }

  isValidColumn(colIndex) {
    return !!this.allColumnList.length && !!this.allColumnList[colIndex];
  }

  bindPeriodColumns(row, col, period, columnPeriodEndDate) {
    this.workbookManager.suspend();
    const periodEndDateColValue = this.workbookManager.getCellValue(targetSheetIndex, 3, col);
    let periodEndDate = columnPeriodEndDate ? columnPeriodEndDate : 
      periodEndDateColValue ? periodEndDateColValue : this.allColumnList[col].periodEndDate;
      
    periodEndDate = getQuarterDateFromString(periodEndDate);
    periodEndDate = moment(new Date(periodEndDate)).format("YYYY-MM-DD");

    if (moment(periodEndDate, "YYYY-MM-DD")._isValid) {
      if (period === 'Yearly' || period === null) {
        if (!!periodEndDate) {
          if (period && this.isValidColumn(col)) {
            const year = new Date(periodEndDate).getFullYear();
            this.allColumnList[col].periodEndDate = `${year}-12-31`;
            this.workbookManager.setCellValue(targetSheetIndex, 3, col, year);
            this.workbookManager.setCellStyle(targetSheetIndex, 3, col, null);
            this.workbookManager.getSheet(targetSheetIndex).setFormatter(3, col, '');
          }
        }
        this.setDropDownList(
          targetSheetIndex,
          row,
          col,
          new Array(20).fill(0).map((_, i) => 2020 + i - 10),
        );
      }
      if (period === 'Monthly' || period === 'YTD' || period === 'TTM') {
        if (!!periodEndDate) {
          if (this.isValidColumn(col)) {
            this.allColumnList[col].periodEndDate = periodEndDate;
          }
          this.workbookManager.setCellValue(targetSheetIndex, 3, col, periodEndDate);
        }
        this.setMonthPicker(targetSheetIndex, row, col);
      }
      if (period === 'Quarterly') {
        if (!!periodEndDate) {
          if (this.isValidColumn(col)) {
            this.allColumnList[col].periodEndDate = periodEndDate;
          }
          const quarter = moment(periodEndDate).utc().quarter();
          const year = moment(periodEndDate).utc().format('YYYY')
          this.workbookManager.setCellValue(targetSheetIndex, 3, col, `Q${quarter} ${year}`);
        }
        this.setQuarterDropDownList(targetSheetIndex, row, col);
      }
      if (this.isValidColumn(col)) {
        const foundPeriod = this.options.cashflowDropdowns.cfPeriod.includes(period);
        this.allColumnList[col].period = foundPeriod ? foundPeriod?.name : '';
        if (period === '') {
          this.workbookManager.setCellValue(targetSheetIndex, 3, col, null)
          this.allColumnList[col].periodEndDate = null;
        }
      }
      this.setCashFlowPriodDropdownStyle(col)
    }
    this.workbookManager.resume();
  }

  handlePeriodType(row, col, periodType) {
    this.workbookManager.suspend();
    if (this.isValidColumn(col)) {
      const foundPeriodType =  this.options.cashflowDropdowns.cfPeriodType.includes(periodType);
      this.allColumnList[col].type = foundPeriodType ? periodType : '';
      if (periodType === '') {
        this.workbookManager.setCellValue(targetSheetIndex, row + 1, col, '')
        this.workbookManager.setCellValue(targetSheetIndex, row + 2, col, '');
        this.allColumnList[col] = {
          cashFlowType: '',
          period: '',
          periodEndDate: null,
          ...this.allColumnList[col]
        }
      }
    }
    this.workbookManager.resume();
  }

  handlePeriodDate(col, periodDate) {
    this.workbookManager.suspend();
    const period = this.workbookManager.getCellValue(targetSheetIndex, 2, col);
    if (this.isValidColumn(col)) {
      if (period === 'Yearly') {
        this.allColumnList[col].periodEndDate = `${periodDate}-12-31`;
      } else if (period === 'Monthly' || period === 'YTD' || period === 'TTM') {
        if (typeof periodDate === 'object') {
          const periodEndDate = moment(periodDate).endOf('month').format('YYYY-MM-DD');
          this.allColumnList[col].periodEndDate = periodEndDate;
          this.workbookManager.setCellValue(targetSheetIndex, 3, col, periodEndDate);
        }
      } else if (period === 'Quarterly') {
        this.allColumnList[col].periodEndDate = getQuarterDateFromString(periodDate);
      }
      this.setCashFlowPriodDropdownStyle(col);
    }
    this.workbookManager.resume();
  }

  setQuarterDropDownList(sheetIndex, row, col) {
    this.workbookManager.suspend();
    let year = 2020;
    const taggingInfo = currentDocumentTaggingInfoSelector(this.options.store.getState());
    if (taggingInfo?.periodFrom) {
      year = +moment(taggingInfo.periodFrom).format('YYYY');
    }

    const dropDownListCellType = new GC.Spread.Sheets.Style();
    dropDownListCellType.cellButtons = [
      {
        imageType: GC.Spread.Sheets.ButtonImageType.dropdown,
        command: 'openList',
        useButtonStyle: false,
      },
    ];
    dropDownListCellType.dropDowns = [
      {
        type: GC.Spread.Sheets.DropDownType.list,
        option: {
          items: new Array(30)
            .fill(0)
            .map((_, i) => year + i - 5)
            .map((value) => {
              return {
                text: value,
                value,
                layout: { displayAs: GC.Spread.Sheets.LayoutDisplayAs.tree, collapsible: false },
                items: new Array(4).fill(0).map((_, row) => {
                  const rowIndex = row + 1;
                  return {
                    text: `Q${rowIndex} ${value}`,
                    value: `Q${rowIndex} ${value}`,
                  };
                }),
              };
            }),
        },
      },
    ];
    this.workbookManager
      .getSheet(sheetIndex)
      .setStyle(row, col, dropDownListCellType);
    this.setCashFlowPriodDropdownStyle(col);  
    this.workbookManager.resume();
  }

  setCashFlowPriodDropdownStyle(col) {
    this.workbookManager.suspend();
    this.workbookManager.getSheet(targetSheetIndex).getRange(1, col, 3, 1)['backColor']('#eee');
    this.workbookManager.getSheet(targetSheetIndex).getRange(1, col, 3, 1)['foreColor']('#000');
    this.workbookManager.resume();
  }

  setAiParserData(data) {
    this.workbookManager.suspend();
    let workbookData = [];
    const sheetNames = this.workbookManager.getSheetNames();
    if (!sheetNames.includes(aiParserSheet.name)) {
      const sheet = new GC.Spread.Sheets.Worksheet(aiParserSheet.name);
      this.workbookManager.workbook.addSheet(aiParserSheet.sheetIndex, sheet);
      this.workbookManager.addStyle(aiParserSheet.sheetIndex, 'aiParserHeader', {
        backColor: 'rgb(43, 142, 255)',
        foreColor: '#fff',
        borderBottom: {
          color: 'black',
          lineStyle: 'thin'
        }
      });
    } else {
      this.resetAiParserSheet();
    }

    if (data && typeof data === 'object') {
      const arrayData = Reflect.ownKeys(data).map(d => data[d]);
      const sortedData = arrayData.sort((a, b) => a.displayOrder < b.displayOrder ? -1 : 1);
      sortedData.forEach(d => delete d.displayOrder);
      workbookData = sortedData;
    }

    if (data && Array.isArray(data)) {
      const sortedData = data.sort((a, b) => a.displayOrder < b.displayOrder ? -1 : 1);
      sortedData.forEach(d => delete d.displayOrder);
      workbookData = sortedData;
    }
    this.workbookManager.setActiveSheet(aiParserSheet.name);
    this.setAiParserSheetData(aiParserSheet.sheetIndex, workbookData);
    this.options.setWorkbookToast({ type: 'success', message: messages.toastMessage.BETA_PARSER_SUCCESS, autohide: true });
    this.workbookManager.resume();
  }

  resetAiParserSheet() {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(aiParserSheet.sheetIndex)
    sheet.getRange(-1, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).clear(GC.Spread.Sheets.StorageType.style);
    this.workbookManager.resume();
  }

  setAiParserSheetData(sheetIndex, data) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(sheetIndex)
    const { columns, rows } = this.sanitizeAiParserData(data);  
    this.workbookManager.setSheetColumnCount(sheetIndex, columns.length + 50);
    this.workbookManager.setSheetData(sheetIndex, rows, 300, columns.length + 50, 250);
    this.workbookManager.bindSheetColumns(sheetIndex, columns, 250);
    this.setAiParserSheetHeaderNames(sheetIndex, columns);
    this.workbookManager.resizeCell(sheet, { row: 0, col: 0, rowCount: 300, colCount: columns.length });
    this.setAiParserSheetFormatter(sheet, rows, columns);
    this.workbookManager.resume();
  }
  
  sanitizeAiParserData(data) {
    const rows = getAiParserDataRows(data);
    const columns = getAiParserDataColumns(rows);
    return {
      columns, rows
    }
  }

  setAiParserSheetHeaderNames(sheetIndex, columns) {
    this.workbookManager.addRows(sheetIndex, 0, 1);
    this.workbookManager.setRowData(sheetIndex, 0, columns.map(c => c.headerName));
    this.workbookManager.freezeRow(sheetIndex, 1); // Freezing row header

    columns.forEach((column, i) => {
      this.workbookManager.setCellStyle(sheetIndex, 0, i, 'aiParserHeader');
    });
  }


  setAiParserSheetFormatter(sheet, rows, columns) {
    this.workbookManager.suspend();
    const rowCount = sheet.getRowCount();
    if (Array.isArray(columns) && columns.length > 0) {
      columns.forEach(column => {
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(aiParserSheet.sheetIndex, 0, column.headerName);

        if (foundColumnIndex !== -1) {
          const range = sheet.getRange(1, foundColumnIndex, rowCount, 1);
          const isValidDateColumn = !!column.name && toLower(column.name).includes('date');

          const isValidNumberColumn = rows.some((row, idx) =>
          (idx > 0 && !aiParserIgnoredColumnsForFormatter.includes(column.name))
          && isValidAmountNumber(row[column.name]) && !isValidDateColumn);

          if (isValidDateColumn) {
            this.setAiParserSheetDateColumn(sheet, rows, column.name, foundColumnIndex)
          } else if (isValidNumberColumn) {
            range.formatter(USCurrencyFormat);
          } else {
            range.formatter(
              !aiParserNumberColumns.includes(column.name) ? '@' : ''
            );
          }
        }
      })
    }
    this.workbookManager.resume()
  }

  hideWorkingSheet() {
    this.workbookManager.suspend();
    const sheets = this.workbookManager.getSheetNames();
    if (sheets.length > 0 && sheets.includes(workingSheetName)) {
      this.workbookManager.getSheet(workingSheetIndex).visible(false)
    }
    this.workbookManager.resume();
  }

  setAiParserSheetDateColumn(sheet, rows, columnName, columnIndex) {
    this.workbookManager.suspend();
    rows.forEach((row, rowIdx) => {
      const columnValue = row[columnName];
      const date = moment(columnValue, validDateFormats, true);
      const cellValue =  date.isValid() ? moment(new Date(columnValue)).format("MM/DD/YYYY") : null; 
      if (cellValue) {
        sheet.setValue(rowIdx, columnIndex, new Date(cellValue));
        sheet.setFormatter(rowIdx, columnIndex, USDateFormat);
      } else {
        rowIdx !== 0 && sheet.setValue(rowIdx, columnIndex, null)
      }
    })
    this.workbookManager.resume();
  }

  setSheetReadOnly() {
    this.workbookManager.suspend();
    if (this.options.readOnlyAccess) {
      const sheets = this.workbookManager.getSheets();
      if (sheets.length > 0) {
        sheets.forEach((sheet, sheetIndex) => {
          this.workbookManager.setSheetReadOnly(sheetIndex, true);
          if (this.isWorkbookHasOSTypeDoc() && sheet.name() === summarySheetName) {
            sheet.bind(GC.Spread.Sheets.Events.EditStarting, function (_, args) {
              args.cancel = true;
            });
          }
        })
      }
    }
    this.workbookManager.resume();
  }

  setSheetZoomRatio() {
    this.workbookManager.suspend();
    const sheets = this.workbookManager.getSheets();
    if (window.screen && window.screen.width && !!sheets.length) {
      const zoomFactor = (window.screen.width >= 1600) ? 1 : (window.screen.width >= 1440) ? 0.8 : 0.7;
      for (let i = 0; i < sheets.length; i++) {
        sheets[i].zoom(zoomFactor);
      }
    }
    this.workbookManager.resume();
  }

  updateRowColors(rowCount, columnCount) {
    this.workbookManager.suspend();
    const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head')
      .foundColumnIndex;

    for (let r = 1; r < rowCount; r++) {
      const head = this.workbookManager.getCellValue(targetSheetIndex, r, headIndex);
      this.workbookManager.setCellRangeAttr(targetSheetIndex, r, 0, 1, columnCount, 'backColor', rowColors[head]);
    }
    this.workbookManager.resume();
  }

  setWorkbookData(data, onlySource = false) {
    this.workbookManager.suspend();
    this.workbookManager.setWorkbookData(data);
    const { columns, rowCount } = data.sheets[targetSheetName];
    const { targetSheetData } = this.getSheetData();
    const targetSheetColCount = this.workbookManager.getColumnCount(targetSheetData);
    this.allColumnList = columns;
    this.onDataBind('onDataBind');
    if (onlySource) {
      const sheetCount = this.workbookManager.workbook.sheets.length;
      for (let i = 1; i < sheetCount; i++) {
        this.workbookManager.workbook.removeSheet(1);
      }
    } else {
      this.updateRowColors(rowCount, targetSheetColCount);
      if (this.isWorkbookHasOSTypeDoc()) {
        if (
          this.isAllowedCustomSummarySheet && 
          Reflect.ownKeys(data.sheets).length !== 5 // checking if saved worksbook has no custom summary sheet
          ) {
          this.workbookManager.workbook.setSheetCount(5);
          this.setSheetData(customSummarySheetIndex,  {
            columns: [],
            rows: [[]]
          }, false);
          this.workbookManager.setColumnWidths(customSummarySheetIndex, 180);
          this.workbookManager.setColumnWidths(this.operatingStatementSummarySheetIndex, 180);
          this.workbookManager.setSheetName(customSummarySheetIndex, `${this.options.template?.templateName}-summary`);   
          this.setSummarySheetData(customSummarySheetIndex);
          this.workbookManager.setSheetName(this.operatingStatementSummarySheetIndex, summarySheetName);
          //Adding Blank Sheet here
          this.workbookManager.setSheetData(this.operatingStatementSummarySheetIndex + 1, [[]]);
          this.workbookManager.setSheetName(this.operatingStatementSummarySheetIndex + 1, workingSheetName);
          this.addStyles()
        }
        this.setSummarySheetData(this.operatingStatementSummarySheetIndex);
      }
      //if summarySheet does not exist
      if(!data.sheets[summarySheetName]) {
        this.workbookManager.workbook.setSheetCount(4);
        this.workbookManager.setSheetData(rrSheetIndex.summarySheetIndex, [[]]);
        this.workbookManager.setSheetName(rrSheetIndex.summarySheetIndex, summarySheetName);
        this.workbookManager.setSheetName(rrSheetIndex.workingSheetIndex, workingSheetName);
      }

      if (this.isWorkbookHasOSFullTypeDoc()) {
        this.getCFSummary();
      }
      this.validateTargetSheet(); // checking on load, if target sheet column contains any invalid entry
      // this.highlightVacancyLossAmountCell();

      this.setMonthTotalAmount();
      //Allowing context menu for non-protected sheet
      this.workbookManager.workbook.options.allowContextMenu = true;
      setTimeout(() => this.setRRSummarySheetData(), 0);
    }
    setTimeout(() => this.setSheetReadOnly(), 0);
    this.hideWorkingSheet();
    this.workbookManager.resume();
  }

  onDataBind(eventType = undefined) {
    this.workbookManager.suspend();
    this.setSheetZoomRatio();
    this.operatingStatementEvents();
    setTimeout(() => this.workbookManager.onToggleMonths(), 0);
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.setInitialChargeCode();
      setTimeout(() => this.getUniqueUnitStatus(), 0);
      this.calculateUnitMixSummary(eventType);
      this.getUniqueFloorPlan('onDataBind');
      this.setRentRollLeaseTypeData();
      // setTimeout(() => {
      //   this.validateMonthlyRent();
      // }, 1000);
    }
    if (this.isWorkbookHasRentRollTypeDoc() && !this.isMultifamilyOrHealthcareDocument()) {
      this.setInitialChargeCode(true);
      this.getRetailLeaseExpirationData();
      this.getRetailOccupancyStatus();
      this.getRetailLeaseType();
      this.getRetailTenantTypeData();
    }
    this.workbookManager.resume();
  }

  getColIndex(columnName) {
    return this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, columnName).foundColumnIndex;
  }

  validateMonthlyRent() {
    // Validate only if tentative status exists,
    const tentativeStatusIndex = this.getColIndex(columnMap["Status"]);
    if (tentativeStatusIndex < 0) {
      return false;
    }
    this.workbookManager.suspend();
    const taggingInfo = currentDocumentTaggingInfoSelector(this.options.store.getState());
    const asOnDate = get(taggingInfo, 'asOnDate');

    const rowCount = this.workbookManager.getSheetRowCount(targetSheetIndex);
    const sheet = this.workbookManager.getSheet(targetSheetIndex);

    const statusIndex = this.getColIndex(columnMap["Status"]);
    const marketRentIndex = this.getColIndex(columnMap['MarketRent']);
    const monthlyRentIndex = this.getColIndex(columnMap['MonthlyRent']);
    const leaseEndDateIndex = this.getColIndex(columnMap['LeaseEndDate']);

    this.statusIndex = statusIndex;
    this.marketRentIndex = marketRentIndex;
    this.monthlyRentIndex = monthlyRentIndex;
    this.leaseEndDateIndex = leaseEndDateIndex;

    Array(rowCount).fill(0).map((_, i) => {

      const index = i + 1;
      const row = index;
      const status = this.workbookManager.getCellValue(targetSheetIndex, row, statusIndex);
      const marketRent = this.workbookManager.getCellValue(targetSheetIndex, row, marketRentIndex);
      const monthlyRent = this.workbookManager.getCellValue(targetSheetIndex, row, monthlyRentIndex);
      const leaseEndDate = this.workbookManager.getCellValue(targetSheetIndex, row, leaseEndDateIndex);

      let shouldHighlightCell = false;
      let errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];
      if (status === statusMap["Occupied"] && monthlyRent <= 1) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];
      }

      if (status === statusMap["Vacant"] && monthlyRent > 0) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_NON_0'];
      }


      if (monthlyRent > 0 && marketRent > 0) {
        if (status === statusMap["Occupied"] && rentDiffPercentage(monthlyRent, marketRent) > cutoffPercentage) {
          shouldHighlightCell = true;
          errorMessageMapObj = errorMessageMap['CUTTOFF_ERROR'];
        }
      }

      if (moment(leaseEndDate).isBefore(asOnDate)) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['ASOFDATE_AFTER_ENDDATE'];
      }

      this.highlightRow(sheet, row, errorMessageMapObj, shouldHighlightCell);
    });

    this.workbookManager.resume();
  }

  highlightCell(sheet, row, col, shouldHighlightCell) {
    this.workbookManager.suspend();
    sheet.getRange(row, col, 1, 1)['backColor'](shouldHighlightCell ? '#FF6F61' : undefined);
    sheet.getRange(row, col, 1, 1)['foreColor'](shouldHighlightCell ? 'black' : undefined);
    this.workbookManager.resume();
  }

  highlightRow(sheet, row, errorMessageMap, shouldHighlightCell) {
    this.workbookManager.suspend();
    const { color, message } = errorMessageMap;
    sheet.getRange(row, -1, 1, -1).backColor(shouldHighlightCell ? color : undefined);
    sheet.comments.add(row, 1, shouldHighlightCell ? message : undefined);
    this.workbookManager.resume();
  }

  operatingStatementEvents() {
    if (this.isWorkbookHasOSTypeDoc()) {
      this.getUniqueCategoriesWithLineItems();
      this.getRentalIncomeInfo();
      // this.highlightVacancyLossAmountCell();
    }
  }

  onFreezeRowColumn(sheetIndex) {
    this.workbookManager.resetRowFreeze(sheetIndex);
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.freezeColumn(sheetIndex, 3); // Freezing column till floor plan column.
      this.workbookManager.freezeColumn(rrSheetIndex.summarySheetIndex, 2); //Freezing column of rr summary sheet till column 2
    } else {
      this.workbookManager.resetColFreeze(sheetIndex);
    }
  }

  onRentRollEventTrigger(eventType = undefined) {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.getUniqueUnitStatus();
      this.calculateUnitMixSummary(eventType);
      this.getUniqueFloorPlan(eventType);
      this.retrieveChargeCodes();
      this.setRentRollLeaseTypeData();
      this.setRRSummarySheetData();
    }

    if (this.isWorkbookHasRentRollTypeDoc() && !this.isMultifamilyOrHealthcareDocument()) {
      this.getRetailLeaseExpirationData();
      this.retrieveChargeCodes();
      this.getRetailOccupancyStatus();
      this.getRetailLeaseType();
      this.getRetailTenantTypeData();
    }
  }

  setColumnReadOnly(sheetIndex, row, col, rowCount, columnCount) {
    const sheet = this.workbookManager.getSheet(sheetIndex);
    const summarySheetColCount = this.workbookManager.getSheet(this.operatingStatementSummarySheetIndex).getColumnCount();
    this.workbookManager.suspend();
    this.workbookManager.setCellRangeAttr(sheetIndex, row, col, rowCount, columnCount, 'locked', true);
    this.workbookManager.setCellRangeAttr(sheetIndex, -1, 0, -1, summarySheetColCount, 'locked', false);
    sheet.options.isProtected = true;
    sheet.options.protectionOptions.allowDeleteRows = true;
    sheet.options.protectionOptions.allowDeleteColumns = true;
    sheet.options.protectionOptions.allowInsertRows = true;
    sheet.options.protectionOptions.allowInsertColumns = true;
    sheet.options.protectionOptions.allowDargInsertRows = true;
    sheet.options.protectionOptions.allowDragInsertColumns = true;
    this.workbookManager.resume();
  }

  setCellBorder(data) {
    this.workbookManager.suspend();
    if (data) {
      const dataKey = ['source', 'target'];
      for (let sheetIndex = 0; sheetIndex < 2; sheetIndex++) {
        const sheet = this.workbookManager.getSheet(sheetIndex);
        const { columns, rows } = data[dataKey[sheetIndex]];
        const rowCount = rows.filter(item => Reflect.ownKeys(item).length > 0).length;
        sheet.getRange(0, 0, rowCount, columns.length, GC.Spread.Sheets.SheetArea.viewport)
          .setBorder(new GC.Spread.Sheets.LineBorder("#ababab", GC.Spread.Sheets.LineStyle.thin), { all: true }, 3);
      }
    }
    this.workbookManager.resume();
  }


  setColumnHeaderList(data) {
    this.columnHeadersList = data;
  }

  setCellFormatInUSNumber() {
    if (this.isWorkbookHasOSTypeDoc()) {
      const columnCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getColumnCount();
      const rowCount = this.workbookManager.getSheetRowCount(targetSheetIndex);
      const sheet = this.workbookManager.getSheet(targetSheetIndex);
      const amountIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Amount').foundColumnIndex;
      const targetSheetDataTotalColCount = columnCount - amountIndex;
      const cellFormatInUSNumberSelection = [{
        row: 1,
        rowCount,
        col: amountIndex,
        colCount: targetSheetDataTotalColCount
      }];
      this.workbookManager.suspend();
      cellFormatInUSNumberSelection.forEach((selection) => {
        const range = sheet.getRange(selection.row, selection.col, selection.rowCount, selection.colCount);
        range.formatter(USCurrencyFormat);
      });
      this.workbookManager.resume();
    }
  }


  bindCategoryDropdown(target) {
    const categoryColumnIndex = target.columns.findIndex(column => column.name === 'category');
    const subCategoryColumnIndex = target.columns.findIndex(column => column.name === 'sub_category');
    if (categoryColumnIndex !== -1) {
      target.rows.forEach((rowObj, row) => {
        if (Reflect.ownKeys(rowObj).length > 0) {
          row && this.setCategoryDropdown(row, categoryColumnIndex, rowObj.head);
          row && subCategoryColumnIndex !== -1 && this.setSubCategoryDropdown(row, subCategoryColumnIndex, rowObj.head, rowObj.category);
        }
      });
    }
  }

  highlightLineItems(targetData) {
    if (this.isWorkbookHasOSTypeDoc() || this.isWorkbookHasOSFullTypeDoc()) {
      this.workbookManager.suspend();
      const lineItemColumnIndex = targetData.columns.findIndex(column => column.name === 'line_item');
      const isProjectDictionaryExists = projectDictionaryExistsSelector(this.options.store.getState());
      if (lineItemColumnIndex !== -1) {
        targetData.rows.forEach((rowObj, row) => {
          if (!!Reflect.ownKeys(rowObj).length && row > 0 && rowObj.options) {
            if (isProjectDictionaryExists) {
              const { options: { lineItemMatch } } = rowObj;
              if (!isNil(lineItemMatch) && !lineItemMatch) {
                this.workbookManager.setCellStyle(targetSheetIndex, row, lineItemColumnIndex, this.headerStyleMap.BOLD_CELL_STYLE);
              }
            }
          }
        });
      }
      this.workbookManager.resume();
    }
  }

  onHighlightAmountCell(columnIndex = null) {
    if (this.isWorkbookHasOSTypeDoc()) {
      if (columnIndex) {
        const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
        const categoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
        const subCategoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
        if (columnIndex !== headIndex && columnIndex !== categoryIndex && columnIndex !== subCategoryIndex) {
          this.setMonthTotalAmount(columnIndex);
        }
      }
    }
  }

  setMonthTotalAmount(columnIndex = null) {
    if (this.isWorkbookHasOSTypeDoc() && this.options.docExtractionStatus === ExtractionJobStatus.SUCCESSFUL.key) {
      const serialNumberColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'S.N.').foundColumnIndex;
      const amountColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Amount').foundColumnIndex;
      const adjustmentColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Adjustments').foundColumnIndex;
      const totalColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Total').foundColumnIndex;
      const notesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Notes').foundColumnIndex;
      const { targetSheetData: { data: { dataTable } } } = this.getSheetData();
      const startColumnIndex = adjustmentColumnIndex === -1 ? amountColumnIndex : adjustmentColumnIndex;
      const sheet = this.workbookManager.getSheet(targetSheetIndex);
      const colAlphabetIndex = colIndex => this.workbookManager.toAlphabetColumnName(colIndex);
      this.workbookManager.suspend();
      if (startColumnIndex !== -1 && totalColumnIndex !== -1) {
        const colCount = Reflect.ownKeys(dataTable[0]).length - 1; // dataTable consists column info at zero index
        const monthColCount = (colCount - startColumnIndex) - 1;
        if (monthColCount > 0) {
          monthColCount > 2 ? sheet.setColumnWidth(totalColumnIndex, 150) : sheet.deleteColumns(totalColumnIndex, 1);
          for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
            const rowIndex = i + 1;
            const amountColValue = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=VALUE(${colAlphabetIndex(amountColumnIndex + 1)}${rowIndex})`);
            const totalColValue = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=VALUE(${colAlphabetIndex(totalColumnIndex + 1)}${rowIndex})`);
            const serialNumber = this.workbookManager.getCellValue(targetSheetIndex, i, serialNumberColIndex);
            if (!isNil(amountColValue) && !isNil(serialNumber)) {
              const columnCount = notesColIndex !== -1 ? colCount - 1 : colCount;
              const monthsColValue = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${colAlphabetIndex(startColumnIndex + 2)}${rowIndex}:${colAlphabetIndex(columnCount)}${rowIndex})`);
              let totalAmountValue = 0
              if (!!columnIndex && columnIndex < colCount) { // checking if edited column is month column 
                totalAmountValue = monthsColValue;
                //syncing up the months sum with total column total.
                this.workbookManager.setCellValue(targetSheetIndex, i, totalColumnIndex, totalAmountValue);
              } else {
                totalAmountValue = totalColValue;
              }
              if (monthColCount > 2 && totalColumnIndex !== -1) {
                this.compareAmountWithTotals(sheet, i, amountColumnIndex, amountColValue, totalAmountValue);
              }
            }
          }
        }
      }
      this.workbookManager.resume();
    }
  }

  compareAmountWithTotals(sheet, rowIndex, amountColumnIndex, amountColValue, totalColValue) {
    if (this.options.isEqualTaggedPeriodToMLPeriod) {
      if (!isNil(amountColValue) && !isNil(totalColValue)) {
        const maxNumber = Math.floor(totalColValue) + 1;
        const minNumber = Math.floor(totalColValue) - 1;
        const amountValue = Math.floor(amountColValue);
        if (!isNumberBetweenRange(amountValue, minNumber, maxNumber)) {
          sheet.getCell(rowIndex, amountColumnIndex).backgroundImage(RED_BACKGROUND_BASE64_IMG);
        } else {
          sheet.getCell(rowIndex, amountColumnIndex).backgroundImage(undefined);
        }
      }
    }
  }

  setReverseSignToRowForOS() {
    const activeSheetIndex = this.workbookManager.getActiveSheetIndex();
    if (activeSheetIndex === targetSheetIndex) {
      const selections = this.workbookManager.getSelections();
      const serialNumberIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'S.N.').foundColumnIndex;
      const lineItemIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Line Item').foundColumnIndex;
      const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
      const categoriesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
      const subCategoriesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
      const { targetSheetData, sourceSheetData } = this.getSheetData();
      selections.forEach(selection => {
        const { row, rowCount, col } = selection;
        const previousSerialNumberValues = this.workbookManager.getColumnValues(targetSheetIndex, row, rowCount, serialNumberIndex);
        const previousHeadValues = this.workbookManager.getColumnValues(targetSheetIndex, row, rowCount, headIndex);
        const previousCategoryValues = this.workbookManager.getColumnValues(targetSheetIndex, row, rowCount, categoriesColIndex);
        const previousSubCategoryValues = this.workbookManager.getColumnValues(targetSheetIndex, row, rowCount, subCategoriesColIndex);
        const undoState = {
          previousSerialNumberValues, previousHeadValues, previousCategoryValues, previousSubCategoryValues
        };
        const targetSheetColCount = this.workbookManager.getColumnCount(targetSheetData);
        const sourceSheetColCount = this.workbookManager.getColumnCount(sourceSheetData);
        const columnCount = { targetSheetColCount, sourceSheetColCount };
        const columnIndex = {
          serialNumberIndex, lineItemIndex, headIndex, categoriesColIndex, subCategoriesColIndex
        };
        if (col !== -1) {
          return;
        }

        this.workbookManager.workbook.commandManager().execute({
          cmd: 'reverseSignToRow',
          isUndo: false,
          activeSheetIndex,
          undoState,
          selection,
          columnCount,
          columnIndex
        });
      });
    }
  }

  registerReverseRowSignCommand() {
    const runCommand = (options) => {
      const {
        selection: {
          row, col, rowCount, colCount
        },
        columnCount: { sourceSheetColCount, targetSheetColCount },
        undoState: {
          previousSerialNumberValues, previousHeadValues, previousCategoryValues, previousSubCategoryValues
        },
        columnIndex: {
          serialNumberIndex, headIndex, categoriesColIndex, subCategoriesColIndex
        },
        activeSheetIndex,
        isUndo
      } = options;
      if (col === -1) {
        this.workbookManager.suspend();
        for (let i = 0; i < rowCount; i++) {
          const rowIndex = row + i;
          for (let j = 0; j < colCount; j++) {
            const colIndex = col + j;
            const targetValue = this.workbookManager.getCellValue(targetSheetIndex, rowIndex, colIndex);
            if (colIndex !== serialNumberIndex) { // ignoring serial number  
              if (!isNil(targetValue) && isNumber(targetValue)) {
                this.setValue(activeSheetIndex, rowIndex, col + j, targetValue * -1);
              }
            }
          }
          const style = isNil(this.workbookManager.getSheet(activeSheetIndex).getRange(rowIndex, col, 1, colCount)['foreColor']()) ? 'red' : undefined;
          this.workbookManager.setCellRangeAttr(activeSheetIndex, rowIndex, col, 1, colCount, 'foreColor', style);
          this.onTargetHeadValueChange(rowIndex, isUndo ? previousHeadValues[i] : '', sourceSheetColCount, targetSheetColCount);
          const serialNumberValue = this.workbookManager.getCellValue(activeSheetIndex, rowIndex, serialNumberIndex);
          this.workbookManager.setCellValue(activeSheetIndex, rowIndex, headIndex, isUndo ? previousHeadValues[i] : '');
          this.workbookManager.setCellValue(activeSheetIndex, rowIndex, categoriesColIndex, isUndo ? previousCategoryValues[i] : '');
          this.workbookManager.setCellValue(activeSheetIndex, rowIndex, subCategoriesColIndex, isUndo ? previousSubCategoryValues[i] : '');

          if (isUndo) {
            this.rowReverseMapping[previousSerialNumberValues[i]] = !this.rowReverseMapping[previousSerialNumberValues[i]];
          } else if (this.rowReverseMapping?.hasOwnProperty(serialNumberValue)) {
            this.rowReverseMapping[serialNumberValue] = !this.rowReverseMapping[serialNumberValue];
          } else {
            this.rowReverseMapping = { ...this.rowReverseMapping, [serialNumberValue]: true };
          }
        }
      }
      this.operatingStatementEvents();
      this.workbookManager.resume();
    };

    const undoCommand = (options) => {
      options.isUndo = true;
      runCommand(options);
    };

    this.workbookManager.registerCustomCommand('reverseSignToRow', runCommand, undoCommand);
  }

  setRowReverseMappingsToStore() {
    this.workbookManager.suspend();
    if (this.isWorkbookHasOSTypeDoc()) {
      const mappedRowReverseValues = [];
      const mappedRowReverse = Reflect.ownKeys(this.rowReverseMapping);
      const filteredRowReverseMapping = mappedRowReverse.length ? mappedRowReverse.filter(row => this.rowReverseMapping[row]) : [];
      const serialNumberIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'S.N.').foundColumnIndex;
      const lineItemIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Line Item').foundColumnIndex;
      const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
      const categoriesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
      const subCategoriesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
      if (filteredRowReverseMapping.length) {
        for (let row = 0; row < filteredRowReverseMapping.length; row++) {
          const { foundRowIndex } = this.workbookManager.customSearchWithColumnValue(targetSheetIndex, serialNumberIndex, Number(filteredRowReverseMapping[row]));
          if (foundRowIndex !== -1) {
            const lineItem = this.workbookManager.getCellValue(targetSheetIndex, foundRowIndex, lineItemIndex);
            const head = this.workbookManager.getCellValue(targetSheetIndex, foundRowIndex, headIndex);
            const category = this.workbookManager.getCellValue(targetSheetIndex, foundRowIndex, categoriesColIndex);
            const subCategory = this.workbookManager.getCellValue(targetSheetIndex, foundRowIndex, subCategoriesColIndex);
            mappedRowReverseValues.push({
              lineItem, head, category, subCategory
            });
          }
        }
      }

      this.options.store.dispatch(setRowReverseMapping(
        mappedRowReverseValues
      ));
    }
    this.workbookManager.resume();
  }

  setShowHideMonthColumnsOnLoad() {
    if (this.isWorkbookHasOSTypeDoc()) {
      this.workbookManager.suspend();
      const sheet = this.workbookManager.getSheet(targetSheetIndex);
      let isUsedMonthColumn = false;
      if (sheet) {
        const months = map(this.allColumnList.filter(month => !isNil(month) && moment(month.headerName, "MMMM YYYY")._isValid && month), 'headerName');
        const filteredMonths = months.length >= 3 ? months.concat('Total') : months;
        const monthsToHide = numberOfOSMonthsToHideSelector(this.options.store.getState());
        if (months.length < monthsToHide) {
          filteredMonths.forEach(month => {
            const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, month);
            sheet.setColumnWidth(foundColumnIndex, 0);
            sheet.setColumnResizable(foundColumnIndex, false, GC.Spread.Sheets.SheetArea.colHeader);
          });
          isUsedMonthColumn = false
        } else {
          filteredMonths.forEach(month => {
            const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, month);
            sheet.setColumnWidth(foundColumnIndex, 130);
            sheet.setColumnResizable(foundColumnIndex, true, GC.Spread.Sheets.SheetArea.colHeader);
          });
          isUsedMonthColumn = true
        }
        this.options.store.dispatch(setUseMonthlyData(isUsedMonthColumn));
      }

      this.workbookManager.resume();
    }
  }

  showHideMonthColumns() {
    this.workbookManager.suspend();
    if (this.isWorkbookHasOSTypeDoc()) {
      const sheet = this.workbookManager.getSheet(targetSheetIndex);
      let isUsedMonthColumn = false;
      if (sheet) {
        const months = map(this.allColumnList.filter(month => !isNil(month) && moment(month.headerName, "MMMM YYYY")._isValid && month), 'headerName');
        const filteredMonths = months.length >= 3 ? months.concat('Total') : months;
        filteredMonths.forEach(month => {
          const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, month);
          if (foundColumnIndex !== -1) {
            const columnWidth = sheet.getColumnWidth(foundColumnIndex, GC.Spread.Sheets.SheetArea.viewport);
            if (columnWidth > 0) {
              sheet.setColumnWidth(foundColumnIndex, 0);
              sheet.setColumnResizable(foundColumnIndex, false, GC.Spread.Sheets.SheetArea.colHeader);
              month !== 'Total' && (isUsedMonthColumn = false);
            } else {
              sheet.setColumnWidth(foundColumnIndex, 130);
              sheet.setColumnResizable(foundColumnIndex, true, GC.Spread.Sheets.SheetArea.colHeader);
              month !== 'Total' && (isUsedMonthColumn = true);
            }
          }
        });
        this.options.store.dispatch(setUseMonthlyData(isUsedMonthColumn));
      }
    }

    this.workbookManager.resume();
  }

  onTargetHeadValueChange(row, editingText, sourceColCount = -1, targetSheetColCount = -1, categoryValue = '', subCategoryValue = '') {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(targetSheetIndex);
    const id = sheet.getCell(row, 0).value();
    const defaultCategoryValue = this.updateCategoryCombo(row, editingText);
    const defaultSubCategoryValue = this.updateSubCategoryCombo(row, editingText, defaultCategoryValue);
    const categoryColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
    const subCategoryColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
    this.workbookManager.updateRowBackColorForColumnValue(sourceSheetIndex, 0, id, rowColors[editingText], 0, sourceColCount);
    this.workbookManager.setCellRangeAttr(targetSheetIndex, row, 0, 1, targetSheetColCount, 'backColor', rowColors[editingText]);
    this.workbookManager.setCellValue(targetSheetIndex, row, categoryColumnIndex, editingText === '' ? '' : categoryValue || defaultCategoryValue);
    this.workbookManager.setCellValue(targetSheetIndex, row, subCategoryColumnIndex, editingText === '' ? '' : subCategoryValue || defaultSubCategoryValue);
    const updatedCategoryValue = categoryValue.length ? categoryValue : defaultCategoryValue;
    this.setSubCategoryDropdown(row, subCategoryColumnIndex, editingText, updatedCategoryValue);
    this.workbookManager.resume();
  }

  onTargetCategoryValueChange(row, head, category) {
    const defaultSubCategoryValue = this.updateSubCategoryCombo(row, head, category);
    const subCategoryColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
    this.workbookManager.setCellValue(targetSheetIndex, row, subCategoryColumnIndex, defaultSubCategoryValue);
  }

  updateCategoryCombo(row, head) {
    const categoryColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
    setTimeout(() => this.workbookManager.setCellRangeAttr(targetSheetIndex, row, categoryColumnIndex, 1, 1, 'backColor', rowColors[head]), 0);
    const defaultCategoryValue = this.setCategoryDropdown(row, categoryColumnIndex, head);
    return defaultCategoryValue;
  }

  updateSubCategoryCombo(row, head, category) {
    const subCategoryColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
    const defaultSubCategoryValue = this.setSubCategoryDropdown(row, subCategoryColumnIndex, head, category);
    setTimeout(() => this.workbookManager.setCellRangeAttr(targetSheetIndex, row, subCategoryColumnIndex, 1, 1, 'backColor', rowColors[head]), 0);
    return defaultSubCategoryValue;
  }

  setCategoryDropdown(row, categoryColumnIndex, head) {
    const headCategories = this.options.projectTemplateHeadCategories[head] || [];
    let categories = headCategories;
    if (!Array.isArray(headCategories) && typeof headCategories === 'object') {
      categories = Reflect.ownKeys(headCategories).sort();
    } else {
      categories = headCategories.sort();
    }
    this.setDropDownList(targetSheetIndex, row, categoryColumnIndex, categories);
    return 'Select Category';
  }

  setSubCategoryDropdown(row, subCategoryColumnIndex, head = null, category = null) {
    let subCategories = [];
    if (subCategoryColumnIndex !== -1) {
      if (head !== null) {
        const headCategories = this.options.projectTemplateHeadCategories[head] || [];
        if (!Array.isArray(headCategories) && typeof headCategories === 'object') {
          subCategories = headCategories[category] ? headCategories[category].sort() : [];
        }
      }

      this.setDropDownList(targetSheetIndex, row, subCategoryColumnIndex, subCategories);
      return 'Select Sub Category';
    }
  }

  onTargetIdValueChange(idValue, sourceColCount = -1) {
    this.workbookManager.updateRowBackColorForColumnValue(sourceSheetIndex, 0, idValue, rowColors.matchedRow, 0, sourceColCount);
  }

  getSheetData() {
    const { sheets } = this.workbookManager.getWorkbookData();
    const targetSheetData = sheets[targetSheetName];
    const sourceSheetData = sheets[sourceSheetName];
    return { targetSheetData, sourceSheetData };
  }

  getSavedSummarySheetData() {
    if (this.isWorkbookHasOSTypeDoc()) {
      this.workbookManager.suspend();
      const savedSummaryData = [];
      const workbookData = workbookDataSelector(this.options.store.getState());
      if (!isNil(workbookData)) {
        const { sheets: { Summary: { data: { dataTable } } } } = workbookData;
        for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
          if (!isNil(dataTable[i])) {
            savedSummaryData.push({
              head: !isNil(dataTable[i][0]) && dataTable[i][0]?.hasOwnProperty('value') ? dataTable[i][0].value : '',
              category: !isNil(dataTable[i][1]) && dataTable[i][1]?.hasOwnProperty('value') ? dataTable[i][1].value : '',
              isCustomAdjustment: !(!isNil(dataTable[i][3]) && dataTable[i][3]?.hasOwnProperty('formula')),
              isCommentAvailable: !!(!isNil(dataTable[i][4]) && dataTable[i][4]?.hasOwnProperty('value')),
              commentValue: !isNil(dataTable[i][4]) && dataTable[i][4]?.hasOwnProperty('value') ? dataTable[i][4].value : ''
            });
          } else {
            break;
          }
        }
      }
      this.workbookManager.resume();
      return savedSummaryData;
    }
  }

  getChargeCodeSummary() {
    const chargeCodeConfig = Object.values(chargeCodeConfigSelector(this.options.store.getState()));
    
    return chargeCodeConfig.map(chargeCode => (
      {
        'Charge Code': chargeCode?.key ? validateChargeCode(chargeCode?.key) : '',
        Amount: chargeCode?.total || 0,
        Category: chargeCode?.category || '',
      }
    ));
  }

  getUnitStatusSummary() {
    const unitStatusConfig = currentDocumentUnitStatusSelector(this.options.store.getState());
    return Reflect.ownKeys(unitStatusConfig).map((unitStatus, index) => {
      const marketRent = unitStatusConfig[unitStatus]?.marketRentTotal;
      const monthlyRent = unitStatusConfig[unitStatus]?.monthlyRentTotal;
      const { occupancyCodeHeader, marketRentHeader, monthlyRentHeader, unitsHeader } = unitStatusSummaryHeader;
      return ({
        [occupancyCodeHeader]: unitStatus, 
        [marketRentHeader]: isNumber(marketRent) ? marketRent : 0,
        [monthlyRentHeader]: isNumber(monthlyRent) ? monthlyRent : 0,
        [unitsHeader]: unitStatusConfig[unitStatus].count
      })
    })
  }

  getFloorPlanWithRentDetails(floorPlanSummary) {
    return Reflect.ownKeys(floorPlanSummary).map(summaryKey => {
      let floorPlan = floorPlanSummary[summaryKey];
      let percentOfMarketRent = floorPlan?.occupiedMarketRent ? +(floorPlan?.occupiedMonthlyRent*100 / floorPlan?.occupiedMarketRent).toFixed(2) : 0;
      let key = summaryKey;
      floorPlan = {
        ...floorPlanSummary[summaryKey],
        percentOfMarketRent,
        key
      }
      let floorPlanWithRentDetails = {}
      Reflect.ownKeys(floorPlanWithRentDetailsHeader).map((detailKey) => {
        floorPlanWithRentDetails= {
          ...floorPlanWithRentDetails,
          [floorPlanWithRentDetailsHeader[detailKey]]: floorPlan[detailKey]
        }
      })
      return floorPlanWithRentDetails;
    });
  }

  getFloorPlanWithUnitDetails(floorPlanSummary, totalFloorPlanUnit) {
    return Reflect.ownKeys(floorPlanSummary).map(summaryKey => {
      let floorPlan = floorPlanSummary[summaryKey];
      const { occupiedUnitCount, vacant, totalUnitCount, renovationConfig } = floorPlan;
      const key = summaryKey;
      const percentOfTotalUnits = (totalUnitCount * 100 / totalFloorPlanUnit).toFixed(2);
      const percentageOccupied = (occupiedUnitCount * 100 / totalUnitCount).toFixed(2);
      const percentageVacant = (vacant * 100 / totalUnitCount).toFixed(2);
      const nonRevenue = totalUnitCount - (occupiedUnitCount + vacant);
      const percentageNonRevenue = (nonRevenue * 100 / totalUnitCount).toFixed(2);
      const renovatedCount = renovationConfig.renovated;
      const down = floorPlan?.down ? floorPlan.down : 0;
      const unRenovated = totalUnitCount - (renovatedCount + down);
      floorPlan = {
        ...floorPlanSummary[summaryKey],
        totalUnitCount,
        percentageOccupied,
        percentageVacant,
        nonRevenue,
        percentageNonRevenue,
        down,
        renovatedCount,
        unRenovated,
        percentOfTotalUnits,
        key
      }
      let floorPlanWithUnitDetails = {};
      Reflect.ownKeys(floorPlanWithUnitDetailsHeader).map((detailKey) => {
        floorPlanWithUnitDetails= {
          ...floorPlanWithUnitDetails,
          [floorPlanWithUnitDetailsHeader[detailKey]]: floorPlan[detailKey]
        }
      })
      return floorPlanWithUnitDetails
    });
  }

  getTotalFloorPlanUnit(floorPlanSummary) {
    return Reflect.ownKeys(floorPlanSummary).reduce((acc, key) => acc + floorPlanSummary[key].totalUnitCount, 0);
  }

  setPropertyAndAsOnDateProperties(sheet, asOnDateValue, propertyName) {
    const spreadNS = GC.Spread.Sheets;
    const sheetHeaderObjWithValues = [
      ...sheetHeaderObj,
      { row: 1, col: 2, value: propertyName },
      { row: 2, col: 2, value: asOnDateValue },
    ];
    sheetHeaderObjWithValues.map(obj => this.updateCellValueAndStyles(sheet, obj.row, obj.col, obj.value));
    const sheetTitleKeys = sheet.getRange(1,1,2,1);
    const sheetTitleValues = sheet.getRange(1,2,2,1);
    sheet.addSpan(0, 1, 1, 2);
    const cell = sheet.getCell(0,1);
    cell.backColor(headerBgColor);
    cell.foreColor(textColor);
    cell.hAlign(spreadNS.HorizontalAlign.center);
    sheetTitleKeys.backColor(tableHeaderBgColor);
    sheetTitleValues.backColor(propertyInfoBgColor);
  }

  updateCellValueAndStyles(sheet, row, col, cellValue) {
    const spreadNS = GC.Spread.Sheets;
    const cell = sheet.getCell(row, col);
    cell.value(cellValue).font('normal normal 15px normal');
    cell.hAlign(spreadNS.HorizontalAlign.left);
    cell.foreColor(black);
  }

  mergeAndUpdateCell(sheet, row, col, rowCount, colCount, cellValue, rowStyle, columnCount) {
    const spreadNS = GC.Spread.Sheets;
    sheet.addSpan(row, col, rowCount, colCount);
    let cell = sheet.getCell(row, col);
    cell.value(cellValue)
    cell.hAlign(spreadNS.HorizontalAlign.center);
    rowStyle.hAlign = spreadNS.HorizontalAlign.center;
    sheet.getRange(row, 1, 1, columnCount).setStyle(rowStyle);
  }

  clearAllSpans(sheet) {
    const spans =sheet.getSpans();
    for(let i=0;i<spans.length;i++) {
      sheet.removeSpan(spans[i].row, spans[i].col, GC.Spread.Sheets.SheetArea.viewport);
    }
  }

  avgOfTotalUnits(data, key, floorPlanSummary) {
    let totalFloorPlanUnit = 0;
    const { marketRent, lastFiveLease, occupiedUnits, totalUnits, floorPlan,
      last90DaysLeaseAvg, last60DaysLeaseAvg, last30DaysLeaseAvg } = occupiedRentAvg;
    const totalSum = sumBy(data, (item) => {
      let unitCount;
      if(key === marketRent) {
        unitCount = floorPlanSummary[item[floorPlan]].totalUnitCount;
        totalFloorPlanUnit += unitCount;
      } else if(key === lastFiveLease) {
        unitCount = floorPlanSummary[item[floorPlan]].recentFiveLeaseCount;
        totalFloorPlanUnit += unitCount;
      } else if(key === last90DaysLeaseAvg) {
        unitCount = floorPlanSummary[item[floorPlan]].last90Days;
        totalFloorPlanUnit += unitCount;
      } else if(key === last60DaysLeaseAvg) {
        unitCount = floorPlanSummary[item[floorPlan]].last60Days;
        totalFloorPlanUnit += unitCount;
      } else if(key === last30DaysLeaseAvg) {
        unitCount = floorPlanSummary[item[floorPlan]].last30Days;
        totalFloorPlanUnit += unitCount;
      } else {
        unitCount = !isUndefined(item[occupiedUnits]) ? item[occupiedUnits] : item[totalUnits];
        totalFloorPlanUnit += unitCount;
      }
      return item[key] ? parseFloat((`${item[key]}`).includes('$') ? item[key].replace(/[$,]/g, '')  : item[key]) * unitCount : 0
    });
    return totalFloorPlanUnit ? totalSum / totalFloorPlanUnit : 0;
  }

  setRRSummarySheetData(isFirstTimeMount = false) {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const floorPlanSummary = floorPlanSummarySelector(this.options.store.getState());
      const totalFloorPlanUnit = this.getTotalFloorPlanUnit(floorPlanSummary);
      const chargeCodeSummary = this.getChargeCodeSummary();
      const unitStatusSummary = this.getUnitStatusSummary();
      const floorPlanWithRentDetails = this.getFloorPlanWithRentDetails(floorPlanSummary);
      const floorPlanWithUnitDetails = this.getFloorPlanWithUnitDetails(floorPlanSummary, totalFloorPlanUnit);
      let occupiedMonthlyRentAvg = 0;
      let occupiedMarketRentAvg = 0;

      const summarySheetHeaderMapping = {
        chargeCode: {
          title: chargeCodeSummayTitle,
          data: chargeCodeSummary,
          columnCount: 3,
          currencyColumnIndexes: [2],
          numberColumnIndexes: [],
          mergeColConfig: [],
          footerConfig: chargeCodeSummaryFooterConfig,
          titleHAlign: 'center'
        },
        unitStatus: {
          title: unitStatusSummaryTitle,
          data: unitStatusSummary,
          columnCount: 4,
          currencyColumnIndexes: [2, 3],
          mergeColConfig: [],
          numberColumnIndexes: [4],
          footerConfig: unitStatusSummaryFooterConfig,
          titleHAlign: 'center'
        },
        floorPlanWithRentDetails: {
          title: floorPlanWithRentDetailsTitle,
          data: floorPlanWithRentDetails,
          columnCount: 14,
          currencyColumnIndexes: [2, 4, 5, 8, 10, 12, 14],
          numberColumnIndexes: [3, 6, 7, 9, 11, 13],
          mergeColConfig: floorPlanWithRentDetailsGroupedCol,
          footerConfig: floorPlanWithRentDetailsFooterConfig,
          titleHAlign: 'left'
        },
        floorPlanWithUnitDetails: {
          title: '',
          data: floorPlanWithUnitDetails,
          columnCount: 15,
          currencyColumnIndexes: [],
          numberColumnIndexes: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
          mergeColConfig: floorPlanWithUnitDetailsGroupedCol,
          footerConfig: floorPlanWithUnitDetailsFooterConfig
        }
      }

      const asOnDateValue = moment(get(currentDocumentTaggingInfoSelector(this.options.store.getState()), 'asOnDate')).format("YYYY-MM-DD");
      const propertyName = currentProjectSelector(this.options.store.getState())?.name;
      const sheet = this.workbookManager.getSheet(rrSheetIndex.summarySheetIndex);
      let spreadNS = GC.Spread.Sheets;
      sheet.getRange(-1, -1, 1, -1, spreadNS.SheetArea.viewport).clear(spreadNS.StorageType.style);
      sheet.clear(-1, -1, -1, -1, spreadNS.SheetArea.viewport, spreadNS.StorageType.data);
      this.clearAllSpans(sheet);
      const rowStyle = new spreadNS.Style();
      rowStyle.backColor = tableHeaderBgColor;
      rowStyle.foreColor = black;
      this.setPropertyAndAsOnDateProperties(sheet, asOnDateValue, propertyName);
      let startRowIndex = 5;
      sheet.setColumnWidth(0, 50);

      Reflect.ownKeys(summarySheetHeaderMapping).map(key => {
        const { title, data, columnCount, currencyColumnIndexes, mergeColConfig, footerConfig, numberColumnIndexes, titleHAlign } = summarySheetHeaderMapping[key];
        const tableTop = startRowIndex;
        let table = sheet.tables.findByName(`${key}Summary`);
        table && sheet.tables.remove(table);

        if (data.length) {
          // Add rows according to data length
          isFirstTimeMount && this.workbookManager.addRows(rrSheetIndex.summarySheetIndex, startRowIndex, data.length);
        
          if (title) {
            sheet.addSpan(startRowIndex, 1, 2, columnCount);
            const cell = sheet.getCell(startRowIndex, 1);
            cell.backColor(headerBgColor);
            cell.foreColor(textColor);
            cell.value(title).font('normal normal 16px normal');
            cell.hAlign(spreadNS.HorizontalAlign[titleHAlign]);
            cell.vAlign(spreadNS.HorizontalAlign.center);
            startRowIndex += 2;
          }
          if (!isEmpty(mergeColConfig)) {
            mergeColConfig.map(mergeCol => {
              const { col, colCount, mergeColTitle } = mergeCol
              this.mergeAndUpdateCell(sheet, startRowIndex, col, 1, colCount, mergeColTitle, rowStyle, columnCount);
            })
            startRowIndex += 1;
          }

          for (let col = 1; col <= columnCount; col++) {
            sheet.setColumnWidth(col, 200);
          }

          for (let row = startRowIndex; row < startRowIndex + data.length + 3; row++) {
            for (let col = 0; col <= columnCount; col++) {
              const cell = sheet.getCell(row, col);
              cell.cellPadding('0 0 2 2');
              if (numberColumnIndexes.includes(col)) {
                sheet.setFormatter(row, col, '#,##0.00');
              } else if (currencyColumnIndexes.includes(col)) {
                sheet.setFormatter(row, col, USCurrencyFormat);
              }
              else {
                cell.hAlign(spreadNS.HorizontalAlign.left);
              }
            }
          }

          rowStyle.hAlign = spreadNS.HorizontalAlign.left;
          sheet.getRange(startRowIndex, 1, 1, columnCount).setStyle(rowStyle);
          this.workbookManager.updateCellColor(sheet, startRowIndex, columnCount, black);
          table = sheet.tables.addFromDataSource(`${key}Summary`, startRowIndex, 1, data, spreadNS.Tables.TableThemes.light18);
          startRowIndex += data.length + 1;
          const tableRows = startRowIndex - tableTop + 1;
          table.showFooter(true);

          if (!isEmpty(footerConfig)) {
            footerConfig.map(config => {
              const { colKey, type, col } = config;
              const { colSum, colAvg, colPercentage, percentOfMarketRent } = summarySheetFooterOperations;
              const { marketRentAvg, monthlyRentAvg } = occupiedRentAvg;
              if (type === colSum) {
                const totalSum = getSumbyGroup(data, colKey) ?? 0
                table.setColumnFormula(col, `=VALUE(${totalSum})`)
              } else if (type === colAvg) {
                const totalAvg = this.avgOfTotalUnits(data, colKey, floorPlanSummary).toFixed(2);
                if (colKey === marketRentAvg) {
                  occupiedMarketRentAvg = totalAvg;
                } else if (colKey === monthlyRentAvg) {
                  occupiedMonthlyRentAvg = totalAvg;
                }
                const totalAvgInUsFormat = totalAvg ?? 0
                table.setColumnFormula(col, `=VALUE(${totalAvgInUsFormat})`);
              } else if (type === colPercentage) {
                let percentage = (getSumbyGroup(data, colKey) * 100 / totalFloorPlanUnit).toFixed(2);
                percentage = isNaN(percentage) ? 0 : percentage;
                table.setColumnValue(col, percentage);
              } else if (type === percentOfMarketRent) {
                let percentage = 0;
                const isValidNumber = isNumber(parseFloat(occupiedMarketRentAvg)) && isNumber(parseFloat(occupiedMonthlyRentAvg));
                if (occupiedMarketRentAvg && occupiedMonthlyRentAvg && isValidNumber) {
                  percentage = (occupiedMonthlyRentAvg * 100 / occupiedMarketRentAvg).toFixed(2);
                  percentage = isNaN(percentage) ? 0 : percentage;
                }
                table.setColumnValue(col, percentage);
              } else if (type) {
                table.setColumnValue(col, type)
              }
              if (currencyColumnIndexes.includes(col + 1)) {
                sheet.setFormatter(startRowIndex, col + 1, USCurrencyFormat);
              } else if (numberColumnIndexes.includes(col + 1)) {
                sheet.getCell(startRowIndex, col + 1).hAlign(spreadNS.HorizontalAlign.right);
              }
            })
            const range = sheet.getRange(startRowIndex, 1, 1, columnCount);
            range.backColor(footerBgColor);
          }
          const tableRange = sheet.getRange(tableTop, 1, tableRows, columnCount);
          tableRange.setBorder(new GC.Spread.Sheets.LineBorder(borderColor, GC.Spread.Sheets.LineStyle.thin), { all: true }, 4);
          startRowIndex = key === 'floorPlanWithRentDetails' ? startRowIndex + 2 : startRowIndex + 5;
        }
      })
      this.workbookManager.resume();
    }
  }

  filterFutureTenants() {
    this.workbookManager.suspend();
    const taggingInfo = currentDocumentTaggingInfoSelector(this.options.store.getState());
    const asOnDate = get(taggingInfo, 'asOnDate');
    const sheet = this.workbookManager.getSheet(targetSheetIndex);
    const rowCount = sheet.getRowCount();
    const { targetSheetData } = this.getSheetData();
    const moveInDateColIndex = this.workbookManager.getColumnIndexByName(targetSheetData, 'Move In Date');
    const startDateColIndex = this.workbookManager.getColumnIndexByName(targetSheetData, 'Start Date');
    for(let row = 1; row <= rowCount; row++) {
      const moveInDate = this.workbookManager.getCellValue(targetSheetIndex, row, moveInDateColIndex);
      const startDate = this.workbookManager.getCellValue(targetSheetIndex, row, startDateColIndex);
      const leaseStartDate = startDate || moveInDate || null;
      const futureTenantPresent =
      leaseStartDate &&
      moment(asOnDate).isBefore(
        moment(leaseStartDate)
      );
      if(futureTenantPresent) {
        sheet.deleteRows(row, 1);
        row = row - 1;
      }
    }
    this.workbookManager.resume();
  }

  setSummarySheetData(sheetIndex) {
    if (this.isWorkbookHasOSTypeDoc()) {
      this.workbookManager.suspend();
      const sheet = this.workbookManager.getSheet(sheetIndex);
      this.workbookManager.addAdditionalRowsColumns(sheetIndex, 500, 100);
      sheet.getRange(-1, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).clear(GC.Spread.Sheets.StorageType.style);
      const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
      const categoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
      let subCategoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
      const amountIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Amount').foundColumnIndex;
      const adjustmentsIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Adjustments').foundColumnIndex;
      const notesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Notes').foundColumnIndex;
      const rowCount = this.workbookManager.getSheetRowCount(targetSheetIndex);
      const amountSumRangeByCategory = []
      const colAlphabetIndex = colIndex => this.workbookManager.toAlphabetColumnName(colIndex + 1);
      const targetRange = `${colAlphabetIndex(categoryIndex)}1:${colAlphabetIndex(categoryIndex)}${rowCount}`;
      const targetSubCategoryRange = `${colAlphabetIndex(subCategoryIndex)}1:${colAlphabetIndex(subCategoryIndex)}${rowCount}`;
      const targetConditionRange = `${colAlphabetIndex(headIndex)}1:${colAlphabetIndex(headIndex)}${rowCount}`;
      const targetSumRange = `${colAlphabetIndex(amountIndex)}1:${colAlphabetIndex(amountIndex)}${rowCount}`;
      const targetAdjustmentsSumRange = `${colAlphabetIndex(adjustmentsIndex)}1:${colAlphabetIndex(adjustmentsIndex)}${rowCount}`;
      const targetSheetNotesRange = `${colAlphabetIndex(notesColIndex)}1:${colAlphabetIndex(notesColIndex)}${rowCount}`;
      // To hide sub-category column, we had kept column width to 0 
      const subCategoryColWidth = this.workbookManager.getSheet(targetSheetIndex).getColumnWidth(subCategoryIndex);
      subCategoryIndex = subCategoryColWidth ? subCategoryIndex : -1;

      const categories = this.getUniqueCategories(headIndex, categoryIndex, subCategoryIndex);
      const storedSummaryData = this.getSavedSummarySheetData();
      this.operatingStatementEvents();
      const isAdjustmentColumnAbsent = adjustmentsIndex === -1;
      let updatedSummarySheetHeaders = isAdjustmentColumnAbsent ? summarySheetHeaders.filter((header) => header !== 'Adjustments') : summarySheetHeaders;

      if (notesColIndex == -1) {
        updatedSummarySheetHeaders = updatedSummarySheetHeaders.filter((header) => header !== 'Notes')
      }

      Reflect.ownKeys(categories[0]).length !== 0 && categories.unshift({});


      // Resetting the summary sheet when template is switched or category changed from category sequence.
      if (!!storedSummaryData.length && !!categories.length) {
        if (storedSummaryData.length + 1 !== categories.length) {
          sheet.clear(-1, -1, -1, -1, GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.data);
        }
      }
      
      updatedSummarySheetHeaders.forEach((head, index) => {
        sheet.setValue(sourceSheetIndex, index, head);
        this.workbookManager.setCellStyle(sheetIndex, 0, index, this.summaryStyle.SUMMARY_STATIC_HEADER);
        // this.setColumnReadOnly(sheetIndex, 0, index, 1, 3);
      });

      const formulaExpr = (colTargetRange, head, category, targetSumRange) =>
        `ROUND(SUMIFS('${targetSheetName}'!${targetSumRange},'${targetSheetName}'!${colTargetRange},"${category}", '${targetSheetName}'!${targetConditionRange}, "${head}"),2)`

      const noteFormulaExpr = (colTargetRange, targetSumRange, row) =>
        `TEXTJOIN(";",TRUE,IF($B${row + 1}='${targetSheetName}'!${colTargetRange},IF('${targetSheetName}'!${targetSumRange}<>"", '${targetSheetName}'!${targetSumRange},""),""))`

      if (subCategoryIndex > -1) {
        this.workbookManager.getSheet(sheetIndex).setColumnWidth(1, 350)
      }

      for (let categoryRow = 1; categoryRow < categories.length; categoryRow++) {
        if (isStringType(categories[categoryRow].category)) {
          if (categories[categoryRow].category.trim().length === 0) {
            sheet.setValue(categoryRow, 0, '');
            sheet.setValue(categoryRow, 1, '');
            this.workbookManager.setCellRangeAttr(sheetIndex, categoryRow, 0, 1, 3, 'backColor', undefined);
          } else {
            const { head, category, isSubCategory } = categories[categoryRow];
            sheet.setValue(categoryRow, 0, head);
            //adding an extra space in subcategory to ignore text search for custom summary sheet
            sheet.setValue(categoryRow, 1, isSubCategory ? ` ${category}` : category);

            if (subCategoryIndex > -1) {
              //SubCategories binding here
              const { isSubCategory } = categories[categoryRow];
              if (isSubCategory) {
                sheet.setFormula(categoryRow, 2, formulaExpr(targetSubCategoryRange, head, category, targetSumRange));
                const subCategory = sheet.getCell(categoryRow, 1);
                subCategory.textIndent(2);
              } else {
                sheet.setFormula(categoryRow, 2, formulaExpr(targetRange, head, category, targetSumRange));
                this.workbookManager.setColumnsFont(sheet, categoryRow, [1, 2, 3], 'bold 11pt Calibri');
                const categoryRowIndex = categoryRow + 1;
                const cellIndex = `${colAlphabetIndex(2)}${categoryRowIndex}`
                amountSumRangeByCategory.push({ head, cellIndex, categoryRowIndex });
              }
            } else {
              sheet.setFormula(categoryRow, 2, formulaExpr(targetRange, head, category, targetSumRange));
            }

            if (adjustmentsIndex !== -1) {
              let adjustmentFormulaExpression;
              const findSummaryByHeadCategory = storedSummaryData.length ? storedSummaryData.find(summary => summary.head === categories[categoryRow].head && summary.category === categories[categoryRow].category) : null;
              if (subCategoryIndex > -1) {
                const { isSubCategory } = categories[categoryRow];
                if (isSubCategory) {
                  adjustmentFormulaExpression = formulaExpr(targetSubCategoryRange, head, category, targetAdjustmentsSumRange);
                } else {
                  adjustmentFormulaExpression = formulaExpr(targetRange, head, category, targetAdjustmentsSumRange);
                }
              } else {
                adjustmentFormulaExpression = formulaExpr(targetRange, head, category, targetAdjustmentsSumRange);
              }

              if (!isNil(findSummaryByHeadCategory) && !!Reflect.ownKeys(findSummaryByHeadCategory).length) {
                const { isCommentAvailable, commentValue, isCustomAdjustment } = findSummaryByHeadCategory;
                if (!isCommentAvailable) {
                  sheet.setFormula(categoryRow, 3, adjustmentFormulaExpression);
                  sheet.setValue(categoryRow, 4, commentValue);
                }
                !isCustomAdjustment && sheet.setFormula(categoryRow, 3, adjustmentFormulaExpression);
              }
              if (storedSummaryData.length === 0 || storedSummaryData.length + 1 !== categories.length || isNil(findSummaryByHeadCategory)) {
                sheet.setFormula(categoryRow, 3, adjustmentFormulaExpression);
              }
            }

            if (notesColIndex > 0) {
              let notesFormulaExpression = '';
              if (subCategoryIndex > -1) {
                const { isSubCategory } = categories[categoryRow];
                if (isSubCategory) {
                  notesFormulaExpression = noteFormulaExpr(targetSubCategoryRange, targetSheetNotesRange, categoryRow);
                } else {
                  notesFormulaExpression = noteFormulaExpr(targetRange, targetSheetNotesRange, categoryRow);
                }
              } else {
                notesFormulaExpression = noteFormulaExpr(targetRange, targetSheetNotesRange, categoryRow);
              }
              sheet.setArrayFormula(categoryRow, 5, 1, 1, notesFormulaExpression);
              this.setColumnReadOnly(sheetIndex, categoryRow, 5, 1, 1);
            }

            // this.setColumnReadOnly(sheetIndex, categoryRow, 0, 1, 3);
            let formattedColumnCount = notesColIndex > 0 ? 6 : 5;
            this.workbookManager.setCellFormat(sheetIndex, categoryRow, 2, cellNumberFormat);
            this.workbookManager.setCellFormat(sheetIndex, categoryRow, 3, cellNumberFormat);
            this.workbookManager.setCellRangeAttr(
              sheetIndex,
              categoryRow, 0, 1,
              isAdjustmentColumnAbsent ? formattedColumnCount - 1 : formattedColumnCount,
              'backColor',
              rowColors[capitalizeFirstLetter(categories[categoryRow].head)]);
          }
        }
      }

      if (subCategoryIndex > 0 && sheet.rowOutlines.getMaxLevel() === -1) {
        this.setSummaryRowOutline(sheet, amountSumRangeByCategory, categories.length)
      }

      if (subCategoryIndex === -1) {
        sheet.rowOutlines.ungroup(1, categories.length - 1);
      }

      sheet.getRange(0, 0, categories.length, updatedSummarySheetHeaders.length, GC.Spread.Sheets.SheetArea.viewport)
        .setBorder(new GC.Spread.Sheets.LineBorder("#ababab", GC.Spread.Sheets.LineStyle.thin), { all: true }, 4);
      if (sheetIndex === this.operatingStatementSummarySheetIndex) {
        this.setSummaryTotal(sheet, categories.length, categories, amountSumRangeByCategory);
      } else {
        this.setCustomSummaryTotal(sheet, categories.length)
      }  
      this.workbookManager.resume();
    }
  }

  setSummaryRowOutline(sheet, range, totalCategories) {
    this.workbookManager.suspend();
    const rowRanges = range.map(r => r.categoryRowIndex);
    !!rowRanges.length && rowRanges.forEach((row, i) => {
      const rowCount = rowRanges[i + 1] - row;
      if (!isNaN(rowCount)) {
        sheet.rowOutlines.group(row, rowCount - 1);
      } else {
        sheet.rowOutlines.group(row, totalCategories - row);
      }
      sheet.rowOutlines.expand(row, false);
    })
    sheet.invalidateLayout();
    this.workbookManager.resume();
  }

  setSummaryTotal(sheet, summaryRowCount, categories, amountSumRangeByCategory) {
    const { OS_HEADS } = summaryConstant;
    const tableRowCount = summaryRowCount + 1;
    const style = this.summaryStyle.SUMMARY_TOTAL;
    for (let i = 0; i < OS_HEADS.length; i++) {
      sheet.setValue(tableRowCount + i, 1, OS_HEADS[i].text);
      const filteredByHead = !!amountSumRangeByCategory.length &&
        amountSumRangeByCategory.filter(item => item.head === OS_HEADS[i].key).map(col => col.cellIndex);
      const formulaExpression = amountSumRangeByCategory.length ?
        `ROUND(SUM(${filteredByHead.join()}),2)`
        : `ROUND(SUMIF('${summarySheetName}'!A1:C${tableRowCount},"${OS_HEADS[i].key}",'${summarySheetName}'!C1:C${tableRowCount}),2)`;
      sheet.setFormula(tableRowCount + i, 2, formulaExpression, GC.Spread.Sheets.SheetArea.viewport);
      for (let j = 0; j < 3; j++) {
        sheet.setStyle(tableRowCount + i, j, style);
        this.workbookManager.setCellFormat(this.operatingStatementSummarySheetIndex, tableRowCount + i, j, cellNumberFormat);
      }
    }
    this.setSummaryNOI(sheet, style, tableRowCount + OS_HEADS.length, categories);
  }

  setSummaryNOI(sheet, style, rowIndex, categories) {
    const { OS_NOI_NCF } = summaryConstant;
    const totalIncome = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Income').foundRowIndex;
    const totalExpense = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Expense').foundRowIndex;
    const totalCapex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Capital Expense').foundRowIndex;
    for (let i = 0; i < OS_NOI_NCF.length; i++) {
      sheet.setValue(rowIndex + i, 1, OS_NOI_NCF[i].text);
      if (OS_NOI_NCF[i].key === 'NOI') {
        sheet.setFormula(rowIndex + i, 2, `=C${totalIncome + 1}-C${totalExpense + 1}`);
      } else if (OS_NOI_NCF[i].key === 'NCF') {
        sheet.setFormula(rowIndex + i, 2, `=C${rowIndex + 1}-C${totalCapex + 1}`);
      }/* else if (OS_NOI_NCF[i].key === 'NOI_DIFF') {
        if (documentNOIIndex !== -1) {
          sheet.setFormula(rowIndex + i, 2, `=C${rowIndex + 1}-C${documentNOIIndex + 1}`);
        } else {
          sheet.setValue(rowIndex + i, 1, '');
        }
      }*/

      for (let j = 0; j < 3; j++) {
        sheet.setStyle(rowIndex + i, j, style);
        this.workbookManager.setCellFormat(this.operatingStatementSummarySheetIndex, rowIndex + i, j, cellNumberFormat);
      }
    }

    this.setSummaryExcludedItems(sheet, style, categories);
  }

  filterTemplateByAssetType(templateMapping, projectAssetType) {
    return templateMapping.length ? templateMapping.filter(template => template.assetType === projectAssetType) : [];
  }

  // custom summary sheet total 
  setCustomSummaryTotal(sheet, summaryRowCount) {
    this.workbookManager.suspend();
    const totalConfig = this.options.projectSummarySheetTotalConfig;

    let tableRowCount = summaryRowCount + 1;
    if (totalConfig.length) {
      let previousHeadValue = '';
      totalConfig.forEach((config, rowIndex) => {
        if (config.hasOwnProperty('allowLineBreak')) {
          if (toUpper(config.allowLineBreak) === 'TRUE') {
            tableRowCount = tableRowCount + 1
          }
        } else if (previousHeadValue !== '' && previousHeadValue !== config?.head) {
          tableRowCount = tableRowCount + 1
        }

        sheet.setValue(tableRowCount + rowIndex, 1, config?.label);
        const formulaExpr = trim(config.formula);
        const categories = trim(config.categories);
        let formulaExpression = ''

        if (!!formulaExpr &&  !categories) {
          formulaExpression = config.formula;
          formulaExpression = formulaExpression.replaceAll('B:C', `B${summaryRowCount + 1}:C${summaryRowCount + 50}`);
        }  
        
        if (!formulaExpr && !!categories){
          let cateogriesToSum = []
          if (categories.indexOf(',') > -1) {
            cateogriesToSum = config.categories.split(',')
          } else {
            cateogriesToSum = [config.categories]
          }      
          formulaExpression = `ROUND(SUM(SUMIFS(C1:C${summaryRowCount},A1:A${summaryRowCount},"${config.head}",B1:B${summaryRowCount},{${cateogriesToSum.map(d => `"${d}"`).join()}})),2)`;
        }
        
        sheet.setFormula(tableRowCount + rowIndex, 2, formulaExpression, GC.Spread.Sheets.SheetArea.viewport);

        if (config.label === 'Total Income') {
          const row = tableRowCount + rowIndex;
          const operatingIncomeIndex = `C${row - 2}`;
          const lessOmittedIncomeIndex = `C${row - 1}`;
          const lessBedDebtIndex = `C${row}`;
          const totalForumlaExpr = 
            `=IF(${lessBedDebtIndex}>=0,${operatingIncomeIndex}-${lessOmittedIncomeIndex}-${lessBedDebtIndex},${operatingIncomeIndex}-${lessOmittedIncomeIndex}+${lessBedDebtIndex})`
          sheet.setFormula(tableRowCount + rowIndex, 2, totalForumlaExpr, GC.Spread.Sheets.SheetArea.viewport);
        }

        this.workbookManager.setCellFormat(customSummarySheetIndex, tableRowCount + rowIndex, 2, cellNumberFormat);
        this.setCellFormatToCustomSummartyTotal(sheet, tableRowCount + rowIndex, 2, config)
        previousHeadValue = config?.head
      })
    }
    this.workbookManager.resume();
  }

  setCellFormatToCustomSummartyTotal(sheet, row, col, config) {
    this.workbookManager.suspend();
    if (config.hasOwnProperty('columnFormatType')) {          
      if (config.columnFormatType.trim().length !== 0) {
        const cellFormat = getCellFromatter(config.columnFormatType);
        sheet.getCell(row, col).formatter(cellFormat);
      }
    }
    this.workbookManager.resume();
  }


  setSummaryExcludedItems(sheet, style, categories) {
    const { WITHOUT_EXCLUDED_ITEMS } = summaryConstant;
    let excludedIncomeIndex = -1; let excludedExpenseIndex = -1; let excludedCapexIndex = -1;
    const currentAssetType = this.workbookManager.options.assetType;
    const currentBaseClass = this.options.baseClass;
    const currentProjectTemplateMapping = this.options.projectTemplateMappings;
    const excludedCategories = excludedCategoriesSelector(this.options.store.getState());
    const excludedMappingList = [];

    try {
      if (!isNil(currentProjectTemplateMapping)) {
        const templateMapping = this.filterTemplateByAssetType(currentProjectTemplateMapping, currentAssetType);
        const excludedClikTemplateMapping = this.filterTemplateByAssetType(excludedCategories, currentBaseClass);
        if (templateMapping.length) {
          excludedClikTemplateMapping.forEach(template => {
            const foundMapping = templateMapping.find(projectMapping => compareTwoStrings(projectMapping.clikCategory, template.clikCategory));
            excludedMappingList.push(foundMapping);
          });

          categories.forEach((documentData, rowIndex) => {
            if (!isNil(documentData)) {
              const foundCategory = excludedMappingList.find(mapppingData => compareTwoStrings(mapppingData.head, documentData.head) && compareTwoStrings(mapppingData.category, documentData.category));
              if (!isNil(foundCategory) && !!Reflect.ownKeys(foundCategory).length) {
                const { head } = foundCategory;
                if (compareTwoStrings(head, 'Income')) {
                  excludedIncomeIndex = rowIndex;
                }
                if (compareTwoStrings(head, 'Expense')) {
                  excludedExpenseIndex = rowIndex;
                }
              }
            }
          });
        }
      }
    } catch (e) {
      this.options.setWorkbookToast({
        message: messages.toastMessage.TEMPLATE_MAPPING_ERROR,
        autohide: false,
        type: 'invalid-on-save-datatype',
        wrapperClass: 'spread-validation-error',
        headerMessage: 'Template Mapping Error'
      });
      console.error(e)
    }

    const rowIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Net Cash Flow').foundRowIndex + 2;
    const totalIncomeIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Income').foundRowIndex;
    const totalExpenseIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Expense').foundRowIndex;
    const totalCapexIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Capital Expense').foundRowIndex;

    for (let i = 0; i < WITHOUT_EXCLUDED_ITEMS.length; i++) {
      sheet.setValue(rowIndex + i, 1, WITHOUT_EXCLUDED_ITEMS[i].text);
      sheet.setStyle(rowIndex + i, 1, style);
      this.workbookManager.setCellFormat(this.operatingStatementSummarySheetIndex, rowIndex + i, 2, cellNumberFormat);
      if (WITHOUT_EXCLUDED_ITEMS[i].key === 'header') {
        this.workbookManager.setCellStyle(this.operatingStatementSummarySheetIndex, rowIndex + i, 1, this.summaryStyle.SUMMARY_HEADER);
      } else if (WITHOUT_EXCLUDED_ITEMS[i].key === 'Income') {
        const isExcludedOmittedIncome = excludedIncomeIndex !== -1 ? `=C${totalIncomeIndex + 1}-C${excludedIncomeIndex + 1}` : `=C${totalIncomeIndex + 1}`;
        sheet.setFormula(rowIndex + i, 2, isExcludedOmittedIncome);
      } else if (WITHOUT_EXCLUDED_ITEMS[i].key === 'Expense') {
        const isExcludedOmittedExpense = excludedExpenseIndex !== -1 ? `=C${totalExpenseIndex + 1}-C${excludedExpenseIndex + 1}` : `=C${totalExpenseIndex + 1}`;
        sheet.setFormula(rowIndex + i, 2, isExcludedOmittedExpense);
      } else if (WITHOUT_EXCLUDED_ITEMS[i].key === 'Capital Expense') {
        const isExcludedOmittedCapex = excludedCapexIndex !== -1 ? `=C${totalCapexIndex + 1}-C${excludedCapexIndex + 1}` : `=C${totalCapexIndex + 1}`;
        sheet.setFormula(rowIndex + i, 2, isExcludedOmittedCapex);
      }
    }
    this.setSummaryExcludedItemsNOI(sheet, style);
  }

  setSummaryExcludedItemsNOI(sheet) {
    const totalIncomeIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Income:').foundRowIndex;
    const totalExpenseIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Expense:').foundRowIndex;
    const excludedItemNoiIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Net Operating Income:').foundRowIndex;
    sheet.setFormula(excludedItemNoiIndex, 2, `=C${totalIncomeIndex + 1}-C${totalExpenseIndex + 1}`);
    this.setSummaryExcludedItemsNCF(sheet);
  }

  setSummaryExcludedItemsNCF(sheet) {
    const totalCapexIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Total Capital Expense:').foundRowIndex;
    const excludedItemNoiIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Net Operating Income:').foundRowIndex;
    const excludedItemNcfIndex = this.workbookManager.customSearchWithColumnValue(this.operatingStatementSummarySheetIndex, 1, 'Net Cash Flow:').foundRowIndex;
    sheet.setFormula(excludedItemNcfIndex, 2, `=C${excludedItemNoiIndex + 1}-C${totalCapexIndex + 1}`);
  }

  getUniqueCategories(headIndex, categoryIndex, subCategoryIndex) {
    const isNotCategorySequence = isNil(this.options.projectHeadCategorySequence);
    const headCategoryList = this.getAllHeadCategoryList();
    const rowCount = this.workbookManager.getSheetRowCount(targetSheetIndex);
    const categories = Array(rowCount).fill(0).map((_, i) => {
      const index = i + 1;
      const category = this.workbookManager.getCellValue(targetSheetIndex, index, categoryIndex);
      const subCategory = this.workbookManager.getCellValue(targetSheetIndex, index, subCategoryIndex);
      const head = this.workbookManager.getCellValue(targetSheetIndex, index, headIndex);
      return category ? subCategory ? { head, category, subCategory } : { head, category } : null
    }).filter(x => x);

    const updatedCategoryList = [...categories, ...headCategoryList];
    const keyToPick = subCategoryIndex > 0 ? ['head', 'subCategory'] : ['head', 'category'];
    let uniqByCategorySubCategory = uniqBy(updatedCategoryList, (elem) => JSON.stringify(pick(elem, keyToPick)));
    if (subCategoryIndex > -1) {
      const finalCategorySubCategory = [];
      const groupByCategory = groupBy(uniqByCategorySubCategory, 'category');
      const categoryWithSubCategories = Reflect.ownKeys(groupByCategory).reduce((acc, curr) => {
        acc[curr] = uniqBy(groupByCategory[curr], 'subCategory').map(data => data.subCategory);
        return acc
      }, {});

      Reflect.ownKeys(categoryWithSubCategories).forEach(category => {
        const foundObject = updatedCategoryList.find(item => item.category === category);
        if (foundObject) {
          const { head, category } = foundObject;
          finalCategorySubCategory.push({ head, category, isSubCategory: false })
          const subCategories = categoryWithSubCategories[category];
          if (subCategories.length) {
            subCategories.forEach(subCategory => {
              finalCategorySubCategory.push({ head, category: subCategory, isSubCategory: true })
            })
          }
        }
      });
      uniqByCategorySubCategory = finalCategorySubCategory;
    }

    uniqByCategorySubCategory =
      uniqByCategorySubCategory.sort((a, b) => summarySheetHeadSequence.indexOf(a.head) - summarySheetHeadSequence.indexOf(b.head));

    return isNotCategorySequence ? uniqByCategorySubCategory : headCategoryList;
  }

  getAllHeadCategoryList() {
    const { projectTemplateHeadCategories, projectHeadCategorySequence } = this.options;
    const headCategoryList = isNil(projectHeadCategorySequence) ? projectTemplateHeadCategories : projectHeadCategorySequence;
    const summaryHeadCategoryList = [];
    if (isNil(projectHeadCategorySequence)) {
      Reflect.ownKeys(headCategoryList).map(head => {
        if (Array.isArray(headCategoryList[head])) {
          return headCategoryList[head].map(category => {
            const categoryItem = category.trim().length === 0 ? false : category;
            summaryHeadCategoryList.push({ head, category: categoryItem });
          });
        } else {
          return Reflect.ownKeys(headCategoryList[head]).map(category => headCategoryList[head][category].map(subCategory => {
            const categoryItem = category.trim().length === 0 ? false : category;
            const subCategoryItem = subCategory.trim().length === 0 ? false : subCategory;
            summaryHeadCategoryList.push({ head, category: categoryItem, subCategory: subCategoryItem });
          }));
        }
      });
      return summaryHeadCategoryList.flat();
    } else {
      return projectHeadCategorySequence;
    }
  }

  getUniqueCategoriesWithLineItems() {
    if (this.isWorkbookHasOSTypeDoc()) {
      const rowCount = this.workbookManager.getSheetRowCount(targetSheetIndex);
      const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
      const categoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
      const lineItemIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Line Item').foundColumnIndex;
      const amountIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Amount').foundColumnIndex;
      const targetSheetData = Array(rowCount).fill(0).map((_, i) => {
        const index = i + 1;
        const category = this.workbookManager.getCellValue(targetSheetIndex, index, categoryIndex);
        const head = this.workbookManager.getCellValue(targetSheetIndex, index, headIndex);
        const lineItem = this.workbookManager.getCellValue(targetSheetIndex, index, lineItemIndex);
        const serialNumber = this.workbookManager.getCellValue(targetSheetIndex, index, 0);
        const amount = this.workbookManager.getCellValue(targetSheetIndex, index, amountIndex);
        return category && {
          category, head, lineItem, serialNumber, amount
        };
      }).filter(x => x);

      const sortByHead = sortBy(targetSheetData, 'head').reverse();
      const groupedHeads = groupBy(sortByHead, 'head');

      const totalAmount = (key) => round(sumBy(key, (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount), 2);
      const checkNullValue = (value) => (value !== null ? value : '');

      const summaryDetail = Reflect.ownKeys(groupedHeads).map(head => {
        const heads = groupedHeads[head];
        const incomeTotal = sumBy(groupedHeads['Income'], (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount);
        const groupedCategory = groupBy(heads, 'category');
        const categoryAmountTotal = Object.values(groupedCategory).map(item => sumBy(item, (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount));

        return {
          title: head,
          color: rowColors[head],
          total: round(totalAmount(heads), 2),
          displayTotal: round(totalAmount(heads), 2),
          showEGI: true,
          expanded: true,
          children: Reflect.ownKeys(groupedCategory).map((category, index) => ({
            title: category,
            color: rowColors[head],
            total: categoryAmountTotal[index],
            egi: round((categoryAmountTotal[index] / incomeTotal) * 100, 2),
            displayTotal: round(categoryAmountTotal[index], 2),
            children: groupedCategory[category].map(item => (
              {
                title: `${checkNullValue(item.lineItem)}`,
                displayTotal: !isNaN(item.amount) ? round(item.amount, 2) : 0,
                color: rowColors[head]
              }
            ))
          }))
        };
      });

      const summaryTotal = this.getSummaryTotal(summaryDetail);
      this.options.setSummaryDataToStore({ summaryDetail, summaryTotal });
    }
  }

  getSummaryTotal(summaryData) {
    if (summaryData.length > 0) {
      const SUMMARY_TOTAL = summaryData.map((item) => ({ head: item.title.replace(/ /g, ''), color: item.color, total: round(item.total, 2) }));
      const { Expense, Income, CapitalExpense } = groupBy(SUMMARY_TOTAL, 'head');
      const IncomeTotal = isEmpty(Income) ? 0 : Income[0].total;
      const ExpenseTotal = isEmpty(Expense) ? 0 : Expense[0].total;
      const SUMMARY_NOI = round((IncomeTotal - ExpenseTotal), 2);
      const SUMMARY_NCF = round((SUMMARY_NOI - (isEmpty(CapitalExpense) ? 0 : CapitalExpense[0].total)), 2);
      return { SUMMARY_TOTAL, SUMMARY_NOI, SUMMARY_NCF };
    }
  }

  reCalculateTentativeStatus(rrOccupancyConfig) {
    // Validate only if tentative status column exits.
    if (!this.statusIndex) {
      return false;
    }
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      const { unitStatus: { config } } = rrOccupancyConfig;
      const { statusIndex: tentativeStatusIndex } = this;
      const { targetSheetData: { data: { dataTable } } } = this.getSheetData();
      const statusIndex = this.getColIndex("Status");
      this.workbookManager.suspend();
      for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
        const rowIndex = i;
        const status = this.workbookManager.getCellValue(targetSheetIndex, rowIndex, statusIndex);
        const tentativeStatus = get(config[status], 'category', status);
        this.workbookManager.setCellValue(targetSheetIndex, rowIndex, tentativeStatusIndex, tentativeStatus);
      }
      this.workbookManager.resume();
      // this.validateMonthlyRent();
    }
  }

  validateMonthlyRentOnCellChanged({
    sheet, row, col
  }) {
    // Validate only if tentative status column exits.
    if (!this.statusIndex) {
      return false;
    }
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      const statusIndex = this.getColIndex("Status");
      /**
       * Validate only if changed column is Status, MonthlyRent, MarketRent, Tentative Status, Move out date
       */
      const { statusIndex: tentativeStatusIndex, marketRentIndex, monthlyRentIndex, leaseEndDateIndex } = this;
      if ([statusIndex, tentativeStatusIndex, marketRentIndex, monthlyRentIndex, leaseEndDateIndex].indexOf(col) < 0) {
        return;
      }

      if (statusIndex === col) {
        const { unitStatus: { config } } = mfRentRollConfigSelector(this.options.store.getState());
        const status = this.workbookManager.getCellValue(targetSheetIndex, row, statusIndex);
        const tentativeStatus = get(config[status], 'category', status);
        this.workbookManager.setCellValue(targetSheetIndex, row, tentativeStatusIndex, tentativeStatus);
      }
      const taggingInfo = currentDocumentTaggingInfoSelector(this.options.store.getState());
      const asOnDate = get(taggingInfo, 'asOnDate');

      const status = this.workbookManager.getCellValue(targetSheetIndex, row, tentativeStatusIndex);
      const marketRent = this.workbookManager.getCellValue(targetSheetIndex, row, marketRentIndex);
      const monthlyRent = this.workbookManager.getCellValue(targetSheetIndex, row, monthlyRentIndex);
      const leaseEndDate = this.workbookManager.getCellValue(targetSheetIndex, row, leaseEndDateIndex);

      let shouldHighlightCell = false;
      let errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];

      if (status === statusMap["Occupied"] && monthlyRent <= 1) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];
      }

      if (status === statusMap["Vacant"] && monthlyRent > 0) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_NON_0'];
      }

      if (monthlyRent > 0 && marketRent > 0) {
        if (status === statusMap["Occupied"] && rentDiffPercentage(monthlyRent, marketRent) > cutoffPercentage) {
          shouldHighlightCell = true;
          errorMessageMapObj = errorMessageMap['CUTTOFF_ERROR'];
        }
      }

      if (moment(leaseEndDate).isBefore(asOnDate)) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['ASOFDATE_AFTER_ENDDATE'];
      }

      this.highlightRow(sheet, row, errorMessageMapObj, shouldHighlightCell);
    }

  }

  validateCellOnValueChanged(sheet, row, col, columns, cellValue) {
    if (!isNil(cellValue)) {
      if (typeof cellValue === 'object' && Reflect.ownKeys(cellValue).length > 0) {
        return
      }
    }

    this.workbookManager.suspend();
    // this.validateMonthlyRentOnCellChanged({
    //   sheet, row, col, columns, cellValue
    // });

    try {
      const formulaValue = typeof cellValue === 'string' && GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, cellValue, row, col);
      if (formulaValue && typeof formulaValue !== 'object') {
        cellValue = formulaValue
      }
    } catch (e) { }

    const columnHeaderName = sheet.getCell(0, col).value();
    if (columnHeaderName) {
      const foundColumnName = columns.filter(validCol => validCol).find(column => column.headerName === columnHeaderName);
      if (foundColumnName && foundColumnName?.hasOwnProperty('dataType')) {
        const { dataType } = foundColumnName;
        if (dataType !== 'string') { // ignoring valdation check on string type datatype
          if (!!cellValue || isNumber(cellValue)) {
            const validCellValue = typeof cellValue === 'object' ? moment(new Date(cellValue), 'MM/DD/YYYY') : cellValue;
            const isValidValue = this.validateColumnDataType(dataType, validCellValue);
            if (isValidValue) {
              this.highlightCellValue(sheet, row, col, undefined);
              this.options.setWorkbookToast({ message: '', autohide: true });
            } else {
              this.highlightCellValue(sheet, row, col, invalidCellColor);
              this.options.setWorkbookToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
            }
          } else {
            this.highlightCellValue(sheet, row, col, undefined);
            this.options.setWorkbookToast({ message: '', autohide: true });
          }
          this.setExistingCellFormat(sheet, row, col, dataType, cellValue);
        }
      }
    }
    this.workbookManager.resume();
  }

  categoryCopiedValueRange() {
    if (this.isWorkbookHasOSTypeDoc()) {
      const {
        row, rowCount, col, colCount
      } = this.workbookManager.workbook.getActiveSheet().getSelections()[0];
      const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category', { startCol: col });
      if (foundColumnIndex !== -1 && colCount > 1) {
        this.copiedCategoryValues = this.workbookManager.getColumnValues(targetSheetIndex, row, rowCount, foundColumnIndex);
      } else {
        this.copiedCategoryValues = null;
      }
    }
  }


  subCategoryCopiedValueRange() {
    if (this.isWorkbookHasOSTypeDoc()) {
      const {
        row, rowCount, col, colCount
      } = this.workbookManager.workbook.getActiveSheet().getSelections()[0];
      const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category', { startCol: col });
      if (foundColumnIndex !== -1 && colCount > 1) {
        this.copiedSubCategoryValue = this.workbookManager.getColumnValues(targetSheetIndex, row, rowCount, foundColumnIndex);
      } else {
        this.copiedSubCategoryValue = null;
      }
    }
  }


  onRowColDeletion(isUndo, sheet, row, col, rowCount) {
    if (sheet === this.workbookManager.getSheet(1)) {
      this.workbookManager.suspend();
      if (col < 1) {  // Execute on row deletion
        const { sourceSheetData } = this.getSheetData();
        const sourceSheetColCount = this.workbookManager.getColumnCount(sourceSheetData);
        const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
        for (let i = row; i < rowCount + row; i++) {
          const rowId = this.workbookManager.getCellValue(targetSheetIndex, i, 0);
          const backColor = isUndo ? rowColors[this.workbookManager.getCellValue(targetSheetIndex, i, headIndex)] : undefined;
          this.workbookManager.updateRowBackColorForColumnValue(sourceSheetIndex, 0, rowId, backColor, 0, sourceSheetColCount);
        }
      }
      if (row < 1 && !isUndo) { // Execute on column deletion
        if (this.isWorkbookHasOSTypeDoc()) {
          const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
          const categoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
          const subCategoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
          if (col !== headIndex || col !== categoryIndex || col !== subCategoryIndex) {
            this.setMonthTotalAmount(col); // Always call this method at last to highlight the diffrence value between amount & total
          }
        }
      }
      if (this.isWorkbookHasRentRollTypeDoc()) {
        setTimeout(() => {
          this.onRentRollEventTrigger('onRowColDelete');
        }, 0)
      }
      if (this.isWorkbookHasOSTypeDoc()) {
        setTimeout(() => {
          this.getUniqueCategoriesWithLineItems();
          this.getRentalIncomeInfo();
        }, 0)
      }
      if (this.isWorkbookHasOSFullTypeDoc()) {
        setTimeout(() => {
          this.getCFSummary();
        }, 0)
      }
      this.workbookManager.resume();
    } 
  }

  onRowColInsertion(sheet, row, col, rowCount, colCount) {
    if (sheet === this.workbookManager.getSheet(targetSheetIndex)) {
      if (col < 1) {
        this.workbookManager.suspend();
        const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
        const categoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
        this.copyColumnsFromPreviousRow(sheet, [headIndex, categoryIndex], row, rowCount);
        this.workbookManager.resume();
      } else if (row < 1) {
        this.workbookManager.suspend();
        for (let i = 0; i < colCount; i++) {
          const columnCount = col + colCount;
          const currentColumnStyle = sheet.getStyle(0, columnCount); // fetching style of current selected column.
          const previousColumnStyle = sheet.getStyle(0,  col - 1);
          const cellStyle = !isNil(currentColumnStyle) ? currentColumnStyle : !isNil(previousColumnStyle) ? previousColumnStyle : this.headerStyleMap.DATA;
          sheet.setStyle(sourceSheetIndex, col + i, cellStyle);
          this.applyFormatterOnInsert(sheet, cellStyle, col + i, rowCount);
          sheet.getCell(row, col + i).locked(false);
        }
        this.workbookManager.resume();
      }
    }
  }

  applyFormatterOnInsert(sheet, cellStyle, column, rowCount) {
    if (typeof cellStyle === 'object' && cellStyle.hasOwnProperty('parentName')) {
      const { parentName } = cellStyle;
      if (!isNil(spreadCellFormatter[parentName])) {
        this.workbookManager.suspend();
        const range = sheet.getRange(1, column, rowCount, 1);
        range.formatter(spreadCellFormatter[parentName]);
        this.workbookManager.resume();
      }
    }
  }

  registerUpdateCellBgColorCommand() {
    const runCommand = (options) => {
      const {
        args, previousHeadValues, headIndex, isUndo
      } = options;
      const { sourceSheetData, targetSheetData } = this.getSheetData();
      const {
        cellRange: {
          row, col, rowCount, colCount
        }
      } = args;
      const sourceSheetColCount = this.workbookManager.getColumnCount(sourceSheetData);
      const targetSheetColCount = this.workbookManager.getColumnCount(targetSheetData);
      if (col <= headIndex < col + colCount) {
        this.workbookManager.suspend();
        for (let i = row, count = 0; i < rowCount + row; i++, count++) {
          const cellValue = this.workbookManager.getCellValue(1, i, 0);
          const backColor = isUndo ? rowColors[previousHeadValues[count]] : rowColors[this.workbookManager.getCellValue(targetSheetIndex, i, headIndex)];
          const headValue = isUndo ? previousHeadValues[count] : this.workbookManager.getCellValue(targetSheetIndex, i, headIndex);
          this.workbookManager.updateRowBackColorForColumnValue(targetSheetIndex, 0, cellValue, backColor, 0, targetSheetColCount);
          this.workbookManager.updateRowBackColorForColumnValue(sourceSheetIndex, 0, cellValue, backColor, 0, sourceSheetColCount);
          const categoryValue = isNil(this.copiedCategoryValues) ? '' : (count > this.copiedCategoryValues.length - 1) ? last(this.copiedCategoryValues) : this.copiedCategoryValues[count];
          const subCategoryValue = isNil(this.copiedSubCategoryValue) ? '' : (count > this.copiedSubCategoryValue.length - 1) ? last(this.copiedSubCategoryValue) : this.copiedSubCategoryValue[count];
          // const subCategoryValue = isNil(this.copiedSubCategoryValue) ? '' : this.copiedSubCategoryValue[count];
          this.onTargetHeadValueChange(i, headValue, -1, -1, categoryValue, subCategoryValue);
        }
        this.getUniqueCategoriesWithLineItems();
        this.workbookManager.resume();
      }
    };

    const undoCommand = (options) => {
      options.isUndo = true;
      runCommand(options);
      setTimeout(() => {
        this.getUniqueCategoriesWithLineItems();
        this.workbookManager.undo();
      }, 0);
    };

    this.workbookManager.registerCustomCommand('updateCellColorOnPaste', runCommand, undoCommand);
  }

  copyColumnsFromPreviousRow(sheet, columnIndexes = [], row, rowCount) {
    if (columnIndexes.length > 0) {
      for (let colIndex = 0; colIndex < columnIndexes.length; colIndex++) {
        for (let i = row; i < rowCount + row; i++) {
          /*** If selected row index is 1 then it would pick dropdowns from next row ****/
          const fromRow = row === 1 ? i + rowCount : i - 1; 
          const fromRange = [new GC.Spread.Sheets.Range(fromRow, columnIndexes[colIndex], 1, 1)];
          const toRanges = [new GC.Spread.Sheets.Range(i, columnIndexes[colIndex], 1, 1)];
          this.workbookManager.workbook.commandManager().execute({
            cmd: 'clipboardPaste',
            sheetName: targetSheetName,
            fromSheet: sheet,
            fromRanges: fromRange,
            pastedRanges: toRanges,
            isCutting: false,
            clipboardText: '',
            pasteOption: GC.Spread.Sheets.ClipboardPasteOptions.formatting
          });
        }
      }
    }
  }

  setOutlineToGroupedColumns() {
    const sheet = this.workbookManager.getSheet(targetSheetIndex);
    const { targetSheetData: { columns } } = this.getSheetData();
    this.workbookManager.suspend();
    const rentRollCols = columns.filter(col => col.type && col);
    if (rentRollCols.length > 0 && this.isWorkbookHasRentRollTypeDoc()) {
      if (this.isMultifamilyOrHealthcareDocument()) {
        const chargeCodeStartColIndex = findIndex(rentRollCols, ['type', 'CHARGE_CODE']);
        const staticCCStartColIndex = findIndex(rentRollCols, ['type', 'STATIC_CHARGE_CODE']);
        if (chargeCodeStartColIndex !== -1 && staticCCStartColIndex !== -1) {
          const endColIndex = rentRollCols.length - chargeCodeStartColIndex;
          const chargeCodeEndColumnIndex = chargeCodeStartColIndex - staticCCStartColIndex - 1;
          sheet.columnOutlines.group(staticCCStartColIndex, chargeCodeEndColumnIndex);
          sheet.columnOutlines.group(chargeCodeStartColIndex, endColIndex);
          const sheetOutlines = this.getColumnOutlineLevels(sheet, rentRollCols.length);
          //Expecting two column outlines for Rent Roll (Multifamily Documnet)
          if (sheetOutlines.length === 2) {
            sheet.columnOutlines.expandGroup(sheetOutlines[1], false);
            sheet.invalidateLayout();
          }
        }
      } else if (this.validateDocumentAssetType(PROPERTY_TYPES.RETAIL.baseClass) || this.validateDocumentAssetType(PROPERTY_TYPES.OFFICE.baseClass)) {
        const rentStepStartIndex = findIndex(rentRollCols, ['type', 'RENT_STEP_DATE']);
        if (rentStepStartIndex !== -1) {
          const endColIndex = rentRollCols.length - rentStepStartIndex;
          sheet.columnOutlines.group(rentStepStartIndex, endColIndex);
          const sheetOutlines = this.getColumnOutlineLevels(sheet, rentRollCols.length);
          if (sheetOutlines.length > 0) {
            sheet.columnOutlines.expandGroup(sheetOutlines[0], false);
            sheet.invalidateLayout();
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  getColumnOutlineLevels(sheet, columnCount) {
    this.workbookManager.suspend();
    const outlineLevels = sheet.columnOutlines.getMaxLevel();
    const outlines = [];
    for (let index = 0, i = 0; index <= outlineLevels; index++) {
      for (let col = 0; col < columnCount; col++) {
        // Fetch group information of Outline
        const groupInfo = sheet.columnOutlines.find(col, index);
        if (!isNil(groupInfo)) {
          outlines[i] = groupInfo;
          i++;
          col = groupInfo.end;
        }
      }
    }
    this.workbookManager.resume();
    return outlines;
  }

  columnAlphabetIndex(colIndex) {
    return this.workbookManager.toAlphabetColumnName(colIndex + 1);
  }

  getTargetSheetRowCount() {
    this.workbookManager.suspend();
    let serialNumbers = [];
    const rowCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getRowCount();
    for (let row = 0; row < rowCount; row++) {
      const value = this.workbookManager.getCellValue(targetSheetIndex, row, 0);
      if (!isNil(value)) {
        serialNumbers.push(value);
      }
    }
    this.workbookManager.resume();
    return serialNumbers.length;
  }

  setInitialChargeCode(isRetail = false) {
    const chargeCodes = this.options.chargeCodeConfig;
    const rowCount = this.getTargetSheetRowCount();
    const sheet = this.workbookManager.getSheet(targetSheetIndex);
    const retailUniqeChargeCode = [];
    this.workbookManager.suspend();
    if (Reflect.ownKeys(chargeCodes).length > 0) {
      Reflect.ownKeys(chargeCodes).forEach((colName) => {
        if (isRetail && isBracketExists(colName)) {
          delete chargeCodes[colName];
          return;
        }
        if (isRetail) {
          const chargeCode = chargeCodes[colName];
          let cellValue = '';
          if (chargeCode) {
            cellValue = chargeCode.label ? chargeCode.label : chargeCode.code ? chargeCode.code : '';

            if (!chargeCode.label) {
              chargeCode.label = chargeCode.code ? !retailUniqeChargeCode.includes(cellValue) ? chargeCode.code : '' : '';
            }
          }

          if (!!cellValue && (!retailUniqeChargeCode.includes(cellValue) || checkArrayIncludesValue(ALLOWED_RETAIL_CC_CATEGORY, cellValue))) {
            retailUniqeChargeCode.push(cellValue);
          } else {
            chargeCode.label ? chargeCode.label = "" : chargeCode.code = "";
            chargeCode.unit = "";
          }
        }
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, colName);
        if (
          foundColumnIndex !== -1) {
          const total = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${this.columnAlphabetIndex(foundColumnIndex)}2:${this.columnAlphabetIndex(foundColumnIndex)}${rowCount})`);
          if (isNil(chargeCodes[colName].category)) {
            const ccConfig = isRetail ? chargeCodes[colName] : {};
            const ccCategory = isRetail ? chargeCodes[colName] : chargeCodes[colName].code;
            chargeCodes[colName] = {
              total, colIndex: foundColumnIndex, category: ccCategory, key: colName, ...ccConfig
            };
          } else {
            chargeCodes[colName] = {
              ...chargeCodes[colName], colIndex: foundColumnIndex, total, key: colName, ...chargeCodes[colName]
            };
          }
          chargeCodes[colName]['total'] = total;
        }
      });
    }
    this.initailChargeCodes = { ...this.initailChargeCodes, ...chargeCodes };

    this.options.store.dispatch(setChargeCodeConfig({
      ...chargeCodes
    }));
    this.workbookManager.resume();
  }

  retrieveChargeCodes() {
    const chargeCodes = this.options.chargeCodeConfig;
    const rowCount = this.getTargetSheetRowCount();
    const sheet = this.workbookManager.getSheet(targetSheetIndex);
    this.workbookManager.suspend();
    if (Reflect.ownKeys(chargeCodes).length > 0) {
      Reflect.ownKeys(chargeCodes).forEach(colName => {
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, colName);
        if (foundColumnIndex !== -1) {
          const total = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${this.columnAlphabetIndex(foundColumnIndex)}2:${this.columnAlphabetIndex(foundColumnIndex)}${rowCount})`);
          this.options.chargeCodeConfig[colName] = {
            ...chargeCodes[colName], total, colIndex: foundColumnIndex, category: chargeCodes[colName].category, key: colName
          };
        }
      });
    }

    this.options.store.dispatch(setChargeCodeConfig({
      ...this.options.chargeCodeConfig
    }));
    this.workbookManager.resume();
  }

  getRentRollUniqueColumnValues(columnName) {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const columnValues = [];
      const rowCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getRowCount();
      const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, columnName);
      for (let i = 1; i < rowCount; i++) {
        const colValue = this.workbookManager.getSheet(targetSheetIndex).getValue(i, foundColumnIndex);
        !isNil(colValue) && columnValues.push(colValue);
      }
      const result = columnValues.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});
      this.workbookManager.resume();
      return result;
    }
  }

  getColumnAlphabetIndex(columnIndex) {
    return this.workbookManager.toAlphabetColumnName(columnIndex + 1);
  }

  getAlphabetTargetRange(columnName, sheetIndex) {
    const rowCount = this.workbookManager.workbook.getSheet(sheetIndex).getRowCount();
    const columnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, columnName).foundColumnIndex;
    const targetRange = `${this.getColumnAlphabetIndex(columnIndex)}1:${this.getColumnAlphabetIndex(columnIndex)}${rowCount}`;
    return { rowCount, targetRange, columnIndex };
  }

  getRecentLeaseAverageRent(recentLeaseDataArray) {
    let recentLeaseAverageRent = {};
    recentLeaseDataArray.map(obj =>  {
      const { day, data } = obj;
      const avgRent = data.length ? mean(data).toFixed(2) : 0;
      const count= data.length;
      recentLeaseAverageRent = {
        ...recentLeaseAverageRent,
        [recentLeaseAverageRentkey[day][0]]: count,
        [recentLeaseAverageRentkey[day][1]]: avgRent
      }
    })
    return recentLeaseAverageRent
  }

  getRecentLeasesDayWise(sheetIndex, rowCount, sourceColumnIndex, moveInDateColumnIndex, startDateColIndex, days, targetColumnIndex, cellValue, isExactDataTypeMatch = true) {
    const sheet = this.workbookManager.getSheet(sheetIndex);
    return days.map(day =>  {
      const foundRowIndex = [];
      for (let row = 1; row < rowCount; row++) {
        const columnValue = sheet.getCell(row, sourceColumnIndex).value();
        const moveInDate = sheet.getCell(row, moveInDateColumnIndex).value();
        const startDate = sheet.getCell(row, startDateColIndex).value();
        const effectiveStartDate = startDate || moveInDate;
        if(isValidDate(effectiveStartDate)) {
          const taggingInfo = currentDocumentTaggingInfoSelector(this.options.store.getState());
          const asOnDate = get(taggingInfo, 'asOnDate');
          const daysBetweenDates = Math.abs(new Date(asOnDate) - new Date(effectiveStartDate)) / (24 * 60 * 60 * 1000);
          const validatedCellValue = isExactDataTypeMatch ? columnValue === cellValue : columnValue == cellValue;
          if (validatedCellValue && daysBetweenDates <= day) {
            foundRowIndex.push(sheet.getCell(row, targetColumnIndex).value());
          }
        }
      }
      return {
        day: day,
        data: foundRowIndex
      }
    })
  }

  getRecentLeases(sheetIndex, rowCount, sourceColumnIndex, moveInDateColumnIndex, targetColumnIndex, cellValue, isExactDataTypeMatch = true) {
    const sheet = this.workbookManager.getSheet(sheetIndex);
    const taggingInfo = currentDocumentTaggingInfoSelector(this.options.store.getState());
    const asOnDate = get(taggingInfo, 'asOnDate');
    const leaseData = [];
    for (let row = 1; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, sourceColumnIndex).value();
      const validatedCellValue = isExactDataTypeMatch ? columnValue === cellValue : columnValue == cellValue;
      if (validatedCellValue) {
        leaseData.push({ value: sheet.getCell(row, targetColumnIndex).value(), date: sheet.getCell(row, moveInDateColumnIndex).value() });
      }
    }
    leaseData.sort((a, b) => b.date - a.date);
    const recentLeases = leaseData.filter(data => new Date(data.date) < new Date(asOnDate));
    const lastFiveLeases = [];
    for (let count = 0; count<recentLeases.length; count++) {
      if(count === 5) break;
      lastFiveLeases.push(recentLeases[count].value)
    }
    return lastFiveLeases;
  }

  getCellValuesOcuppiedStatusWise(sheetIndex, rowCount, sourceColumnIndex, statusColumnIndex, status, unitStatusConfig, targetColumnIndex, cellValue,isExactDataTypeMatch = true) {
    const unitStatus = Reflect.ownKeys(unitStatusConfig) || [];
    const occupiedUnits =  !!unitStatus.length ? unitStatus.filter(unit => unitStatusConfig[unit].category === status) : []
    const sheet = this.workbookManager.getSheet(sheetIndex);
    const foundRowIndex = [];
    for (let row = 1; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, sourceColumnIndex).value();
      const statusValue = sheet.getCell(row, statusColumnIndex).value();
      const monthlYRent = sheet.getCell(row, targetColumnIndex).value();
      const validatedCellValue = isExactDataTypeMatch ? columnValue === cellValue  && occupiedUnits.includes(statusValue): columnValue == cellValue && occupiedUnits.includes(statusValue);
      if (validatedCellValue) {
        foundRowIndex.push(sheet.getCell(row, targetColumnIndex).value());
      }
    }
    return foundRowIndex;
  }

  getUniqueFloorPlan(eventType = undefined) {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const mappedFloorPlan = currentDocumentFloorPlanSelector(this.options.store.getState());
      const unitStatusConfig = currentDocumentUnitStatusSelector(this.options.store.getState());
      const isAcquisition = currentTemplateTagSelector(this.options.store.getState()) === TEMPLATE_TAGS.ACQUISITION.key;

      const unitStatus = Reflect.ownKeys(unitStatusConfig) || [];
      const occupiedUnits = !!unitStatus.length ? unitStatus.filter(unit => unitStatusConfig[unit].category === 'Occupied') : [];
      const vacantUnits = !!unitStatus.length ? unitStatus.filter(unit => unitStatusConfig[unit].category === 'Vacant') : [];
      const downUnits = !!unitStatus.length ? unitStatus.filter(unit => unitStatusConfig[unit].category === 'Admin/Down' || unitStatusConfig[unit].category === 'Down' ) : [];
      let recentLeaseAverageRentArray = [];
      let recentFiveLeaseCount = 0;
      let recentFiveLeaseCountAvgRent = 0;
      let avgSquareFt = 0;
      let marketRent = 0;
      let monthlyRent = 0;
      let vacant = 0;
      let down = 0;
      let occupiedMarketRent = 0;
      let occupiedMonthlyRent = 0;
      let occupiedUnitCount = 0;
      let floorPlanForSummary = {};
      let totalUnitCount = 0;
      let result = {};
      let finalFloorPlan = {};
      let validColumnName = '';
      let marketRentTotal = 0;
      let marketRentMinValue = 0;
      let marketRentMaxValue = 0;
      let monthlyRentMinValue = 0;
      let monthlyRentMaxValue = 0;
      let monthlyRentTotal = 0;
      let squareFtMinValue = 0;
      let squareFtMaxValue = 0;
      let squareFtTotal = 0;
      let occupancyStatus = {};
      let columnValues = [];
      let occupiedMonthlyRentTotal = 0;
      for (let col = 0; col < RENT_ROLL_MF_COLUMN_NAME.length; col++) {
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, RENT_ROLL_MF_COLUMN_NAME[col]);
        if (foundColumnIndex !== -1 && !columnValues.length) {
          const columnData = this.getRentRollUniqueColumnValues(RENT_ROLL_MF_COLUMN_NAME[col]);
          if (Reflect.ownKeys(columnData).length) {
            const isInvalidCellValues = Reflect.ownKeys(columnData).every(col => excludedRentRollMFColValue.includes(col));
            // checking if columnData has not contained ignored values
            if (!isInvalidCellValues) {
              validColumnName = RENT_ROLL_MF_COLUMN_NAME[col];
              columnValues.push(columnData);
              break;
            }
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      let columnIndex = 0;
      for (let cellValue of columnValues) {
        // eslint-disable-next-line no-unused-vars
        columnIndex++;
        if (Reflect.ownKeys(cellValue).length > 0) {
          const isColumnContainsInValidValue = arrayContainsArray(excludedRentRollMFColValue, Reflect.ownKeys(cellValue));
          if (!isColumnContainsInValidValue) {
            result = { ...result, ...cellValue };
          }
        }
      }

      if (Reflect.ownKeys(result).length > 0) {
        Reflect.ownKeys(result).forEach(item => {
          excludedRentRollMFColValue.includes(item) && delete result[item];
        });
        const { rowCount } = this.getAlphabetTargetRange(validColumnName, targetSheetIndex);
        const floorPlanColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, validColumnName).foundColumnIndex;
        const marketRentAmoutColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Market Rent').foundColumnIndex;
        const monthlyRentAmoutColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Monthly Rent').foundColumnIndex;
        const squareFtColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Sq Ft').foundColumnIndex;
        const occupancyStatusColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Status').foundColumnIndex;
        const affordableColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Affordable').foundColumnIndex;
        const renovationColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Renovation').foundColumnIndex;
        const subsidyColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Subsidy').foundColumnIndex;
        const renovationBumpColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Renovation Bump').foundColumnIndex;
        const renovationCostColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Renovation Cost').foundColumnIndex;
        const moveInDateColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Move In Date').foundColumnIndex;
        const startDateColIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Start Date').foundColumnIndex;
        const { YES } = affordableRnotationOptions;

        if (isAcquisition && !eventType) { // Ignoring this block to execute on manual cell change
          this.calculateAffordableRenovationColumns(affordableColumnIndex, renovationColumnIndex, rowCount);
        }

        finalFloorPlan = Reflect.ownKeys(result).reduce((acc, item) => {
          const bed = !isNil(mappedFloorPlan[item]) ? mappedFloorPlan[item].BD : '';
          const bath = !isNil(mappedFloorPlan[item]) ? mappedFloorPlan[item].BA : '';
          const renovationConfig = !isNil(mappedFloorPlan[item]) && mappedFloorPlan[item].renovationConfig ? mappedFloorPlan[item].renovationConfig : { renovated: 0, avgRenovationCost: 0, avgRenovationBump: 0, renovationBump: 0, renovationCost: 0 };
          const affordableConfig = !isNil(mappedFloorPlan[item]) && mappedFloorPlan[item].affordableConfig ? mappedFloorPlan[item].affordableConfig : { affordable: 0, avgSubsidy: 0 };

          if (marketRentAmoutColumnIndex !== -1) {
            const marketRentColumnValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, marketRentAmoutColumnIndex, item, false);
            marketRentMinValue = min(compact(marketRentColumnValues)) || 0;
            marketRentMaxValue = max(compact(marketRentColumnValues)) || 0;
            marketRentTotal = sum(compact(marketRentColumnValues)) || 0;
            marketRent = !isEmpty(marketRentColumnValues) ? (sum(compact(marketRentColumnValues)) / marketRentColumnValues.length).toFixed(2) : 0;
          }
          if (monthlyRentAmoutColumnIndex !== -1) {
            const monthlyRentValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, monthlyRentAmoutColumnIndex, item, false);
            monthlyRentMinValue = min(compact(monthlyRentValues)) || 0;
            monthlyRentMaxValue = max(compact(monthlyRentValues)) || 0;
            monthlyRentTotal = sum(compact(monthlyRentValues)) || 0;
            monthlyRent = !isEmpty(monthlyRentValues) ? sum(compact(monthlyRentValues)) / monthlyRentValues.length : 0;
          }
          if(moveInDateColumnIndex !== -1 && monthlyRentAmoutColumnIndex !== -1) {
            const recentFiveLease = this.getRecentLeases(targetSheetIndex, rowCount, floorPlanColumnIndex, moveInDateColumnIndex, monthlyRentAmoutColumnIndex, item, false);
            recentFiveLeaseCount = !isEmpty(recentFiveLease) ? recentFiveLease.length : 0;
            recentFiveLeaseCountAvgRent = recentFiveLeaseCount ? (sum(compact(recentFiveLease))/recentFiveLeaseCount).toFixed(2) : 0;
            const recentLeaseDataArray = this.getRecentLeasesDayWise(targetSheetIndex, rowCount, floorPlanColumnIndex, moveInDateColumnIndex, startDateColIndex, [30, 60, 90], monthlyRentAmoutColumnIndex, item, false);
            recentLeaseAverageRentArray = this.getRecentLeaseAverageRent(recentLeaseDataArray);
          }
          if (squareFtColumnIndex !== -1 && floorPlanColumnIndex !== -1) {
            const filteredSqFt = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, squareFtColumnIndex, item, false);
            squareFtMinValue = min(compact(filteredSqFt)) || 0;
            squareFtMaxValue = max(compact(filteredSqFt)) || 0;
            squareFtTotal = sum(compact(filteredSqFt)) || 0;
            avgSquareFt = !isEmpty(filteredSqFt) ? (sum(compact(filteredSqFt)) / filteredSqFt.length).toFixed(2) : 0;
          }
          if (occupancyStatusColumnIndex > 0) {
            const sheetOccupancy = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, occupancyStatusColumnIndex, item, false);
            totalUnitCount = !!sheetOccupancy.length ? sheetOccupancy.length : 0;
            occupiedUnitCount = !!sheetOccupancy.length ? sheetOccupancy.filter(unit => occupiedUnits.includes(unit)).length : 0;
            occupancyStatus = { occupiedUnitCount };
            vacant = !!sheetOccupancy.length ? sheetOccupancy.filter(unit => vacantUnits.includes(unit)).length : 0;
            down = !!sheetOccupancy.length ? sheetOccupancy.filter(unit => downUnits.includes(unit)).length : 0;
          }
          if(occupancyStatusColumnIndex > 0 && marketRentAmoutColumnIndex > 0) {
            const occupiedMarketRentValues = this.getCellValuesOcuppiedStatusWise(targetSheetIndex, rowCount, floorPlanColumnIndex, occupancyStatusColumnIndex, 'Occupied', unitStatusConfig, marketRentAmoutColumnIndex, item, false);
            occupiedMarketRent = !isEmpty(occupiedMarketRentValues) ? (sum(compact(occupiedMarketRentValues))/occupiedMarketRentValues?.length).toFixed(2) : 0;
          }
          if(occupancyStatusColumnIndex > 0 && monthlyRentAmoutColumnIndex > 0) {
            const monthlyRentValues = this.getCellValuesOcuppiedStatusWise(targetSheetIndex, rowCount, floorPlanColumnIndex, occupancyStatusColumnIndex, 'Occupied', unitStatusConfig, monthlyRentAmoutColumnIndex, item, false);
            occupiedMonthlyRentTotal = sum(compact(monthlyRentValues));
            occupiedMonthlyRent = !isEmpty(monthlyRentValues) ? (sum(compact(monthlyRentValues)) / monthlyRentValues?.length).toFixed(2) : 0;
          }
          
          if (affordableColumnIndex > 0 && eventType !== 'onDataBind') {
            const affordableValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, affordableColumnIndex, item, false);
            const subsidyColumnValue = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, subsidyColumnIndex, item, false);
            const isAffordableExists = !!affordableValues.length;
            const affordableCount = isAffordableExists ? affordableValues.filter(affordableValue => affordableValue === YES).length : 0;
            const totalSubsidy = sum(compact(subsidyColumnValue)) || 0;
            affordableConfig.affordable = affordableCount;
            const avgCalculation = totalSubsidy / affordableCount;
            affordableConfig.avgSubsidy = isValidNumber(avgCalculation) ? +avgCalculation.toFixed(2) : 0;
          }

          if (renovationColumnIndex > 0 && eventType !== 'onDataBind') {
            const renovatedValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, renovationColumnIndex, item, false);
            const renovatedCount = !!renovatedValues.length ? renovatedValues.filter(renovatedValue => renovatedValue === YES).length : 0;
            renovationConfig.renovated = renovatedCount;
          }

          if (renovationBumpColumnIndex > 0 && eventType !== 'onDataBind') {
            const renovatedBumpValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, renovationBumpColumnIndex, item, false);
            const renovatedBumpTotal = sum(compact(renovatedBumpValues)) || 0;
            const avgCalculation = renovatedBumpTotal / renovationConfig.renovated;
            renovationConfig.renovationBump = renovatedBumpTotal;
            renovationConfig.avgRenovationBump = isValidNumber(avgCalculation) ? +avgCalculation.toFixed(2) : 0;
          }

          if (renovationCostColumnIndex > 0 && eventType !== 'onDataBind') {
            const renovatedCostValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, renovationCostColumnIndex, item, false);
            const renovatedCostTotal = sum(compact(renovatedCostValues)) || 0;
            renovationConfig.renovationCost = renovatedCostTotal;
            const avgCalculation = renovatedCostTotal / renovationConfig.renovated;
            renovationConfig.avgRenovationCost = isValidNumber(avgCalculation) ? +avgCalculation.toFixed(2) : 0;
          }

          floorPlanForSummary[item] = {
            BD: isFinite(Number(bed)) ? Number(bed) : bed,
            BA: isFinite(Number(bath)) ? Number(bath) : bath,
            ...recentLeaseAverageRentArray,
            recentFiveLeaseCount,
            recentFiveLeaseCountAvgRent,
            avgSquareFt,
            marketRent,
            vacant,
            down,
            occupiedMarketRent,
            occupiedMonthlyRent,
            occupiedUnitCount,
            occupancyStatus,
            totalUnitCount,
            renovationConfig
          }

          acc[item] = {
            BD: isFinite(Number(bed)) ? Number(bed) : bed,
            BA: isFinite(Number(bath)) ? Number(bath) : bath,
            count: result[item],
            marketRentTotal,
            marketRentMinValue,
            marketRentMaxValue,
            monthlyRentMinValue,
            monthlyRentMaxValue,
            monthlyRentTotal,
            squareFtMinValue,
            squareFtMaxValue,
            squareFtTotal,
            occupancyStatus,
            renovationConfig,
            affordableConfig,
            occupiedMonthlyRentTotal,
            occupiedUnitCount
          };
          return acc;
        }, {});
      }

      finalFloorPlan = Object.keys(finalFloorPlan).sort().reduce((acc, key) => ({
        ...acc, [key]: finalFloorPlan[key]
      }), {});

      this.options.store.dispatch(setFloorPlan({
        ...finalFloorPlan
      }));

      floorPlanForSummary = Object.keys(floorPlanForSummary).sort().reduce((acc, key) => ({
        ...acc, [key]: floorPlanForSummary[key]
      }), {});
      this.options.store.dispatch(setFloorPlanSummary({
        ...floorPlanForSummary
      }));
      this.workbookManager.resume();
    }
  }

  calculateUnitMixSummary(eventType = undefined) {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const sheet = this.workbookManager.getSheet(targetSheetIndex);
      const mappedUnitMixSummary = unitMixSummaryConfigSelector(this.options.store.getState());
      const unitStatusConfig = currentDocumentUnitStatusSelector(this.options.store.getState());
      const isAcquisition = currentTemplateTagSelector(this.options.store.getState()) === TEMPLATE_TAGS.ACQUISITION.key;
      const unitStatus = Reflect.ownKeys(unitStatusConfig) || [];
      const occupiedUnits = !!unitStatus.length ? unitStatus.filter(unit => unitStatusConfig[unit].category === 'Occupied') : []
      const vacantUnits = !!unitStatus.length ? unitStatus.filter(unit => unitStatusConfig[unit].category === 'Vacant') : []

      let marketRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
      let monthlyRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
      let occupiedMonthlyRentConfig = { total: 0, min: 0, max: 0, avg: 0};
      let sqFtConfig = { total: 0, min: 0, max: 0 };
      let occupancyConfig = { occupiedUnitCount: 0, vacantUnitCount: 0 };
      let nonRevenueUnits = 0;
      let affordableConfig = { affordable: 0, avgSubsidy: 0 };
      let renovationConfig = { renovated: 0, avgRenovationCost: 0, avgRenovationBump: 0, renovationBump: 0, renovationCost: 0 };
      let unitMix = {};
      let unitMixConfig = {
        marketRentConfig,
        monthlyRentConfig,
        sqFtConfig,
        occupancyConfig,
        nonRevenueUnits,
        affordableConfig,
        renovationConfig,
        occupiedMonthlyRentConfig
      }
      let unitMixSummary = {};
      let validColumnName = '';
      let columnValues = [];

      for (let col = 0; col < RENT_ROLL_MF_COLUMN_NAME.length; col++) {
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, RENT_ROLL_MF_COLUMN_NAME[col]);
        if (foundColumnIndex !== -1 && !columnValues.length) {
          const columnData = this.getRentRollUniqueColumnValues(RENT_ROLL_MF_COLUMN_NAME[col]);
          if (Reflect.ownKeys(columnData).length) {
            const isInvalidCellValues = Reflect.ownKeys(columnData).every(col => excludedRentRollMFColValue.includes(col));
            // checking if columnData has not contained ignored values
            if (!isInvalidCellValues) {
              validColumnName = RENT_ROLL_MF_COLUMN_NAME[col];
              columnValues.push(columnData);
              break;
            }
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      let columnIndex = 0;
      for (let cellValue of columnValues) {
        // eslint-disable-next-line no-unused-vars
        columnIndex++;
        if (Reflect.ownKeys(cellValue).length > 0) {
          const isColumnContainsInValidValue = arrayContainsArray(excludedRentRollMFColValue, Reflect.ownKeys(cellValue));
          if (!isColumnContainsInValidValue) {
            unitMix = { ...unitMix, ...cellValue };
          }
        }
      }

      if (Reflect.ownKeys(unitMix).length > 0) {
        Reflect.ownKeys(unitMix).forEach(fpName => {
          excludedRentRollMFColValue.includes(fpName) && delete unitMix[fpName];
        });

        const { rowCount } = this.getAlphabetTargetRange(validColumnName, targetSheetIndex);
        const floorPlanColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, validColumnName).foundColumnIndex;
        const marketRentAmoutColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Market Rent').foundColumnIndex;
        const monthlyRentAmoutColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Monthly Rent').foundColumnIndex;
        const squareFtColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Sq Ft').foundColumnIndex;
        const occupancyStatusColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Status').foundColumnIndex;
        const affordableColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Affordable').foundColumnIndex;
        const renovationColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Renovation').foundColumnIndex;
        const subsidyColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Subsidy').foundColumnIndex;
        const renovationBumpColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Renovation Bump').foundColumnIndex;
        const renovationCostColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Renovation Cost').foundColumnIndex;
        const leaseTypeColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Lease Type').foundColumnIndex;

        if (isAcquisition && !eventType) { // Ignoring this block to execute on manual cell change
          this.calculateAffordableRenovationColumns(affordableColumnIndex, renovationColumnIndex, rowCount);
          this.calculateLeaseTypeColumns(leaseTypeColumnIndex, rowCount);
        }

        unitMixSummary = Reflect.ownKeys(unitMix).reduce((acc, fpName) => {
          const bed = !isNil(mappedUnitMixSummary[fpName]) ? mappedUnitMixSummary[fpName].BD : '';
          const bath = !isNil(mappedUnitMixSummary[fpName]) ? mappedUnitMixSummary[fpName].BA : '';
          let children = !isNil(mappedUnitMixSummary[fpName]) ? mappedUnitMixSummary[fpName].children : [];
          const isModfied = !isNil(mappedUnitMixSummary[fpName]) && mappedUnitMixSummary[fpName].isModfied || false;
          let leaseTypeColValue = !isNil(mappedUnitMixSummary[fpName]) ? mappedUnitMixSummary[fpName].leaseType : '';
          nonRevenueUnits = !isNil(mappedUnitMixSummary[fpName]) ? mappedUnitMixSummary[fpName].nonRevenueUnits : 0;
          renovationConfig = !isNil(mappedUnitMixSummary[fpName]) && mappedUnitMixSummary[fpName].renovationConfig ? mappedUnitMixSummary[fpName].renovationConfig : renovationConfig;
          affordableConfig = !isNil(mappedUnitMixSummary[fpName]) && mappedUnitMixSummary[fpName].affordableConfig ? mappedUnitMixSummary[fpName].affordableConfig : affordableConfig;

          if (!isModfied) {
            const marketRentColumnValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, marketRentAmoutColumnIndex, fpName, false);
            const monthlyRentValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, monthlyRentAmoutColumnIndex, fpName, false);
            const sqFtValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, squareFtColumnIndex, fpName, false);
            const occupancyStatusValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, occupancyStatusColumnIndex, fpName, false);
            const affordableValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, affordableColumnIndex, fpName, false);
            const subsidyColumnValue = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, subsidyColumnIndex, fpName, false);
            const renovatedValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, renovationColumnIndex, fpName, false);
            const renovatedBumpValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, renovationBumpColumnIndex, fpName, false);
            const renovatedCostValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, renovationCostColumnIndex, fpName, false);
            const occupiedMonthlyRentColumnValues = this.getCellValuesOcuppiedStatusWise(targetSheetIndex, rowCount, floorPlanColumnIndex, occupancyStatusColumnIndex, 'Occupied', unitStatusConfig, monthlyRentAmoutColumnIndex, fpName, false);
            unitMixConfig = this.calculateUnitMixData(
              {
                marketRentColumnValues,
                monthlyRentValues,
                occupiedMonthlyRentColumnValues,
                sqFtValues,
                occupancyStatusValues,
                occupiedUnits,
                vacantUnits,
                affordableValues,
                subsidyColumnValue,
                renovatedValues,
                renovatedBumpValues,
                renovatedCostValues
              },
              unitMix,
              fpName
            );

            if (leaseTypeColumnIndex > 0 && eventType !== 'onDataBind') {
              const leaseTypeValues = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, leaseTypeColumnIndex, fpName, false);
              const childByLeaseType = {}
              const uniqueLeaseTypes = uniq(leaseTypeValues);
              uniqueLeaseTypes.forEach((leaseType, rowIndex) => {

                if(!leaseType) return;
                
                if (rowIndex === 0) { // considering first row as parent
                  leaseTypeColValue = leaseType;
                }

                if (uniqueLeaseTypes.length > 1) {
                  // Check if child contains lease type value
                  const leaseTypes = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, floorPlanColumnIndex, leaseTypeColumnIndex, fpName, false);
                  const unitCount = !!leaseType.length && getFilteredArrayCount(leaseTypes, leaseType);
                  const childConfig = this.calculateChildUnitMixData(
                    sheet,
                    rowCount,
                    floorPlanColumnIndex,
                    {
                      marketRentAmoutColumnIndex,
                      monthlyRentAmoutColumnIndex,
                      squareFtColumnIndex,
                      occupancyStatusColumnIndex,
                      affordableColumnIndex,
                      subsidyColumnIndex,
                      renovationColumnIndex,
                      renovationBumpColumnIndex,
                      renovationCostColumnIndex
                    },
                    leaseTypeColumnIndex,
                    fpName,
                    leaseType
                  );

                  const unitMixDataConfig = this.calculateUnitMixData(
                    {
                      marketRentColumnValues: map(childConfig, 'marketRent'),
                      monthlyRentValues: map(childConfig, 'monthlyRent'),
                      occupiedMonthlyRentColumnValues: map(childConfig, 'occupiedRent'),
                      sqFtValues: map(childConfig, 'sqFt'),
                      occupancyStatusValues: map(childConfig, 'occupancyStatus'),
                      occupiedUnits,
                      vacantUnits,
                      affordableValues: map(childConfig, 'affordable'),
                      subsidyColumnValue: map(childConfig, 'subsidy'),
                      renovatedValues: map(childConfig, 'renovation'),
                      renovatedBumpValues: map(childConfig, 'renovationBump'),
                      renovatedCostValues: map(childConfig, 'renovationCost')
                    },
                    unitMix,
                    fpName
                  );

                  if (leaseType !== leaseTypeList.AFFORDABLE) {
                    unitMixDataConfig.affordableConfig = { affordable: 0, avgSubsidy: 0 };
                  }

                  childByLeaseType[leaseType] = {
                    floorPlan: fpName,
                    BD: Number(bed),
                    BA: Number(bath),
                    unitCounts: unitCount,
                    leaseType,
                    ...unitMixDataConfig
                  }
                }

              });

              children = Object.values(childByLeaseType)
            }
          }

          if (leaseTypeColValue !== leaseTypeList.AFFORDABLE) {
            unitMixConfig.affordableConfig = { affordable: 0, avgSubsidy: 0 };
          }

          acc[fpName] = {
            BD: Number(bed),
            BA: Number(bath),
            unitCounts: unitMix[fpName],
            leaseType: leaseTypeColValue,
            isModfied,
            children,
            ...unitMixConfig,
          };
          return acc;
        }, {});
      }

      unitMixSummary = Object.keys(unitMixSummary).sort().reduce((acc, key) => ({
        ...acc, [key]: unitMixSummary[key]
      }), {});

      this.options.store.dispatch(setUnitMixSummary({
        ...unitMixSummary
      }));
      this.workbookManager.resume();
    }
  }

  calculateUnitMixData(columnValues, unitMix, fpName) {
    this.workbookManager.suspend();
    let marketRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
    let monthlyRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
    let occupiedMonthlyRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
    let sqFtConfig = { total: 0, min: 0, max: 0, avg: 0 };
    let occupancyConfig = { occupiedUnitCount: 0, vacantUnitCount: 0 };
    let nonRevenueUnits = 0;
    let affordableConfig = { affordable: 0, avgSubsidy: 0 };
    let renovationConfig = { renovated: 0, avgRenovationCost: 0, avgRenovationBump: 0, renovationBump: 0, renovationCost: 0 };

    const {
      marketRentColumnValues,
      monthlyRentValues,
      sqFtValues,
      occupancyStatusValues,
      occupiedUnits,
      vacantUnits,
      affordableValues,
      subsidyColumnValue,
      renovatedValues,
      renovatedBumpValues,
      renovatedCostValues,
      occupiedMonthlyRentColumnValues
    } = columnValues;

    // Market Rent Calculation
    marketRentConfig.min = min(compact(marketRentColumnValues)) || 0;
    marketRentConfig.max = max(compact(marketRentColumnValues)) || 0;
    marketRentConfig.total = sum(compact(marketRentColumnValues)) || 0;
    marketRentConfig.avg = getAverage(marketRentConfig.total, unitMix[fpName]) || 0;

    // Monthly Rent Calculation
    monthlyRentConfig.min = min(compact(monthlyRentValues)) || 0;
    monthlyRentConfig.max = max(compact(monthlyRentValues)) || 0;
    monthlyRentConfig.total = sum(compact(monthlyRentValues)) || 0;
    monthlyRentConfig.avg = getAverage(monthlyRentConfig.total, unitMix[fpName]) || 0;

    // Sq. Ft Calculation
    sqFtConfig.min = min(compact(sqFtValues)) || 0;
    sqFtConfig.max = max(compact(sqFtValues)) || 0;
    sqFtConfig.total = sum(compact(sqFtValues)) || 0;
    sqFtConfig.avg = getAverage(sqFtConfig.total, unitMix[fpName]) || 0;

    // Occupancy Status calculation
    occupancyConfig.occupiedUnitCount = filter(occupancyStatusValues, unit => occupiedUnits.includes(unit)).length;
    occupancyConfig.vacantUnitCount = filter(occupancyStatusValues, unit => vacantUnits.includes(unit)).length;
    nonRevenueUnits = filter(occupancyStatusValues, nonRevenue => unitMixNonRevenueUnits.includes(nonRevenue)).length;

    //Occupied unit monthly rent calculation
    occupiedMonthlyRentConfig.min = min(compact(occupiedMonthlyRentColumnValues)) || 0;
    occupiedMonthlyRentConfig.max = max(compact(occupiedMonthlyRentColumnValues)) || 0;
    occupiedMonthlyRentConfig.total = sum(compact(occupiedMonthlyRentColumnValues)) || 0;
    occupiedMonthlyRentConfig.avg = occupancyConfig.occupiedUnitCount ? getAverage(occupiedMonthlyRentConfig.total, occupancyConfig.occupiedUnitCount) || 0 : 0;

    // Affordable Calculation
    affordableConfig.affordable = !!affordableValues && affordableValues.filter(affordableValue => affordableValue === affordableRnotationOptions.YES).length || 0;
    const totalSubsidy = sum(compact(subsidyColumnValue)) || 0;
    const avgCalculation = totalSubsidy / affordableConfig.affordable;
    affordableConfig.avgSubsidy = isValidNumber(avgCalculation) ? +avgCalculation.toFixed(2) : 0;

    // Renocation Calculation
    renovationConfig.renovated = !!renovatedValues && renovatedValues.filter(renovatedValue => renovatedValue === affordableRnotationOptions.YES).length;
    renovationConfig.renovationBump = sum(compact(renovatedBumpValues)) || 0;
    const avgRenoCalculation = renovationConfig.renovationBump / renovationConfig.renovated;
    renovationConfig.avgRenovationBump = isValidNumber(avgRenoCalculation) ? +avgRenoCalculation.toFixed(2) : 0;
    renovationConfig.renovationCost = sum(compact(renovatedCostValues)) || 0;
    const avgRenoCostCalculation = renovationConfig.renovationCost / renovationConfig.renovated;
    renovationConfig.avgRenovationCost = isValidNumber(avgRenoCostCalculation) ? +avgRenoCostCalculation.toFixed(2) : 0;

    this.workbookManager.resume();
    return { marketRentConfig, monthlyRentConfig, sqFtConfig, occupancyConfig, nonRevenueUnits, affordableConfig, renovationConfig, occupiedMonthlyRentConfig }
  }

  calculateChildUnitMixData(sheet, rowCount, sourceColIndex, targetColumns, leaseTypeColumnIndex, fpName, leaseType) {
    this.workbookManager.suspend();
    const unitStatusConfig = currentDocumentUnitStatusSelector(this.options.store.getState());
    const unitStatus = Reflect.ownKeys(unitStatusConfig) || [];
    const occupiedUnits =  !!unitStatus.length ? unitStatus.filter(unit => unitStatusConfig[unit].category === 'Occupied') : []
    const filteredValue = [];
    const {
      marketRentAmoutColumnIndex,
      monthlyRentAmoutColumnIndex,
      squareFtColumnIndex,
      occupancyStatusColumnIndex,
      affordableColumnIndex,
      subsidyColumnIndex,
      renovationColumnIndex,
      renovationBumpColumnIndex,
      renovationCostColumnIndex
    } = targetColumns;

    for (let row = 1; row < rowCount; row++) {
      const cellValue = sheet.getCell(row, sourceColIndex).value();
      if (!isNil(cellValue) && cellValue === fpName) {
        const marketRent = sheet.getCell(row, marketRentAmoutColumnIndex).value();
        const monthlyRent = sheet.getCell(row, monthlyRentAmoutColumnIndex).value();
        const sqFt = sheet.getCell(row, squareFtColumnIndex).value();
        const occupancyStatus = sheet.getCell(row, occupancyStatusColumnIndex).value();
        const affordable = sheet.getCell(row, affordableColumnIndex).value();
        const subsidy = sheet.getCell(row, subsidyColumnIndex).value();
        const renovation = sheet.getCell(row, renovationColumnIndex).value();
        const renovationBump = sheet.getCell(row, renovationBumpColumnIndex).value();
        const renovationCost = sheet.getCell(row, renovationCostColumnIndex).value();
        const leaseTypeColumnValue = sheet.getCell(row, leaseTypeColumnIndex).value();
        const occupiedRent = occupiedUnits.includes(occupancyStatus) ? monthlyRent : 0;
        if (!isNil(leaseTypeColumnValue) && leaseTypeColumnValue === leaseType) {
          filteredValue.push({
            marketRent,
            monthlyRent,
            sqFt,
            occupancyStatus,
            affordable,
            subsidy,
            renovation,
            renovationBump,
            renovationCost,
            leaseType,
            fpName,
            occupiedRent
          });
        }
      }
    }
    this.workbookManager.resume();
    return filteredValue;
  }

  calculateLeaseTypeColumns(leaseTypeColumnIndex, rowCount) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(targetSheetIndex)
    const subsidyColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Subsidy').foundColumnIndex;
    for (let row = 1; row < rowCount; row++) {
      if (leaseTypeColumnIndex > 0 && subsidyColumnIndex > 0) {
        const subsidyColumnValue = sheet.getCell(row, subsidyColumnIndex).value();
        if (!isNil(subsidyColumnValue)) {
          const cellValue = !!subsidyColumnValue ? 'Affordable' : 'Market Rent';
          sheet.getCell(row, leaseTypeColumnIndex).value(cellValue);
        }
      }
    }
    this.workbookManager.resume();
  }

  calculateAffordableRenovationColumns(affordableColumnIndex, renovationColumnIndex, rowCount) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(targetSheetIndex)
    const subsidyColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Subsidy').foundColumnIndex;
    const rentPremiumColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Rent Premium').foundColumnIndex;
    const { YES, NO } = affordableRnotationOptions;

    for (let row = 1; row < rowCount; row++) {
      if (affordableColumnIndex > 0 && subsidyColumnIndex > 0) {
        const subsidyColumnValue = sheet.getCell(row, subsidyColumnIndex).value();
        if (!isNil(subsidyColumnValue)) {
          const cellValue = !!subsidyColumnValue ? YES : NO;
          sheet.getCell(row, affordableColumnIndex).value(cellValue);
        }
      }
      if (renovationColumnIndex > 0 && rentPremiumColumnIndex > 0) {
        const rentPremiumnColumnValue = sheet.getCell(row, rentPremiumColumnIndex).value();
        if (!isNil(rentPremiumnColumnValue)) {
          const cellValue = !!rentPremiumnColumnValue ? YES : NO;
          sheet.getCell(row, renovationColumnIndex).value(cellValue);
        }
      }
    }
    this.workbookManager.resume();
  }

  convertTenantNameToStatus(columnName) {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const columnValues = [];
      let result = {};
      const tenantNameUnitStatusKeys = Reflect.ownKeys(tenantNameUnitStatusSelector(this.options.store.getState()));
      if (tenantNameUnitStatusKeys.length) {
        const rowCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getRowCount();
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, columnName);
        for (let i = 1; i < rowCount; i++) {
          const colValue = this.workbookManager.getSheet(targetSheetIndex).getValue(i, foundColumnIndex);
          if (!isNil(colValue) && !isNil(tenantNameUnitStatusKeys.find(foundValue => colValue.toLowerCase().trim() === foundValue.toLowerCase().trim()))) {
            columnValues.push(colValue);
          } else if (!isNil(colValue)) {
            columnValues.push('Occupied');
          }
        }
        result = columnValues.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});
      }

      this.workbookManager.resume();
      return result;
    }
  }

  getUniqueUnitStatus() {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const unitStatusConfig = currentDocumentUnitStatusSelector(this.options.store.getState());
      let rowCount; let targetRange; let
        isStatusColumnPresent = true;
      if (Reflect.ownKeys(unitStatusConfig).length > 0) {
        let documentUnitStatus = this.getRentRollUniqueColumnValues('Status');
        if (Reflect.ownKeys(documentUnitStatus).length === 0) {
          documentUnitStatus = this.convertTenantNameToStatus('Tenant Name');
          const columnRange = this.getAlphabetTargetRange('Tenant Name', targetSheetIndex);
          rowCount = columnRange.rowCount;
          targetRange = columnRange.targetRange;
          isStatusColumnPresent = false;
        } else {
          const columnRange = this.getAlphabetTargetRange('Status', targetSheetIndex);
          rowCount = columnRange.rowCount;
          targetRange = columnRange.targetRange;
        }

        const sheet = this.workbookManager.workbook.getSheet(targetSheetIndex);
        const marketRentAmoutColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Market Rent').foundColumnIndex;
        const monthlyRentAmoutColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Monthly Rent').foundColumnIndex;

        let marketRentTotal = 0;
        let monthlyRentTotal = 0;
        if (!!Reflect.ownKeys(documentUnitStatus) && Reflect.ownKeys(documentUnitStatus).length > 0) {
          const unitStatusConfigKeys = Reflect.ownKeys(unitStatusConfig);
          const documentUnitStatusKeys = Reflect.ownKeys(documentUnitStatus);
          if (documentUnitStatusKeys.length) {
            documentUnitStatusKeys.forEach(unitStatus => {
              const validKeyName = findValidKey(unitStatusConfigKeys, unitStatus);
              if (!isNil(validKeyName)) {
                if (marketRentAmoutColumnIndex !== -1) {
                  const targetSumRange = `${this.getColumnAlphabetIndex(marketRentAmoutColumnIndex)}1:${this.getColumnAlphabetIndex(marketRentAmoutColumnIndex)}${rowCount}`;
                  marketRentTotal = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=ROUND(SUMIF('${targetSheetName}'!${targetRange},"${validKeyName}",'${targetSheetName}'!${targetSumRange}),2)`);
                }
                if (monthlyRentAmoutColumnIndex !== -1) {
                  const targetSumRange = `${this.getColumnAlphabetIndex(monthlyRentAmoutColumnIndex)}1:${this.getColumnAlphabetIndex(monthlyRentAmoutColumnIndex)}${rowCount}`;
                  monthlyRentTotal = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=ROUND(SUMIF('${targetSheetName}'!${targetRange},"${validKeyName}",'${targetSheetName}'!${targetSumRange}),2)`);
                }
                documentUnitStatus[unitStatus] = {
                  ...unitStatusConfig[validKeyName], marketRentTotal, monthlyRentTotal, count: documentUnitStatus[unitStatus]
                };
              }
            });
          }
        }

        if (!isStatusColumnPresent) {
          if (marketRentAmoutColumnIndex !== -1) {
            const totalMarketRentSumRange = `${this.getColumnAlphabetIndex(marketRentAmoutColumnIndex)}1:${this.getColumnAlphabetIndex(marketRentAmoutColumnIndex)}${rowCount}`;
            const totalMarketRent = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${totalMarketRentSumRange})`);
            const totalOtherMarketRent = Reflect.ownKeys(documentUnitStatus).reduce((acc, curr) => acc = acc + documentUnitStatus[curr].marketRentTotal, 0);
            if (!isNil(documentUnitStatus['Occupied'])) {
              documentUnitStatus['Occupied'].marketRentTotal = totalMarketRent - totalOtherMarketRent;
            }
          }
          if (monthlyRentAmoutColumnIndex !== -1) {
            const totalMonthlyRentSumRange = `${this.getColumnAlphabetIndex(monthlyRentAmoutColumnIndex)}1:${this.getColumnAlphabetIndex(monthlyRentAmoutColumnIndex)}${rowCount}`;
            const totalMonthlyRent = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${totalMonthlyRentSumRange})`);
            const totalOtherMonthlyRent = Reflect.ownKeys(documentUnitStatus).reduce((acc, curr) => acc = acc + documentUnitStatus[curr].monthlyRentTotal, 0);
            if (!isNil(documentUnitStatus['Occupied'])) {
              documentUnitStatus['Occupied'].monthlyRentTotal = totalMonthlyRent - totalOtherMonthlyRent;
            }
          }
        }
        this.options.store.dispatch(setUnitStatus({
          ...documentUnitStatus
        }));
      }
      this.workbookManager.resume();
    }
  }

  mergeChargeCodeColumns(chargeCodeColumnRef) {
    if (Reflect.ownKeys(chargeCodeColumnRef).length > 0) {
      const { sourceColumnRef, targetcolumnRef } = chargeCodeColumnRef;
      this.workbookManager.suspend();
      const rowCount = this.getTargetSheetRowCount();
      const sheet = this.workbookManager.workbook.getSheet(targetSheetIndex);
      const sourceColCellIndex = this.workbookManager.customSearchColumnWithValue(1, 0, sourceColumnRef.key).foundColumnIndex;
      const targetColCellIndex = this.workbookManager.customSearchColumnWithValue(1, 0, targetcolumnRef.key).foundColumnIndex;
      for (let row = 1; row < rowCount; row++) {
        const sourceColValue = this.workbookManager.getCellValue(targetSheetIndex, row, sourceColCellIndex);
        const targetColValue = this.workbookManager.getCellValue(targetSheetIndex, row, targetColCellIndex);
        const total = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${isNil(sourceColValue) ? 0 : sourceColValue}+${isNil(targetColValue) ? 0 : targetColValue})`);
        this.workbookManager.setCellValue(targetSheetIndex, row, targetColCellIndex, total);
      }
      sheet.deleteColumns(sourceColCellIndex, 1);
      this.workbookManager.bindSheetHeader(sheet);
      this.deleteChargeCodeColumnFromStore(sourceColumnRef.key);
      if (this.isWorkbookHasRentRollTypeDoc()) {
        if (this.isMultifamilyOrHealthcareDocument()) {
          //delaying below method execution to pick latest charge code from state
          setTimeout(() => this.setChargeCodeAfterColumnMerge(), 0);
        } else {
          this.setRetailChargeCode();
        }
      }
      this.workbookManager.resume();
    }
  }

  deleteChargeCodeColumnFromStore(keyName) {
    const chargeCode = this.options.chargeCodeConfig;
    delete chargeCode[keyName];
    this.options.store.dispatch(setChargeCodeConfig({
      ...chargeCode
    }));
  }

  setChargeCodeAfterColumnMerge(isSlidePanelClicked = false, eventType = undefined) {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      const userSelectedChargeCode = this.options.chargeCodeConfig;
      const chargeCodes = invertBy(Reflect.ownKeys(userSelectedChargeCode).reduce((chargeCode, chargeCodeKey) => {
        chargeCode[chargeCodeKey] = userSelectedChargeCode[chargeCodeKey].category;
        return chargeCode;
      }, {}));
      const rowCount = this.getTargetSheetRowCount();
      const sheet = this.workbookManager.workbook.getSheet(targetSheetIndex);
      const chargeCodeKeys = Reflect.ownKeys(chargeCodes);
      const isAcquisition = currentTemplateTagSelector(this.options.store.getState()) === TEMPLATE_TAGS.ACQUISITION.key;
      const renovationBumpColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Renovation Bump').foundColumnIndex;
      const rentPremiumColumnIndex = this.workbookManager.customSearchColumnWithValue(1, 0, 'Rent Premium').foundColumnIndex;

      if (this.isWorkbookHasRentRollTypeDoc() && chargeCodeKeys.length > 0) {
        this.workbookManager.suspend();
        this.clearChargeCodeCategory(rowCount, sheet);
        this.clearRenovationBumpColumn(sheet, rowCount, renovationBumpColumnIndex);

        for (let targetKey in chargeCodes) {
          const targetFormulaCellIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, targetKey).foundColumnIndex;
          let formulaExpression = '';
          chargeCodes[targetKey].forEach(chargeCode => {
            const chargeCodeColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, chargeCode).foundColumnIndex;
            if (chargeCodes[targetKey].length > 1) {
              if (formulaExpression === '') {
                formulaExpression += `${this.columnAlphabetIndex(chargeCodeColIndex)}&`;
              } else {
                formulaExpression += `+${this.columnAlphabetIndex(chargeCodeColIndex)}&`;
              }
            } else {
              formulaExpression += `${this.columnAlphabetIndex(chargeCodeColIndex)}&`;
            }
          });
          if (targetFormulaCellIndex !== -1) {
            for (let row = 1; row < rowCount; row++) {
              const finalFormulaExpression = formulaExpression.split("&").join(row + 1);
              const cellValue = sheet.getCell(row, targetFormulaCellIndex).value();
              if (cellValue === null || !cellValue) {
                sheet.setFormula(row, targetFormulaCellIndex, `=SUM(${finalFormulaExpression})`);
                sheet.setFormatter(row, targetFormulaCellIndex, USCurrencyFormat);
                if (isAcquisition && rentPremiumColumnIndex === targetFormulaCellIndex) {
                  const rentPremiumCellValue = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${finalFormulaExpression})`);
                  sheet.getCell(row, renovationBumpColumnIndex).value(rentPremiumCellValue);
                }
              }
            }
          }
        }

        if (!isSlidePanelClicked && isNil(eventType)) {
          this.retrieveChargeCodes();
          this.options.setWorkbookToast({ type: 'success', message: 'Charge code successfully merged.' });
        }

        setTimeout(() => {
          this.getUniqueUnitStatus();
          if (!!eventType) { // expecting a valid eventType
            this.calculateUnitMixSummary();
            this.getUniqueFloorPlan("onDataBind");
          }
        }, 0);
        this.workbookManager.resume();
      }
    }
  }

  clearChargeCodeCategory(rowCount, sheet) {
    this.workbookManager.suspend();
    const chargeCodes = staticChargeCodeSelector(this.options.store.getState());
    if (chargeCodes.length > 0) {
      for (let chargeCodeColumnIndex = 0; chargeCodeColumnIndex < chargeCodes.length; chargeCodeColumnIndex++) {
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, chargeCodes[chargeCodeColumnIndex]);
        if (foundColumnIndex !== -1) {
          const range = sheet.getRange(1, foundColumnIndex, rowCount, 1);
          range.formatter(cellNumberFormat);
          for (let row = 1; row < rowCount; row++) {
            const cellValue = sheet.getCell(row, foundColumnIndex).value();
            const isFormulaExist = sheet.getFormula(row, foundColumnIndex);
            if (!isNil(isFormulaExist) && (!!cellValue || cellValue === 0)) {
              sheet.setFormula(row, foundColumnIndex, null);
              sheet.setText(row, foundColumnIndex, null);
            }
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  clearRenovationBumpColumn(sheet, rowCount, columnIndex) {
    this.workbookManager.suspend();
    if (columnIndex !== -1) {
      sheet.clear(1, columnIndex, rowCount, 1, GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.data);
    }
    this.workbookManager.resume();
  }


  getLeaseInfoFromDocument(columnNames, sheetIndex, rowCount) {
    this.workbookManager.suspend();
    const columnValues = [];
    for (let headerCol = 0; headerCol < columnNames.length; headerCol++) {
      const columnIndex = this.workbookManager.customSearchColumnWithValue(sheetIndex, 0, columnNames[headerCol]).foundColumnIndex;
      if (columnIndex !== -1) {
        for (let row = 1; row < rowCount; row++) {
          const cellValue = this.workbookManager.getCellValue(targetSheetIndex, row, columnIndex);
          if (!isNil(cellValue)) {
            headerCol === 0 ? columnValues.push(cellValue) : !!cellValue && (columnValues[row - 1] = cellValue);
          }
        }
      }
    }
    const dates = columnValues.filter(colValue => moment(colValue, "MM/DD/YYYY")._isValid && colValue);
    const sortedDate = sortBy(dates, (date) => moment(date, 'MM/DD/YYYY').format('X'));
    const groupedDate = groupBy(sortedDate, (date) => moment(date, 'MM/DD/YYYY').format('MMM-YY'));
    const monthWiseCount = Reflect.ownKeys(groupedDate)
      .map(month => ({
        "name": month, 'Lease Count': groupedDate[month].length
      }));

    const yearWiseCount = groupBy(monthWiseCount, (countData) => moment(countData.name, 'MMM-YY').format('YYYY'));
    this.workbookManager.resume();
    return yearWiseCount;
  }

  setRentRollLeaseTypeData() {
    if (this.isWorkbookHasRentRollTypeDoc() && this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const rowCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getRowCount();
      const leaseStartRange = this.getLeaseInfoFromDocument(['Start Date', 'Move In Date'], targetSheetIndex, rowCount);
      const leaseExpirtyRange = this.getLeaseInfoFromDocument(['End Date', 'Move Out Date'], targetSheetIndex, rowCount);
      this.options.store.dispatch(setLeaseConfig(
        leaseStartRange, leaseExpirtyRange
      ));
      this.workbookManager.resume();
    }
  }

  getRetailLeaseExpirationData() {
    if (this.isWorkbookHasRentRollTypeDoc() && !this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const columnValues = this.workbookManager.fetchValueFromColumns(targetSheetIndex, ['End Date', 'Sq Ft'], ['endDate', 'sqFt']);
      const groupedDates = groupBy(columnValues, 'endDate');
      const monthWiseCount = Reflect.ownKeys(groupedDates).map(date => ({ name: date, total: sumBy(groupedDates[date], 'sqFt') }));
      const yearWiseCount = groupBy(monthWiseCount, (countData) => moment(countData.name, "MM/DD/YYYY")._isValid && moment(countData.name, 'MM-DD-YYYY').format('YYYY'));
      const finalLeaseExpiration = Reflect.ownKeys(yearWiseCount).map(year => ({ 'name': year, 'SF Rolling': sumBy(yearWiseCount[year], 'total') })).filter(item => item.name !== 'false' && item);
      this.options.store.dispatch(setLeaseConfig(
        null, finalLeaseExpiration
      ));
      this.workbookManager.resume();
    }
  }

  getRetailOccupancyStatus() {
    if (this.isWorkbookHasRentRollTypeDoc() && !this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const projectOccupancy = occupancySelector(this.options.store.getState());
      const projectOccupancyMappings = occupancyMappingSelector(
        this.options.store.getState()
      );
      const columnValues = this.workbookManager.fetchValueFromColumns(targetSheetIndex, ['Status', 'Sq Ft'], ['status', 'sqFt']).filter(key => key.status);
      const groupedStatus = groupBy(columnValues, 'status');
      const filterprojectOccupancy = Array.isArray(projectOccupancy) ? groupBy(projectOccupancy, 'name') : [];
      const finalOccupancyData = Reflect.ownKeys(groupedStatus).map(status => {
        const category = !isNil(filterprojectOccupancy[status]) ? filterprojectOccupancy[status][0].category : this.getOccupancyCategory(status, projectOccupancyMappings);
        return {
          name: status, units: groupedStatus[status].length, category, value: sumBy(groupedStatus[status], 'sqFt')
        };
      });

      this.options.store.dispatch(setRetailOccupancy(
        finalOccupancyData
      ));
      this.workbookManager.resume();
    }
  }

  getOccupancyCategory(status, projectOccupancyMappings) {
    return projectOccupancyMappings.includes(status) ? status : "";
  }

  getRetailLeaseType() {
    if (this.isWorkbookHasRentRollTypeDoc() && !this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const rowCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getRowCount();
      const leaseTypeMapping = retailLeaseTypeDropDownSelector(this.options.store.getState());
      const leaseTypeConfig = retailLeaseTypeConfigSelector(this.options.store.getState());
      const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Lease Type');
      if (foundColumnIndex !== -1) {
        const leaseTypeColumnValues = uniq(this.workbookManager.getColumnValues(targetSheetIndex, 1, rowCount, foundColumnIndex).filter(leaseType => leaseType !== null && leaseType));
        const uniqueColumnValue = difference(leaseTypeColumnValues, leaseTypeMapping);
        const finalLeaseType = uniqueColumnValue.length ? Object.assign(...uniqueColumnValue.map(item => ({ [item]: !isNil(leaseTypeConfig[item]) ? leaseTypeConfig[item] : '' }))) : {};
        this.options.store.dispatch(setRetailLeaseTypeConfig(
          finalLeaseType
        ));
      }
      this.workbookManager.resume();
    }
  }


  getRetailTenantTypeData() {
    if (this.isWorkbookHasRentRollTypeDoc() && !this.isMultifamilyOrHealthcareDocument()) {
      this.workbookManager.suspend();
      const columnValues = this.workbookManager.fetchValueFromColumns(targetSheetIndex, Object.values(tenantTypeColumnCalc), Reflect.ownKeys(tenantTypeColumnCalc)).filter(tenant => tenant.TENANT_TYPE);
      const totalSquareFt = sumBy(columnValues, getKeyByValue(tenantTypeColumnCalc, tenantTypeColumnCalc.SQFT));
      const groupedByTenantName = groupBy(columnValues, getKeyByValue(tenantTypeColumnCalc, tenantTypeColumnCalc.TENANT_TYPE));
      const finalTenantTypeData = Reflect.ownKeys(groupedByTenantName).map(tenant => {
        if (!isNil(tenant)) {
          const sqFtTotal = sumBy(groupedByTenantName[tenant], 'SQFT');
          const totalRent = some(groupedByTenantName[tenant], 'BASE_RENT') ? getTotalByKey(groupedByTenantName[tenant], 'BASE_RENT') :
            sumBy(groupedByTenantName[tenant], key => key.MONTLY_RENT_CALC ? Number(key.MONTLY_RENT_CALC) : 0);

          const MONTLY_CAM = some(groupedByTenantName[tenant], 'CAM_RE') ? getTotalByKey(groupedByTenantName[tenant], 'CAM_RE') :
            sumBy(groupedByTenantName[tenant], key => key.MONTLY_CAM_CALC ? Number(key.MONTLY_CAM_CALC) : 0);

          const MONTLY_TAX = some(groupedByTenantName[tenant], 'TAX_RE') ? getTotalByKey(groupedByTenantName[tenant], 'TAX_RE') :
            sumBy(groupedByTenantName[tenant], key => key.MONTLY_TAX_CALC ? Number(key.MONTLY_TAX_CALC) : 0);

          const MONTLY_INC = some(groupedByTenantName[tenant], 'INS_RE') ? getTotalByKey(groupedByTenantName[tenant], 'INS_RE') :
            sumBy(groupedByTenantName[tenant], key => key.MONTLY_INC_CALC ? Number(key.MONTLY_INC_CALC) : 0);

          const totalSqFtPercent = getPercentage(sqFtTotal, totalSquareFt);
          const totalRecovery = Number(MONTLY_CAM + MONTLY_TAX + MONTLY_INC);
          return {
            name: tenant, Recoveries: totalRecovery, sqFtTotal, Rent: isNaN(totalRent) ? 0 : totalRent, totalSqFtPercent
          };
        }
      });

      this.options.store.dispatch(setRetailTenantTypeConfig(
        finalTenantTypeData
      ));

      this.workbookManager.resume();
    }
  }

  calculateTenantType(calcultedPercent) {
    this.workbookManager.suspend();
    const columnValues = this.workbookManager.fetchValueFromColumns(targetSheetIndex, [tenantTypeColumnCalc.TENANT_TYPE, tenantTypeColumnCalc.SQFT], ['TENANT_TYPE', 'SQFT']);
    const totalSquareFt = sumBy(columnValues, 'SQFT');
    const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Tenant Type');
    if (!!columnValues.length && foundColumnIndex !== -1) {
      if (foundColumnIndex) {
        for (let row = 0; row < columnValues.length; row++) {
          const tenantType = this.getTenantType(
            columnValues[row].SQFT,
            totalSquareFt,
            calcultedPercent
          );
          this.workbookManager.setCellValue(targetSheetIndex, columnValues[row].TENANT_TYPE_ROW_INDEX, foundColumnIndex, tenantType);
        }
      }
    }
    this.getRetailTenantTypeData();
    this.workbookManager.resume();
  }

  getTenantType(sqft, totalSquareFt, calcultedPercent) {
    const inlineType = this.workbookManager.options.assetType === PROPERTY_TYPES.OFFICE.key ? 'Minor' : 'Inline';
    const tenantType =
      getPercentage(sqft, totalSquareFt) >= calcultedPercent ?
        "Major" :
        inlineType;

    return tenantType;
  }

  setRetailChargeCode() {
    this.workbookManager.suspend();
    if (this.isWorkbookHasRentRollTypeDoc() && !this.isMultifamilyOrHealthcareDocument()) {
      const userSelectedChargeCode = this.options.chargeCodeConfig;
      const rowCount = this.getTargetSheetRowCount();
      const sheet = this.workbookManager.workbook.getSheet(targetSheetIndex);
      const squareFtColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sq Ft').foundColumnIndex;
      const chargeCodesColumns = staticChargeCodeSelector(this.options.store.getState()) || [];
      const staticChargeCodeMappings = staticChargeCodeMappingSelector(this.options.store.getState());
      const annualCalcChargeCodeColumnMappings = staticChargeCodeMappings.map(chargeCode => chargeCode.mappedCalculatedColumn).filter(cc => cc);
      const chargeCodeColumnsToClear = [...chargeCodesColumns, ...annualCalcChargeCodeColumnMappings].filter(cc => !!cc);
      if (this.isWorkbookHasRentRollTypeDoc()) {
        this.clearRetailChargeCodeColumns(sheet, chargeCodeColumnsToClear, rowCount);
        const otherRecoveries = [];
        for (let targetKey in userSelectedChargeCode) {
          const { label, unit } = userSelectedChargeCode[targetKey];
          const sourceColCellIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, targetKey).foundColumnIndex;
          const targetColCellIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, label).foundColumnIndex;
          let mappedCalcColIndex = -1;
          const findMappedColumnName = !!staticChargeCodeMappings && staticChargeCodeMappings.find(mappedCol => mappedCol.columnName === label);
          if (!isNil(findMappedColumnName)) {
            mappedCalcColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, findMappedColumnName.mappedCalculatedColumn).foundColumnIndex;
          }
          if (userSelectedChargeCode[targetKey] && !!label) {
            if (targetColCellIndex !== -1 && sourceColCellIndex !== -1) {
              for (let row = 1; row < rowCount; row++) {
                const sourceColValue = this.workbookManager.getCellValue(targetSheetIndex, row, sourceColCellIndex);
                const sqFtColumnValue = squareFtColumnIndex !== -1 ? this.workbookManager.getCellValue(targetSheetIndex, row, squareFtColumnIndex) : 1;
                let calculatedCellValue = this.calculateRetailChargeCodeTotal(sourceColValue, unit, sqFtColumnValue) || 0;
                if (label === ALLOWED_RETAIL_CC_CATEGORY[0]) { // Expecting Other Recoveries here
                  calculatedCellValue = sum([otherRecoveries[row], calculatedCellValue]);
                  if (!isNil(otherRecoveries[row])) {
                    otherRecoveries.push(calculatedCellValue);
                  } else {
                    otherRecoveries[row] = calculatedCellValue;
                  }
                }
                this.highlightCellValue(sheet, row, targetColCellIndex, undefined);
                this.workbookManager.setCellValue(targetSheetIndex, row, targetColCellIndex, calculatedCellValue);
                sheet.setFormatter(row, targetColCellIndex, USCurrencyFormat);
                mappedCalcColIndex !== -1 && this.workbookManager.setCellFormula(sheet, row, mappedCalcColIndex, `=PRODUCT(${this.columnAlphabetIndex(targetColCellIndex)}${row + 1}, 12)`); // (chargeCode*12) To get annual total
              }
              const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, targetKey);
              if (foundColumnIndex !== -1) {
                const total = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, `=SUM(${this.columnAlphabetIndex(foundColumnIndex)}2:${this.columnAlphabetIndex(foundColumnIndex)}${rowCount})`);
                userSelectedChargeCode[targetKey]['total'] = total;
              }
            }
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  calculateRetailChargeCodeTotal(chargeCodeTotal, unit, sqFtColumnValue) {
    this.workbookManager.suspend();
    const {
      EMPTY, MONTHLY, ANNUAL, ANNUAL_PER_SQFT, MONTHLY_PER_SQFT
    } = RETAIL_CHARGE_CODE_FORMULA;
    let calculatedCellValue = 0;
    if (this.isWorkbookHasRentRollTypeDoc()) {
      if (chargeCodeTotal !== null) {
        if (unit === EMPTY || unit === MONTHLY || isNil(unit)) {
          calculatedCellValue = chargeCodeTotal; // Show Charge Code Value as it is
        }
        if (unit === ANNUAL) {
          calculatedCellValue = divide(chargeCodeTotal, 12); // [chargeCode/12]
        }
        if (unit === ANNUAL_PER_SQFT) {
          calculatedCellValue = multiply(divide(chargeCodeTotal, 12), sqFtColumnValue); // [chargeCode/12*SqFt]
        }
        if (unit === MONTHLY_PER_SQFT) {
          calculatedCellValue = multiply(chargeCodeTotal, sqFtColumnValue); // [chargeCode*SqFt]
        }
      }
    }
    this.workbookManager.resume();
    return calculatedCellValue;
  }

  clearRetailChargeCodeColumns(sheet, chargeCodesColumns, rowCount) {
    this.workbookManager.suspend();
    if (chargeCodesColumns.length > 0) {
      for (let chargeCodeColumnIndex = 0; chargeCodeColumnIndex < chargeCodesColumns.length; chargeCodeColumnIndex++) {
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, chargeCodesColumns[chargeCodeColumnIndex]);
        if (foundColumnIndex !== -1) {
          const range = sheet.getRange(1, foundColumnIndex, rowCount, 1);
          range.formatter(USCurrencyFormat);
          for (let row = 1; row < rowCount; row++) {
            sheet.setFormula(row, foundColumnIndex, null);
            sheet.setText(row, foundColumnIndex, null);
          }
        }
      }
    }
    this.workbookManager.resume();
  }

  /*******
   * Operating Statement Net Rental Income
   *****/

  fetchMontlyDataFromDoc(months, sourceValue, sourceColumnIndex) {
    const columnValues = [];
    const rowCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getRowCount();
    this.workbookManager.suspend();
    for (let i = 0; i < months.length; i++) {
      const monthColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, months[i]).foundColumnIndex;
      if (monthColumnIndex !== -1) {
        const monthTotal = this.workbookManager.getMatchedCellValues(targetSheetIndex, rowCount, sourceColumnIndex, monthColumnIndex, sourceValue);
        columnValues.push({ name: moment(months[i], 'MMMM YYYY').format('MMM YY'), [sourceValue]: sum(monthTotal) });
      }
    }
    this.workbookManager.resume();
    return columnValues;
  }


  getMappings(targetMonthColumns, sourceColumnIndex) {
    const templateMapping = currentProjectTemplateMappingsSelector(this.options.store.getState()).filter(headValue => headValue.head === 'Income' && headValue);
    if (!!templateMapping.length && !!targetMonthColumns.length) {
      const mappedColumnValues = [];
      let finalNetRentalIncomeData = null;
      const clikCategoryMappings = CLIK_OS_CATEGORY_MAPPING[this.workbookManager.options.assetType];
      if (!isNil(clikCategoryMappings)) {
        Reflect.ownKeys(clikCategoryMappings).forEach(category => {
          const foundMappedCategoryObj = find(templateMapping, { clikCategory: clikCategoryMappings[category] });
          const foundMappedCategory = !isNil(foundMappedCategoryObj) ? foundMappedCategoryObj.category : false;
          if (foundMappedCategory) {
            const monthsData = this.fetchMontlyDataFromDoc(targetMonthColumns, foundMappedCategory, sourceColumnIndex);
            mappedColumnValues.push(monthsData);
          }
        });

        if (mappedColumnValues.length) {
          const groupedByMonth = groupBy(mappedColumnValues.flat(), 'name');
          const filterNetRentalIncomeData = Reflect.ownKeys(groupedByMonth).map((month) => mergeWith({}, ...groupedByMonth[month], (obj, src) => (isArray(obj) ? obj.concat(src) : undefined)));
          finalNetRentalIncomeData = filterNetRentalIncomeData.map(netRentalIncome => Object.assign(netRentalIncome, { 'Net Rental Income': (netRentalIncome[clikCategoryMappings.RENTAL_INCOME] - netRentalIncome[clikCategoryMappings.VACANCY_LOSS]) }));
        }
      }

      return finalNetRentalIncomeData;
    }
  }

  getRentalIncomeInfo() {
    if (this.isWorkbookHasOSTypeDoc()) {
      this.workbookManager.suspend();
      const filteredMonths = map(this.allColumnList.filter(month => !isNil(month) && moment(month.headerName, "MMMM YYYY")._isValid && month), 'headerName');
      if (filteredMonths.length > 0) {
        const categoryColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
        const netRentalIncome = this.getMappings(filteredMonths, categoryColumnIndex);
        this.options.store.dispatch(setNetRentalIncome(
          netRentalIncome
        ));
      }
      this.workbookManager.resume();
    }
  }

  highlightVacancyLossAmountCell() {
    if (this.isWorkbookHasOSTypeDoc()) {
      this.workbookManager.suspend();
      const rowCount = this.workbookManager.workbook.getSheet(targetSheetIndex).getRowCount();
      const templateMapping = currentProjectTemplateMappingsSelector(this.options.store.getState()).filter(headValue => headValue.head === 'Income' && headValue);
      const clikCategoryMappings = CLIK_OS_CATEGORY_MAPPING[this.workbookManager.options.assetType];

      if (!!templateMapping.length && !isNil(clikCategoryMappings)) {
        const vacancyLossCategory = templateMapping.find(category => category.clikCategory === clikCategoryMappings.VACANCY_LOSS);
        if (!isNil(vacancyLossCategory)) {
          const { category } = vacancyLossCategory;
          const categoryColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
          const amountColumnIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Amount').foundColumnIndex;
          const foundRows = this.workbookManager.customSearchWithColumnValues(targetSheetIndex, categoryColumnIndex, category, { startRow: 1 });
          const sheet = this.workbookManager.getSheet(targetSheetIndex);
          const dataValidator = GC.Spread.Sheets.DataValidation.createNumberValidator(GC.Spread.Sheets.ConditionalFormatting.ComparisonOperators.lessThan, 0);

          if (foundRows.length) {
            for (let row = 1; row < rowCount; row++) {
              if (foundRows.includes(row)) {
                const amountValue = this.workbookManager.getCellValue(targetSheetIndex, row, amountColumnIndex);
                this.workbookManager.workbook.options.highlightInvalidData = true;
                if (!isNaN(amountValue) && amountValue !== "" && Math.sign(amountValue) === 1) {
                  dataValidator.showInputMessage(true);
                  dataValidator.ignoreBlank(true);
                  dataValidator.inputMessage(`${category} must be a negative value.`);
                  dataValidator.inputTitle('Note');
                  dataValidator.highlightStyle({
                    type: GC.Spread.Sheets.DataValidation.HighlightType.circle,
                    color: 'red'
                  });
                  sheet.setDataValidator(row, amountColumnIndex, dataValidator);
                }
              } else {
                const isValidatorExist = sheet.getDataValidator(row, amountColumnIndex);
                !isNil(isValidatorExist) && sheet.setDataValidator(row, amountColumnIndex, null);
              }
            }
          }
        }
      }
      this.workbookManager.resume();
    }
  }

  handleCustomChargeCodeColumn(columnName, columnIndex) {
    this.workbookManager.suspend();
    if (this.isWorkbookHasRentRollTypeDoc() && !isNil(columnName)) {
      if (columnName.length > 2 && isNaN(columnName)) {
        const sheet = this.workbookManager.getSheet(targetSheetIndex);
        const trimmedColumnName = columnName.trim();
        const chargeCodePrefix = trimmedColumnName.substring(0, 2);
        if (chargeCodePrefix.toLowerCase() === 'cc') { // check if column has cc prefix
          const config = chargeCodeConfigSelector(this.options.store.getState());
          let chargeCodeConfig;
          if (
            this.isMultifamilyOrHealthcareDocument()
          ) {
            // MultiFamily & Healthcare Charge Code object
            chargeCodeConfig = {
              ...config,
              [trimmedColumnName]: {
                total: 0,
                key: trimmedColumnName,
                category: ''
              }
            };
          } else {
            // Retail Charge Code object
            chargeCodeConfig = {
              ...config,
              [trimmedColumnName]: {
                category: { code: '', conversion: "N/A" },
                total: 0,
                key: trimmedColumnName,
                trimmedColumnName: 0
              }
            };
          }

          sheet.bindColumn(columnIndex, {
            resizable: true, size: 180, name: columnName, headerName: columnName, type: "CHARGE_CODE"
          });
          this.bindSheetColumnsOnChargeCodeAddition(sheet);

          this.options.store.dispatch(setChargeCodeConfig(
            chargeCodeConfig
          ));
        }
      }
    }

    this.workbookManager.resume();
  }

  bindSheetColumnsOnChargeCodeAddition(sheet) {
    this.workbookManager.suspend();
    const workbookData = this.workbookManager.getWorkbookData();
    const { columns } = workbookData.sheets[targetSheetName];
    columns.forEach((column, i) => {
      const alphabetName = this.workbookManager.toAlphabetColumnName(i + 1);
      if (!!column && !!column.name) {
        sheet.bindColumn(i, { displayName: alphabetName, ...column });
      }
    });
    this.workbookManager.resume();
  }

  validateAndSetDateToCell(sheet, row, col, date) {
    this.workbookManager.suspend();
    const isValidDate = this.validateColumnDataType('date', date);
    if (!!date && isValidDate) {
      sheet.setValue(row, col, new Date(date));
      sheet.setFormatter(row, col, USDateFormat);
    } else {
      sheet.setValue(row, col, date);
    }
    this.workbookManager.resume();
  }

  handleColumnsFormat() {
    this.workbookManager.suspend();
    const workbookData = this.workbookManager.getWorkbookData();
    if (workbookData) {
      const { columns } = workbookData.sheets[targetSheetName];
      if (columns.length) {
        const sheet = this.workbookManager.workbook.getSheet(targetSheetIndex);
        const rowCount = this.getTargetSheetRowCount();
        columns.forEach((column) => {
          if (column?.hasOwnProperty('dataType') && column.dataType !== 'string') {
            const { dataType } = column;
            const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(1, 0, column.headerName);
            if (foundColumnIndex !== -1 && columnDataTypeFormatters?.hasOwnProperty(dataType)) {
              const range = sheet.getRange(1, foundColumnIndex, rowCount, 1);
              for (let row = 1; row < rowCount; row++) {
                const columnValue = sheet.getCell(row, foundColumnIndex).value();
                const isValid = this.validateColumnDataType(dataType, columnValue);
                const isDateDataType = dataType === 'date' && (isValid && !!columnValue)
                this.highlightCellValue(sheet, row, foundColumnIndex, isValid ? undefined : invalidCellColor, isDateDataType);
                if (isValid) {
                  this.validateAndSetDateToCell(sheet, row, foundColumnIndex, columnValue);
                }
              }
              if (dataType !== 'string' && dataType !== 'date') {
                range.formatter(columnDataTypeFormatters[dataType]); // applying formattar to the column
              }
            }
          }
        })
      }
    }
    this.workbookManager.resume();
  }

  getCFSummary() {
    const periodIdentifier = cashflowPeriodIdentifierSelector(this.options.store.getState());
    const getPeriodColumns = () => {
      const periodColumns = [];
      let firstCashflowColumnIndex = null;
      const { targetSheetData: { columns } } = this.getSheetData();
      const cashflowColumns = columns.filter((c) => c.hasOwnProperty('cashFlowType'));

      for (let i = 0; i < cashflowColumns.length; i++) {
        if (!firstCashflowColumnIndex) {
          const columnName = cashflowColumns[i]?.headerName || '';
          const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, columnName);
          if (foundColumnIndex !== -1) {
            firstCashflowColumnIndex = foundColumnIndex;
          }
        }

        const periodData = this.workbookManager.getSheet(targetSheetIndex).getText(3, firstCashflowColumnIndex + i);
        const columnIdentifier = this.workbookManager.getSheet(targetSheetIndex).getText(0, firstCashflowColumnIndex + i);
        periodColumns.push({
          value: columnIdentifier,
          label: periodData,
        });
      }
      return { firstCashflowColumnIndex, periodColumns};
    };

    const getSummaryTotal = (summaryData) => {
      if (summaryData.length > 0) {
        const SUMMARY_TOTAL = summaryData.map(
          (item) => ({
            head: item.title.replace(/ /g, ''),
            color: item.color, total: round(item.total, 2)
          }),
        );
        const { Expense, Income, CapitalExpense } = groupBy(SUMMARY_TOTAL, 'head');
        const IncomeTotal = isEmpty(Income) ? 0 : Income[0].total;
        const ExpenseTotal = isEmpty(Expense) ? 0 : Expense[0].total;
        const SUMMARY_NOI = round((IncomeTotal - ExpenseTotal), 2);
        const SUMMARY_NCF = round((SUMMARY_NOI - (isEmpty(CapitalExpense) ? 0 : CapitalExpense[0].total)), 2);
        return { SUMMARY_TOTAL, SUMMARY_NOI, SUMMARY_NCF };
      }
    };

    const {firstCashflowColumnIndex, periodColumns } = getPeriodColumns();
    const rowCount = this.workbookManager.getSheetRowCount(targetSheetIndex);
    const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
    const categoryIndex =  this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
    const lineItemIndex =  this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Line Item').foundColumnIndex;
    const amountIndex =  this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, periodIdentifier).foundColumnIndex;
    const actualNOIRowIndex =  this.workbookManager.customSearchRowWithValue(targetSheetIndex, 1, 'NET OPERATING INCOME').foundRowIndex;
    const actualNCFRowIndex =  this.workbookManager.customSearchRowWithValue(targetSheetIndex, 1, 'NET CASH FLOW').foundRowIndex;

    const targetSheetData = Array(rowCount).fill(0).map((_, i) => {
      const index = i + 1;
      const amoutColIdx = amountIndex > 0 ? amountIndex : firstCashflowColumnIndex;
      const category = this.workbookManager.getCellValue(targetSheetIndex, index, categoryIndex);
      const head = this.workbookManager.getCellValue(targetSheetIndex, index, headIndex);
      const lineItem = this.workbookManager.getCellValue(targetSheetIndex, index, lineItemIndex);
      const serialNumber = this.workbookManager.getCellValue(targetSheetIndex, index, 0);
      const amount = this.workbookManager.getCellValue(targetSheetIndex, index, amoutColIdx);
      return category && {
        category, head, lineItem, serialNumber, amount,
      };
    }).filter((x) => x);

    const summaryColors = {
      'Income': '#EBF8FF',
      'Expense': '#FFF5F5',
      'Capital Expense': '#FFFFF0',
    };

    const sortByHead = sortBy(targetSheetData, 'head').reverse();
    const groupedHeads = groupBy(sortByHead, 'head');

    const totalAmount = (key) => round(sumBy(key, (obj) =>
      isNotEmptyOrNaNValue(obj, 'amount') && obj.amount), 2);


    const summaryDetail = Reflect.ownKeys(groupedHeads).map((head) => {
      const heads = groupedHeads[head];
      const incomeTotal = sumBy(groupedHeads['Income'],
        (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount);
      const groupedCategory = groupBy(heads, 'category');
      const categoryAmountTotal = Object.values(groupedCategory).map(
        (item) => sumBy(item, (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount),
      );

      return {
        title: head,
        color: summaryColors[head],
        total: round(totalAmount(heads), 2),
        displayTotal: round(totalAmount(heads), 2),
        children: Reflect.ownKeys(groupedCategory).map((category, index) => ({
          title: category,
          color: summaryColors[head],
          total: categoryAmountTotal[index],
          displayTotal: round(categoryAmountTotal[index], 2),
        })),
      };
    });

    const actualNetIncome = {
      actualNOI: this.workbookManager.getCellValue(targetSheetIndex, actualNOIRowIndex, amountIndex),
      actualNCF: this.workbookManager.getCellValue(targetSheetIndex, actualNCFRowIndex, amountIndex),
    };
    const summaryTotal = getSummaryTotal(summaryDetail);
    this.options.setSummaryDataToStore({ summaryDetail, summaryTotal, actualNetIncome, periodColumns });
    return { summaryDetail, summaryTotal, actualNetIncome, periodColumns };
  }

}

export default DocExtractionWorkbookManager;
import React, { useState, useCallback } from 'react';
import { withServices } from 'reaf';
import {
    Row, Col, ListGroup, Badge, Button, Dropdown
} from 'react-bootstrap';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import SwitchButton from "../../core/SwitchButton/SwitchButton";
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faInfo as infoIcon } from '@fortawesome/free-solid-svg-icons/faInfo';
import IconButton from '../../core/Button/IconButton';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import ReportTemplatesModal from '../ReportTemplatesModal/ReportTemplatesModal';


function ToggleButton({ isActive, title, onChange }) {
  const [isActiveToggle, setIsActiveToggle] = React.useState(isActive)

  const onToggle = React.useCallback(() => {
    setIsActiveToggle(!isActiveToggle);
    onChange(!isActiveToggle)
  }, [isActiveToggle, onChange])

  return (
    <div className="d-flex flex-column">
      <label className="text-sm">{title}</label>
      <div>
        <SwitchButton
          isActive={isActiveToggle}
          onChange={onToggle}
        />
      </div>
    </div>
  );
} 

function TemplateModelListItem({
  model, templateModelService, onDownloadModel,
  onDeleteTemplateModel, onEditModel, onModelInformation, templateUUID,
  updateShowReportsForModel, updateGlobalModelConsolidatedModelAccess
}) {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const onDeleteReportTemplate = useCallback( async (model, reportTemplate) => {
    try {
      await templateModelService.deleteReportTemplate(null, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... It's intentional to show the error to the admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [templateUUID])

  const onDownloadReportTemplate = useCallback(async (model, reportTemplate) => {
    try {
      await templateModelService.downloadReportTemplate(null, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... It's intentional to show the error to the admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [templateUUID]);

  return (
    <ListGroup.Item>
      <Row className="align-items-center w-100">
        <Col xs={2} className="d-flex align-items-center">
          <WithTooltip tooltipText={model?.modelFilePath}>
            <div>
              {model.modelName}
              {model.assetType && <AssetTypeBadge key={model.assetType} AssetType={model.assetType} />}
            </div>
          </WithTooltip>
          <Badge pill variant="info"></Badge>
        </Col>
        <Col xs={3} className="d-flex align-items-center">
          <ToggleButton
            isActive={model.allowConsolidatedModel}
            title="Allow Download consolidated model"
            onChange={(isActive) => updateGlobalModelConsolidatedModelAccess(model, isActive)}
          />
        </Col>
        <Col xs={3} className="d-flex align-items-center">
          { !!model.modelFilePath && <Button
            className="btn btn-default btn-xs"
            variant="primary"
            onClick={() => onDownloadModel(model)}
          >Model Template</Button>}
          { !!model.hasReportTemplateFile && <Button
            className="btn btn-default btn-xs ml-2"
            variant="outline-primary"
            onClick={toggleModal}
          >Manage Reports</Button>}
        </Col>
        <Col xs={3} className="d-flex align-items-center">
          <label className="mb-0 mr-2">Show Report for this model</label>
          <IconButton
            className={model.showReportsForThisModel ? 'btn-outline-success' : 'btn-outline-danger'}
            onClick={() => updateShowReportsForModel(model)}
            icon={model.showReportsForThisModel ? faCheck : faTimesCircle}
            title={model.showReportsForThisModel ? 'Show Report' : 'Hide Report'} />
        </Col>
        <Col xs={1} className="d-flex align-items-center justify-content-center">
          <Dropdown drop="left">
            <Dropdown.Toggle
              as={IconButton}
              bsPrefix="custom-toggle"
              variant="outline-secondary"
              className="smallIconButton actionButton"
              icon={faEllipsisV}
            />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onDeleteTemplateModel(model)}>
                Delete
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onEditModel(model)}>
                Update
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onModelInformation(model)}>
                Info
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <ReportTemplatesModal
        show={showModal}
        onHide={toggleModal}
        model={model}
        onDownloadReportTemplate={onDownloadReportTemplate}
        onDeleteReportTemplate={onDeleteReportTemplate}
      />
    </ListGroup.Item>
  );
}

export default withServices('templateModelService')(TemplateModelListItem);
